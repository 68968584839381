import { useEffect, useState } from "react";
import Pagination from "../../../components/pagination/Pagination";
import RowsReturns from "./RowsReturns";

const TableReturns = ({ enableActions, returns, onDetail, onInventory }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(4);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = returns.slice(indexOfFirstRecord, indexOfLastRecord);

  useEffect(() => {
    setCurrentPage(1);
  }, [returns]);

  return (
    <>
      <div className="overflow-auto">
        {
          returns?.length > 0 ?
            currentRecords?.map((element, i) =>
              (
                <RowsReturns
                  key={i}
                  enableActions={enableActions}
                  returnElement={element}
                  onDetail={onDetail}
                  onInventory={onInventory}
                />
              )
            )
            :
            <p className="w-full text-center text-ag-secondary-letter text-xs py-4">Table without records...</p>
        }
      </div>
      <div className="p-5 m-auto">
        <Pagination
          currentPage={currentPage}
          totalCount={returns.length}
          pageSize={recordsPerPage}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>
    </>
  );
};

export default TableReturns;