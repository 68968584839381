import ProductInformation from "./product-information/ProductInformation";
import ProductAssignment from "./product-assignment/ProductAssignment";
import ReturnAdditionalInfo from "./ReturnAdditionalInfo";
import ReturnSummary from "./ReturnSummary";
import { useReturnProcessContext } from "./ReturnProcessContext";
import { useNavigate } from "react-router-dom";

const ReturnProductForm = () => {
  const navigate = useNavigate();
  const { returnedOrder, returnBody, handleSubmitReturn } = useReturnProcessContext();

  const disableSubmitButton =
    returnedOrder?.status === "INPROCESS" ||
    (returnBody?.isSellable && (returnBody?.warehouse?._id === "" || returnBody?.addRackQuantity?.rack?._id === "")) ||
    returnBody?.isSellable == null;

  return (
    <>
      <section className="col-span-12 lg:col-span-7 text-sm">
        <div className="overflow-y-auto max-h-[65dvh] mb-4">
          <div className="overflow-x-auto">
            {returnedOrder?.status && returnedOrder?.status === "INPROCESS" && <ProductInformation />}
            {returnedOrder?.status && returnedOrder?.status === "APPROVED" && <ProductAssignment/>}
          </div>
          <ReturnAdditionalInfo />
          <div className="overflow-x-auto">
            {returnedOrder?.status && returnedOrder?.status === "APPROVED" && <ReturnSummary />}
          </div>
        </div>
        <div className="col-span-12 flex flex-col sm:flex-row gap-4 justify-end sm:gap-6 pt-6">
          <button
            className="bg-ag-primary px-4 min-w-[10rem] text-white rounded-md py-1"
            onClick={() => navigate('/returns')}
          >
            Cancel
          </button>
          <button
            className="bg-ag-active px-4 min-w-[10rem] text-white rounded-md py-1 disabled:cursor-not-allowed"
            disabled={disableSubmitButton}
            onClick={handleSubmitReturn}
          >
            Finalize return
          </button>
        </div>
      </section>
    </>

  );
};

export default ReturnProductForm;