import FeaturesByProductOrder from "../../../wholesaler/mycart/FeaturesByProductOrder";
import { useOrderActionsContext } from "../OrderActionsContext";
import { useOrderItems } from "./useOrderItems";
import { MdCancel } from "react-icons/md";

const RowsOrganize = ({ item, itemNumber }) => {
  const {
    rackInputs,
    disableMorePartsButton,
    count,
    setCount,
    handleDeleteSelectInput,
    customOptions,
    isProduct,
    handleChange,
    currentItemStatus
  } = useOrderItems({ item });
  const { loader } = useOrderActionsContext();

  const handleAddSelectInput = () => {
    setCount(count + 1);
  };

  return (
    <section className="inline-block mb-4 min-w-full">
      <h2 className=" w-full bg-ag-secondary-dark px-2 py-1 text-white rounded-t-lg">Item {itemNumber}</h2>
      <div className="flex border border-ag-secondary rounded-b-lg gap-2 py-2">
        <div className="flex flex-col justify-center min-w-[30rem] text-sm p-4 gap-2">
          <p className="font-bold text-ag-secondary-letter">Product name:</p>
          <p className="text-sm text-ag-secondary">{isProduct ? item?.productInventory?.product?.name : item?.accessoryInventory?.accessory?.name}</p>
          <p className="text-sm text-ag-secondary">{isProduct && item?.productInventory?.product?.nags}</p>
          {isProduct && (
            <>
              <hr className="border border-ag-secondary mt-4" />
              <p className="font-bold text-ag-secondary-letter">Features:</p>
              <FeaturesByProductOrder features={item} />
            </>
          )}
        </div>
        <div className="flex flex-col items-center min-w-[15rem] border-l border-ag-secondary text-ag-secondary-letter text-sm text-center gap-4">
          <h3 className="font-bold">Quantity required:</h3>
          <p>{item?.quantity}</p>
          <h3 className="font-bold">Cost of service:</h3>
          <p>${item?.laborCost ?? 0}</p>
        </div>

        <div className="flex flex-col items-center min-w-[15rem] border-l border-ag-secondary gap-4">
          <h3 className="font-bold text-ag-secondary-letter text-sm text-center">Outlet {isProduct ? "rack" : "section"}:</h3>
          {rackInputs.map((rack, index) =>
            (
              <div key={rack.id} className="w-full px-4 flex flex-col gap-1 items-center">
                {index !== 0 &&
                  <button type="button" className="self-end disabled:cursor-not-allowed" disabled={loader} onClick={() => handleDeleteSelectInput(rack.id)}>
                    <MdCancel className="text-ag-secondary"/>
                  </button>
                }
                <select
                  className="w-full font-semibold text-center text-ag-secondary-letter text-sm border border-gray-500 p-2 rounded-xl
                   disabled:bg-zinc-200 truncate disabled:cursor-not-allowed"
                  onChange={(evt) => handleChange(evt,index)}
                  disabled={!customOptions.length || loader}
                  value={rack?.rack?._id ?? ""}
                >
                  <option value="" disabled>Select {isProduct ? "rack" : "section"}</option>
                  {customOptions.map(option =>
                    (<option key={option._id} value={option._id}>{option.name}</option>)
                  )}
                </select>
              </div>
            )
          )}
          <button
            className="font-bold text-sm text-ag-secondary-dark disabled:text-ag-secondary-light disabled:cursor-not-allowed"
            type="button"
            onClick={handleAddSelectInput}
            disabled={disableMorePartsButton || loader}
          >
            Need more parts?
          </button>
        </div>

        <div className="flex flex-col items-center min-w-[15rem] border-l border-ag-secondary ">
          <h3 className="font-bold text-ag-secondary-letter text-sm text-center">Quantity available:</h3>
          {rackInputs.map(({id, quantityAvailable}) =>
            (
              <div key={id} className="w-full text-center">
                <p className="my-5">{ quantityAvailable ?? "---"}</p>
              </div>
            )
          )}

        </div>

        <div className="flex flex-col items-center min-w-[15rem] border-l border-ag-secondary">
          <h3 className="font-bold text-ag-secondary-letter text-sm text-center">Remaining quantity:</h3>
          {rackInputs.map(({id, remainingQuantity}) =>
            (
              <div key={id} className="w-full text-center">
                <p className="my-5">{ remainingQuantity ?? "---"}</p>
              </div>
            )
          )}

        </div>

        <div className="flex flex-col items-center min-w-[15rem] border-l border-ag-secondary">
          <h3 className="font-bold text-ag-secondary-letter text-sm text-center">Missing quantity:</h3>
          {rackInputs.map(({id, missingQuantity}) =>
            (
              <div key={id} className="w-full text-center">
                <p className="my-5">{ missingQuantity ?? "---"}</p>
              </div>
            )
          )}
        </div>

        <div className="flex flex-col items-center min-w-[15rem] w-full border-l border-ag-secondary">
          <h3 className="font-bold text-ag-secondary-letter text-sm text-center">Status:</h3>
          <p className={`my-auto font-bold ${currentItemStatus?.color}`}>{currentItemStatus?.name}</p>
        </div>
      </div>

    </section>
  );
};

export default RowsOrganize;