import { _get, _post, _put } from "./http";

export const getSale = (id) => _get(`/sales/${id}`);

export const getSales = () => _get(`/sales`);

export const saveSale = (salesObject) => _post(`/sales`, salesObject);

export const createSale = (salesObject) => _post(`/sales`, salesObject);

export const changeSalesItems = (id, salesObject) => _put(`/sales/${id}/changeItems`, salesObject);