import { toast } from "react-toastify";
import { alertOptions, formatCurrency, formatModelYears } from "../../../../utils/Utilities";
import FeaturesByProductOrder from "../../../wholesaler/mycart/FeaturesByProductOrder";

const RowsDetails = ({ item, status, typeClient, onReturn, onCompatible, order }) => {
  const handleReturn = (item) => {
    if (!item?.returned) {
      onReturn(item);
    } else {
      toast.warning("A return request for the selected product has already been submitted", alertOptions);
    }
  };

  const isProduct = Object.hasOwn(item, "productInventory");

  const firstOption = item?.compatible_vehicles && item?.compatible_vehicles[0];
  const displayYear = formatModelYears(firstOption?.year);
  const nagTitle = `${firstOption?.nag} (${displayYear})`;
  const carTitle = `${firstOption?.brand} ${firstOption?.model} ${firstOption?.type_car}`;

  const accessoryName = item?.accessoryInventory?.accessory?.name;

  return (
    <tr>
      <td className="w-[55%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        {
          item?.type !== "POWER_WINDOW_REPAIR" && item?.type !== "WINDOW_TINT" ?
            <div className="lg:flex lg:justify-center lg:items-center lg:space-x-1">
              <div className="flex flex-col">
                <div>
                  {order.warehouse.name}
                </div>

                <div >
                  <p className="w-40 m-auto" title={isProduct ? nagTitle : accessoryName }>
                    { isProduct ? `${firstOption?.nag} (${displayYear})` : accessoryName}
                  </p>

                  {isProduct &&
                    <p className="w-40 m-auto" title={carTitle}>
                      {firstOption?.brand} {firstOption?.model} {firstOption?.type_car}
                    </p>
                  }

                </div>
              </div>

              <div className="pt-1 pl-2">
                <p className="w-40 m-auto font-semibold">
                  Total service cost:
                </p>
                <p className="w-40">
                  ${item?.laborCost ?? 0}
                </p>
              </div>

              {isProduct &&
                (
                  <div className="pt-1 pl-2">
                    <div>
                      {
                        item?.compatible_vehicles?.length > 1 ?
                          <p className="truncate w-40 m-auto font-semibold cursor-pointer" onClick={() => onCompatible(item)}>
                                                See compatible vehicles +
                          </p>
                          : null
                      }
                    </div>
                  </div>
                )
              }

            </div>
            :
            <p>{ `${ item?.year } ${item?.brand} ${item?.model} ${item?.typeGlass}` }</p>
        }
      </td>

      <td className="w-[14%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
        {isProduct ? <FeaturesByProductOrder features={item} /> : <p className="w-full text-ag-secondary italic">-----</p> }
      </td>

      <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        { item?.retailUser ? 1 : item?.quantity }
      </td>

      <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        { item?.retailUser && (item?.type !== "POWER_WINDOW_REPAIR" && item?.type !== "WINDOW_TINT")  ? formatCurrency(item?.selectProduct?.price)  : formatCurrency(item.price) }
      </td>

      <td className={`w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3 ${ item.discount ? "bg-blue-200" : "bg-red-200" }`}>
        { item?.discount ? "Yes" : "None" }
      </td>

      <td className="w-[15%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-3">
        {
          (status === "delivered" || status === "paid") && typeClient === "WHOLESALER_USER" ?
            <button className="w-full bg-red-700 px-4 py-2 text-white text-xs rounded-xl truncate" type="button" onClick={() => handleReturn(item)}>
                            Report Return
            </button>
            : "-----"
        }
      </td>
    </tr>
  );
};

export default RowsDetails;