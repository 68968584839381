import { Fragment } from "react";
import { useItemFormUtils } from "./useItemFormUtils";

export const ChangeItemForm = ({ product, index }) => {
  const {
    handleDiscountTypeChange,
    handleChangeQuantity,
    handleAmountChange,
    error,
    handleLaborCheck,
    handleLaborCost
  } = useItemFormUtils();

  return (
    <tr className="border-b border-b-ag-secondary">
      <td colSpan={2}>
        <table className="w-full min-w-[800px]">
          <thead>
            <tr className="text-center">
              <th className="pb-2">Products</th>
              <th className="pb-2">Features</th>
              <th className="pb-2">Unit cost</th>
              <th className="pb-2">Discount type</th>
              <th className="pb-2">Discount Amount</th>
            </tr>
          </thead>

          <tbody>
            <Fragment key={product._id}>
              <tr className="text-center">
                <td className="py-2">
                  <span className="rounded-md bg-gray-100 px-2 py-1 text-sm">
                    {product.nags ? product.nags : product.name}
                  </span>
                </td>
                <td className="py-2 w-[20rem] px-[10px]">
                  <span className="rounded-md bg-gray-100 px-2 py-1 text-sm">
                    {product.features ? product.features : 'No features'}
                  </span>
                </td>
                <td className="py-2">
                  <span className="rounded-md bg-gray-100 px-2 py-1 text-sm">
                  ${product.cost ? product.cost.toFixed(2) : ''}
                  </span>
                </td>
                <td className="py-2 px-[10px]">
                  <select
                    className="rounded-md border py-1 text-sm focus:border-emerald-500 focus:outline-none focus:ring-2 focus:ring-emerald-500"
                    value={product?.newDiscountType ?? product?.discountType}
                    onChange={(e) =>
                      handleDiscountTypeChange(index, e.target.value)
                    }
                  >
                    <option value="">Select discount type</option>
                    <option value="%">%</option>
                    <option value="$">$</option>
                  </select>
                </td>
                <td className="py-2 text-center">
                  <input
                    type="text"
                    value={product?.amount ?? product?.discount}
                    onChange={(e) => handleAmountChange(index, e.target.value)}
                    disabled={!product?.discountType && !product?.newDiscountType}
                    className="w-20 rounded-md border px-2 py-1 text-sm focus:border-emerald-500 focus:outline-none focus:ring-2 focus:ring-emerald-500"
                  />
                </td>
              </tr>

              <tr>
                <td className="py-2 text-center space-x-4">
                  <input
                    type='checkbox'
                    className='accent-ag-active' checked={product?.newLaborChecked ?? product?.laborCharge}
                    onChange={(e) => handleLaborCheck(e.target.checked, index)}
                  />
                  <span>Service</span>
                </td>
                <td className="py-2 text-center">
                  <input
                    type='text'
                    className='w-full rounded-md border px-2 py-1 text-sm focus:border-emerald-500 focus:outline-none focus:ring-2 focus:ring-emerald-500'
                    onChange={(e) => handleLaborCost(e.target.value, index)}
                    disabled={product?.newLaborChecked != undefined ? !product?.newLaborChecked : !product?.laborCharge}
                    min={0}
                    value={product?.newLaborCost ?? product?.laborCost }
                  />
                </td>
              </tr>

              <tr className="border-t border-t-ag-secondary">
                <td colSpan={2} className="py-2 px-4 text-sm space-x-1">
                  <span>Quantity available:</span>
                  <span>{product?.quantityAvailable}</span>
                </td>
                <td colSpan={2} className="text-right py-2 px-1 text-sm">
                  <span className="text-sm text-ag-primary-light">{error}</span>
                </td>
                <td colSpan={2} className="text-right py-2 px-1 text-sm">
                  <span>Quantity:</span>
                  <input
                    type="number"
                    defaultValue={product.quantity}
                    className="rounded-md bg-gray-100 px-2 py-1 text-sm w-20 text-center"
                    min={0}
                    onChange={(e) => {
                      handleChangeQuantity(e, index, product?.quantityAvailable);
                    }}
                  />
                </td>
              </tr>
            </Fragment>
          </tbody>
        </table>
      </td>
    </tr>
  );
};
