import ProductInformationRow from "./ProductInformationRow";

const ProductInformationTable = () => {

  return (
    <table className="col-span-12 w-full">
      <thead>
        <tr className="text-center">
          <th className="min-w-[12rem] font-medium text-ag-secondary-dark">Name</th>
          <th className="min-w-[9rem] font-medium text-ag-secondary-dark">NAG</th>
          <th className="min-w-[12rem] font-medium text-ag-secondary-dark">Features</th>
          <th className="min-w-[10rem] font-medium text-ag-secondary-dark">Accepted quantity</th>
        </tr>
      </thead>
      <tbody>
        <ProductInformationRow/>
      </tbody>
    </table>
  );
};

export default ProductInformationTable;