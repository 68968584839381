import { BsFillCheckCircleFill } from "react-icons/bs";
import FeaturesByProductOrder from "../../../wholesaler/mycart/FeaturesByProductOrder";
import { useRowsDeliveryUtils } from "./useRowsDeliveryUtils";

const RowsDelivery = ({ item, itemNumber }) => {
  const {
    isProduct,
    handleChange,
    values,
    handleCheck
  } = useRowsDeliveryUtils({ item });

  return (
    <section className="inline-block mb-4 min-w-full">
      <h2 className=" w-full bg-ag-secondary-dark px-2 py-1 text-white rounded-t-lg">Item {itemNumber}</h2>
      <div className="flex border border-ag-secondary rounded-b-lg gap-2 py-2">
        <div className="flex flex-col justify-center min-w-[30rem] text-sm p-4 gap-2">
          <p className="font-bold text-ag-secondary-letter">Product name:</p>
          <p className="text-sm text-ag-secondary">{isProduct ? item?.productInventory?.product?.name : item?.accessoryInventory?.accessory?.name}</p>
          <p className="text-sm text-ag-secondary">{isProduct && item?.productInventory?.product?.nags}</p>
          {isProduct && (
            <>
              <hr className="border border-ag-secondary mt-4" />
              <p className="font-bold text-ag-secondary-letter">Features:</p>
              <FeaturesByProductOrder features={item} />
            </>
          )}
        </div>
        <div className="flex flex-col items-center min-w-[15rem] border-l border-ag-secondary text-ag-secondary-letter text-sm text-center gap-4">
          <h3 className="font-bold">Expected:</h3>
          <p>{item?.type !== "POWER_WINDOW_REPAIR" && item?.type !== "WINDOW_TINT" ? item?.quantity : 1}</p>
        </div>

        <div className="flex flex-col items-center min-w-[15rem] border-l border-ag-secondary text-ag-secondary-letter text-sm text-center gap-4">
          <h3 className="font-bold">Real:</h3>
          {item?.type !== "POWER_WINDOW_REPAIR" && item?.type !== "WINDOW_TINT" ?
            (
              <input
                className="min-w-[10rem] h-8 text-center border border-ag-secondary-light rounded-md"
                name="real"
                type="number"
                min={0}
                onChange={handleChange}
                defaultValue={item?.real}
                value={values.real}
              />
            ) : "---"
          }
        </div>

        <div className="flex flex-col items-center min-w-[15rem] border-l border-ag-secondary gap-4">
          <h3 className="font-bold text-ag-secondary-letter text-sm text-center">Discrepancy:</h3>
          {item?.type !== "POWER_WINDOW_REPAIR" && item?.type !== "WINDOW_TINT" ?
            (
              <div className="flex flex-col items-center justify-center gap-2">
                <p className="min-w-[10rem] h-8 text-center border border-ag-secondary-light rounded-md">
                  {item?.real ? Math.abs(item?.quantity - item?.real) : ""}
                </p>
                <p className="font-medium text-sm text-ag-primary">{item?.quantity - item?.real < 0 && "Error"}</p>
              </div>
            ) : "---"}
        </div>

        <div className="flex flex-col items-center min-w-[15rem] border-l border-ag-secondary gap-4">
          <h3 className="font-bold text-ag-secondary-letter text-sm text-center">Notes:</h3>
          {item?.type !== "POWER_WINDOW_REPAIR" && item?.type !== "WINDOW_TINT" ?
            (
              <input
                className="min-w-[10rem] h-8 text-center border border-ag-secondary-light rounded-md"
                name="note"
                type="text"
                maxLength={1000}
                onChange={handleChange}
                defaultValue={item?.note}
              />
            ) : "---"
          }
        </div>

        <div className="flex flex-col items-center min-w-[15rem] border-l border-ag-secondary ">
          <h3 className="font-bold text-ag-secondary-letter text-sm text-center">Actions:</h3>
          <div className="my-auto">
            {item?.type !== "POWER_WINDOW_REPAIR" && item?.type !== "WINDOW_TINT" ?
              (
                <div
                  className={`flex justify-center items-center space-x-1 cursor-pointer
                  ${item?.check && item?.qtyDefault === item?.real ? "text-ag-secondary-light" : "text-green-500"}`}
                  onClick={() => handleCheck(item, isProduct)}
                >
                  <span className="text-lg"><BsFillCheckCircleFill /></span>
                  <span className="text-xs">Check</span>
                </div>
              ) : "---"}
          </div>
        </div>
      </div>
    </section>
  );
};

export default RowsDelivery;
