import { useNavigate } from "react-router-dom";
import { alertOptions, formatCurrency, formatDateHourOrders } from "../../../utils/Utilities";
import { toast } from "react-toastify";
import FeaturesByProductOrder from "../../wholesaler/mycart/FeaturesByProductOrder";


const arrStatus = {
  canceled: { text: "Canceled", color: "bg-zinc-200" },
  delivered: { text: "Delivered", color: "bg-blue-200" },
  inprocess: { text: "In process", color: "bg-ag-order-pending" },
  paid: { text: "Paid", color: "bg-ag-order-delivered" },
  pending: { text: "Pending", color: "bg-ag-order-inprocess" },
  attend: { text: "Attended", color: "bg-ag-order-inprocess" }
};

const typeOpts = {
  POWER_WINDOW_REPAIR: "Power Window Repair",
  WINDOW_TINT: "Window Tint",
};

const RowsOrders = ({ enableActions, order, onCancel, onAttend, onProduct, onCompatible }) => {
  const navigate = useNavigate();

  const handleOrganize = () => {
    if (!order?.pendingInventoryAllocation) {
      navigate(`/orders/organize/${order._id}`);
    } else {
      toast.warning("The product must first be added to inventory", alertOptions);
    }
  };

  console.log(order?.paymentState);
  console.log(order?.immediatePayment);

  const isDeliveredAndPaid = order?.status === "delivered" && order?.paymentState === "PAID";
  const allItemsArr = [...order.productsOrder, ...order.accessoriesOrder];
  return (
    <tr>
      <td className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
        <ul>
          <li>{formatDateHourOrders(order.createdAt)}</li>
          <li>{`ID: ${String(order?._id).substring(String(order?._id).length - 13).toLocaleUpperCase()}`}</li>
        </ul>
      </td>
      <td className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal py-6">
        <ul>
          {
            order?.productsOrder && order?.productsOrder.length > 0 ? order?.productsOrder.map((product, i) => {
              if (i < 5) {
                return (
                  <div key={i} className={order?.productsOrder.length > 1 ? "flex justify-center items-center space-x-3 w-full py-4 border-b-[0.5px] border-ag-secondary-light mt-[-1px] last:border-b-[0px] h-[190px] overflow-y-auto" : 'flex justify-center items-center space-x-3 w-full'}>
                    <div>
                      <p className="truncate w-40 py-2" key={i}>
                        {String(product?.year !== ''?product?.year:'----')}
                      </p>
                    </div>
                  </div>
                );
              }
            })
              :
              <div className={order?.productsOrder.length > 1 ? "flex justify-center items-center space-x-3 w-full py-4 border-b-[0.5px] border-ag-secondary-light mt-[-1px] last:border-b-[0px] h-[190px] overflow-y-auto" : 'flex justify-center items-center space-x-3 w-full'}>
                <div>
                  <p className="truncate w-40 py-2">
                    {String(order?.orderType === 'QUOTES'? order?.quote?.year: '----')}
                  </p>
                </div>
              </div>
          }
        </ul>
      </td>
      <td className="w-[28%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal">
        {
          order?.quote?.searchType === "photos" && order.productsOrder.length === 0 ?
            <span>Quote by photo...</span>
            :
            order?.orderType === "WINDOW_TINT" || order?.orderType === "POWER_WINDOW_REPAIR" ?
              <>
                <p>{typeOpts[order?.orderType]}</p>
                <p>{`${order?.anotherQuote?.service}`}</p>
              </>
              :
              <>
                <ul>
                  {
                    order?.productsOrder && order?.productsOrder.length > 0 ? allItemsArr.map((product, i) => {
                      if (i < 5) {
                        if (Object.hasOwn(product, 'accessoryInventory')) {
                          return (
                            <div key={i} className={"flex justify-center items-center w-full border-b-[0.5px] border-ag-secondary-light last:border-b-[0px] h-[60px] overflow-y-auto" }>
                              <div className="flex flex-col">
                                {order.warehouse.name}
                                <p>{product.accessoryInventory.accessory.name}</p>
                              </div>
                            </div>
                          );
                        }
                        return (
                          <>
                            <div className={ "flex justify-center items-center space-x-3 w-full py-4 border-b-[0.5px] border-ag-secondary-light mt-[-1px] last:border-b-[0px] h-[190px] overflow-y-auto" }>
                              <div className="flex flex-col">
                                <div>
                                  {order.warehouse.name}
                                </div>

                                {
                                  product.productInventory.product.compatible_vehicles.map((prodComp, i) => {
                                    if (i === 0) {
                                      return (
                                        <div key={i} className="py-2 flex flex-col justify-center items-center">
                                          <p className="truncate w-40 uppercase" key={i} title={
                                            `${prodComp?.nag} ` +
                                            `${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length - 1)]}` : prodComp?.year} ` +
                                            `${prodComp?.brand} ` +
                                            `${prodComp?.model} ` +
                                            `${prodComp?.type_car}`
                                          }>
                                            {prodComp?.nag}{' ('}
                                            {String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length - 1)]}` : prodComp?.year}{') '}
                                          </p>
                                          <p className="uppercase ">
                                            {prodComp?.brand}{' '}
                                            {prodComp?.model}{' '}
                                            {prodComp?.type_car}
                                          </p>
                                        </div>
                                      );
                                    }
                                  })
                                }
                              </div>
                              <div className="pt-1 pl-2 flex]">
                                <div className="w-32 items-center">
                                  <FeaturesByProductOrder features={product.productInventory.product} />
                                </div>
                                <div className="flex justify-center items-center">
                                  {
                                    product.productInventory.product.compatible_vehicles.length > 1 ?
                                      <p className="truncate w-32 font-semibold cursor-pointer" onClick={() => onCompatible(product.productInventory.product)}>
                                        See compatible vehicles +
                                      </p>
                                      : null
                                  }
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      }
                    })
                      :
                      order?.quote?.selectProduct?.compatible_vehicles && order?.quote?.selectProduct?.compatible_vehicles.length > 0 ? (
                        <div className="flex justify-center items-center space-x-3">
                          <div className="flex flex-col">
                            <div>
                              {order.warehouse.name}
                            </div>

                            {
                              order?.quote?.selectProduct?.compatible_vehicles.map((prodComp, i) => {
                                if (i === 0) {
                                  return (
                                    <div key={i}>
                                      <p className="truncate w-40 uppercase " key={i} title={
                                        `${prodComp?.nag} ` +
                                      `${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length - 1)]}` : prodComp?.year} ` +
                                      `${prodComp?.brand} ` +
                                      `${prodComp?.model} ` +
                                      `${prodComp?.type_car}`
                                      }>
                                        {prodComp?.nag}{' ('}
                                        {String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length - 1)]}` : prodComp?.year}{') '}
                                      </p>
                                      <p className="uppercase ">
                                        {prodComp?.brand}{' '}
                                        {prodComp?.model}{' '}
                                        {prodComp?.type_car}
                                      </p>
                                    </div>
                                  );
                                }
                              })
                            }
                          </div>
                          <div className="pt-1 pl-2 flex">
                            <div className="w-32 items-center">

                              <FeaturesByProductOrder features={order?.quote?.selectProduct} />
                            </div>
                            <div className="flex justify-center items-center">
                              {
                                order?.quote?.selectProduct?.compatible_vehicles.length > 1 ?
                                  <p className="truncate w-32 font-semibold cursor-pointer" onClick={() => onCompatible(order?.quote?.selectProduct)}>
                                  See compatible vehicles +
                                  </p>
                                  : null
                              }
                            </div>
                          </div>
                        </div>
                      )
                        :
                        (

                          order.accessoriesOrder && order.accessoriesOrder.length > 0 && order.accessoriesOrder.map((accessory, i) => {
                            if (i < 5)
                              return (
                                <div key={i} className={order?.accessoriesOrder.length > 1 ? "flex justify-center items-center w-full border-b-[0.5px] border-ag-secondary-light last:border-b-[0px] h-[60px] overflow-y-auto" : 'flex justify-center items-center space-x-3 w-full'}>
                                  <div className="flex flex-col">
                                    {order.warehouse.name}
                                    <p>{accessory.accessoryInventory.accessory.name}</p>
                                  </div>
                                </div>
                              );
                          })
                        )


                  }
                </ul>
              </>

        }
      </td>
      <td className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">{formatCurrency(order.total)}</td>
      <td
        className={`w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6 
          ${isDeliveredAndPaid ? "bg-ag-order-delivered" : arrStatus[order.status]?.color}`}
      >
        {isDeliveredAndPaid ? "Paid" : order?.status ? arrStatus[order.status]?.text : ""}
      </td>
      <td className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
        {order.employee ? order.employee.names : "-----"}
      </td>
      <td className="w-[12%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] font-normal px-2 py-6">
        <div className="grid grid-cols-2 gap-1">
          <button className="w-full bg-gray-300 p-3 disabled:bg-zinc-200" type="button" disabled={!enableActions} onClick={() => navigate(`/orders/detail/${order._id}`)}>
            Details
          </button>
          {
            order.status === "pending" ?
              <button className="bg-gray-300 p-3 disabled:bg-zinc-200" type="button" disabled={!enableActions} onClick={() => onAttend(order._id)}>
                Attend
              </button>
              : null
          }
          {
            order.status === "attend" && order?.pendingInventoryAllocation ?
              <button className="w-full bg-blue-900 text-white p-3 disabled:bg-zinc-200" type="button" disabled={!enableActions} onClick={() => onProduct(order?.quote?.selectProduct, order?._id, order?.warehouse)}>
                Add
              </button>
              : null
          }
          {
            order.status === "attend" && (order?.orderType !== "WINDOW_TINT" && order?.orderType !== "POWER_WINDOW_REPAIR") && !order?.pendingInventoryAllocation ?
              <button className="w-full bg-green-600 text-white p-3 disabled:bg-zinc-200" type="button" disabled={!enableActions} onClick={handleOrganize}>
                Dispatch
              </button>
              : null
          }
          {
            order.status === "inprocess" || (order.status === "attend" && (order?.orderType === "WINDOW_TINT" || order?.orderType === "POWER_WINDOW_REPAIR")) ?
              <button className="w-full bg-blue-400 text-white p-3 disabled:bg-zinc-200" type="button" disabled={!enableActions} onClick={() => navigate(`/orders/delivery/${order._id}`)}>
                Deliver
              </button>
              : null
          }
          {
            order.status !== "canceled" && order.status !== "delivered" && order.status !== "paid" ?
              <button
                className="bg-ag-primary-light text-white p-3 disabled:bg-zinc-200 disabled:cursor-not-allowed"
                type="button"
                disabled={!enableActions || (order?.immediatePayment && order?.paymentState === "PAID")}
                onClick={() => onCancel(order._id)}
              >
                Cancel
              </button>
              : null
          }
        </div>
      </td>
    </tr>
  );
};

export default RowsOrders;