import { useOrderActionsContext } from '../OrderActionsContext';

const OrganizeOrderHeaderButtons = () => {
  const {
    handleTicket,
    setOpen,
    loader,
    isPaid
  } = useOrderActionsContext();
  return (
    <>
      <div className="grid col-span-12 md:grid-cols-6 md:col-span-6 gap-4">
        {!isPaid && (
          <button
            className="bg-orange-400 border border-ag-secondary rounded-lg md:col-span-3 lg:col-span-2 py-1 disabled:cursor-not-allowed text-white"
            type="button"
            onClick={() => setOpen(true)}
            disabled={ loader }
          >
            Change order
          </button>
        )}

      </div>

      {isPaid && (
        <div className="grid col-span-12 md:grid-cols-6 md:col-span-6">
          <button
            className="bg-ag-active border border-ag-secondary rounded-lg md:col-span-3 md:col-start-4 lg:col-span-2 lg:col-start-5 py-1 disabled:cursor-not-allowed text-white"
            type="button"
            onClick={handleTicket}
            disabled={ loader }
          >
            View ticket
          </button>
        </div>
      )}
    </>
  );
};

export default OrganizeOrderHeaderButtons;