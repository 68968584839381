import { useOrderActionsContext } from '../OrderActionsContext';

const OrganizeOrderFooterButtons = () => {
  const {
    loader,
    handleCancel,
    handleCancelOrganization,
    disableSubmitButton,
    handleToShip,
    order
  } = useOrderActionsContext();

  return (
    <div className="grid grid-cols-12 col-span-12 text-white gap-4">
      <div className="grid col-span-12 md:grid-cols-6 md:col-span-6">
        <button
          className="bg-ag-primary border rounded-lg md:col-span-3 lg:col-span-2 py-1 disabled:cursor-not-allowed"
          type="button"
          disabled={ loader || (order?.immediatePayment && order?.paymentState === "PAID")}
          onClick={handleCancel}
        >
          Cancel order
        </button>
      </div>
      <div className="grid col-span-12 md:grid-cols-6 md:col-span-6 gap-4">
        <button
          className="bg-ag-primary-light border rounded-lg lg:col-start-3 md:col-span-3 lg:col-span-2 py-1 disabled:cursor-not-allowed"
          type="button"
          disabled={loader}
          onClick={handleCancelOrganization}
        >
          Cancel
        </button>
        <button
          className="bg-ag-active border rounded-lg md:col-span-3 lg:col-span-2 py-1 disabled:cursor-not-allowed"
          type="button"
          disabled={loader || disableSubmitButton}
          onClick={handleToShip}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default OrganizeOrderFooterButtons;