import { useChangeOrderContext } from "./ChangeOrderContext";
import { ChangeRowItem } from "./ChangeRowItem";

export const TableChangeProducts = () => {
  const { globalSaleItems, nonExistentProducts, saleNote, handleChangeSaleNote } = useChangeOrderContext();

  if (nonExistentProducts)
    return (
      <h2
        className="w-full flex justify-center items-center font-bold text-xl text-ag-secondary-letter"
      >
        There are no products to show
      </h2>
    );
  return (
    <section className="inline-block overflow-x-auto w-full lg:min-w-[65%] ">
      <table>
        <thead>
          <tr className="bg-ag-secondary text-white w-full rounded">
            <th className="min-w-[20rem] text-sm font-normal px-[100px] md:px-20 lg:px-2 py-2 lg:text-left rounded-tl-lg">
              Products
            </th>
            <th className="w-[10%] text-sm font-normal px-14 md:px-16 py-2 rounded-tr-lg">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {globalSaleItems.map((product, index) =>
            (<ChangeRowItem key={index} product={product} index={index} />)
          )}
        </tbody>
      </table>
      <h2 className="font-bold text-xl text-ag-secondary-letter mt-4">Note</h2>
      <textarea
        className="w-full min-w-[35.6rem] min-h-[10rem] border border-ag-secondary-light disabled:bg-zinc-200 disabled:cursor-not-allowed resize-none px-2 text-sm"
        maxLength={2500}
        onChange={handleChangeSaleNote}
        value={saleNote}
      />
    </section>
  );
};
