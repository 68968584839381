import { formatCurrency, formatDateHourOrders } from "../../../../utils/Utilities";
import { useReturnProcessContext } from "./ReturnProcessContext";

const ReturnProcessDetailTable = () => {
  const {
    returnedOrder,
    isProductOrder,
    returnBody,
    dataSession,
    itemDiscount,
    itemLaborCost
  } = useReturnProcessContext();
  const returnId = String(returnedOrder?._id).substring(String(returnedOrder?._id).length - 13).toLocaleUpperCase();
  const fullProductName = `${returnedOrder?.productOrder?.productInventory?.product?.nags} - ${returnedOrder?.productOrder?.productInventory?.product?.name}`;

  return (
    <div className="grid grid-cols-2 col-span-12 lg:col-span-5 text-sm">
      <div className="grid grid-cols-2 col-span-2 bg-ag-secondary text-white font-bold rounded-t-xl px-2 py-1">
        <h3 className="col-span-1">Purchase date</h3>
        <h3 className="col-span-1">Customer</h3>
      </div>

      <div className="grid grid-cols-2 col-span-2 text-sm px-2 py-1 border border-ag-secondary-light">
        <div className="flex flex-col col-span-1 border-r-2 border-ag-secondary-light my-2 gap-2 flex-wrap">
          <p className="w-fit break-all">{`${formatDateHourOrders(returnedOrder?.createdAt)}`}</p>
          <p className="w-fit break-all">{`ID: ${returnId}`}</p>
        </div>
        <p className="col-span-1 text-center px-2 my-auto">
          {`${returnedOrder?.wholesaler?.names} ${returnedOrder?.wholesaler?.lastNames ?? ''}`}
        </p>

      </div>

      <div className="grid grid-cols-2 col-span-2 bg-ag-secondary text-white font-bold px-2 py-1">
        <h3 className="col-span-1">Items</h3>
        <h3 className="col-span-1">Quantity</h3>
      </div>

      <div className="grid grid-cols-2 col-span-2 text-sm px-2 py-1 border border-ag-secondary-light overflow-y-auto">
        <div className="flex flex-col col-span-1 border-r-2 border-ag-secondary-light my-2 gap-2 justify-center items-center flex-wrap">
          <p
            className="text-center max-w-[15rem] p-4 break-all"
            title={isProductOrder ? fullProductName : ""}
          >
            { isProductOrder ? fullProductName : returnedOrder?.accessoryOrder?.accessoryInventory?.accessory?.name}
          </p>
        </div>
        <div className="flex flex-col col-span-1 gap-2 justify-center items-center w-full">
          <p>{returnedOrder?.quantity}</p>
        </div>
      </div>

      <div className="grid grid-cols-2 col-span-2 bg-ag-secondary text-white font-bold px-2 py-1">
        <h3 className="col-span-1">Home warehouse</h3>
        <h3 className="col-span-1">Receiving warehouse</h3>
      </div>

      <div className="grid grid-cols-2 col-span-2 text-sm px-2 py-1 border border-ag-secondary-light">
        <p className="col-span-1 text-center px-2 border-r-2 border-ag-secondary-light my-2">{returnedOrder?.warehouse?.name}</p>
        <p className="col-span-1 my-auto px-2 text-center">
          {returnBody?.warehouse?.name === "" ? "---" : returnBody?.warehouse?.name}
        </p>
      </div>

      <div className="grid grid-cols-2 col-span-2 bg-ag-secondary text-white font-bold px-2 py-1">
        <h3 className="col-span-1">Total cost</h3>
        <h3 className="col-span-1">Refunded credits</h3>
      </div>

      <div className="grid grid-cols-2 col-span-2 text-sm px-2 py-1 border border-ag-secondary-light">
        <div className="grid col-span-1 text-center px-2 border-r-2 border-ag-secondary-light my-2 gap-2">
          <p>{formatCurrency(returnedOrder?.total)}</p>
          {returnedOrder?.status && returnedOrder?.status === "INPROCESS" && <p>Labor: {formatCurrency(itemLaborCost)}</p>}
        </div>
        <p className="col-span-1 my-auto px-2 text-center">
          {returnedOrder?.status && returnedOrder?.status === "APPROVED" ? formatCurrency(itemDiscount) : "---"}
        </p>
      </div>

      <div className="grid grid-cols-2 col-span-2 bg-ag-secondary text-white font-bold px-2 py-1">
        <h3 className="col-span-1">Sales employee</h3>
        <h3 className="col-span-1">Return employee</h3>
      </div>

      <div className="grid grid-cols-2 col-span-2 text-sm px-2 py-1 border border-ag-secondary-light">
        <p className="col-span-1 text-center px-2 border-r-2 border-ag-secondary-light my-2">{`${returnedOrder?.employee?.names} ${returnedOrder?.employee?.lastNames}` }</p>
        <p className="col-span-1 my-auto px-2 text-center">{`${dataSession?.names} ${dataSession?.lastNames}` }</p>
      </div>

    </div>
  );
};

export default ReturnProcessDetailTable;