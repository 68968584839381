import { ChangeItemForm } from "./ChangeItemForm";
import { useOrderActionsContext } from "../OrderActionsContext";
import { useItemFormUtils } from "./useItemFormUtils";

export const ChangeRowItem = ({ product, index }) => {
  const { isProductItem } = useOrderActionsContext();
  const { handleRemoveProduct, resetItemValues, updateItem } = useItemFormUtils();
  const isProduct = isProductItem(product);

  return (
    <>
      <tr className="border-b border-b-ag-secondary">
        <td className="min-w-full text-ag-secondary-letter text-sm font-normal px-2 py-3 flex justify-center">
          {isProduct ? `${product.nags} - ` : ''}{product.name}
        </td>
        <td className="min-w-[10rem] text-ag-secondary-letter text-sm font-normal px-2 py-3">
          <div className="flex gap-4 justify-center items-center text-white">
            <button
              className="bg-ag-primary-light py-1 px-3 rounded-xl"
              type="button"
              onClick={() => handleRemoveProduct(index)}
            >
              Remove
            </button>
            <button
              className="bg-ag-secondary py-1 px-3 rounded-xl disabled:bg-ag-secondary-light"
              type="button"
              onClick={() => resetItemValues(index)}
              disabled={!product?.canEdit}
            >
              Cancel
            </button>
            <button
              className="bg-ag-active py-1 px-3 rounded-xl"
              type="button"
              onClick={() => updateItem(index, !product?.canEdit)}
            >
              {!product?.canEdit ? "Modify" : "Apply"}
            </button>
          </div>
        </td>
      </tr>
      {product?.canEdit && (
        <ChangeItemForm product={product} index={index} />
      )}
    </>

  );
};
