import { IoMdCloseCircle } from "react-icons/io";
import { PaymentSummary } from "./PaymentSummary";
import { TableChangeProducts } from "./TableChangeProducts";
import { useOrderActionsContext } from "../OrderActionsContext";
import SelectAccesory from "../../sales/SelectAccesory";
import SelectProduct from "../../sales/SelectProduct";
import { useChangeOrderContext } from "./ChangeOrderContext";
import Loader from "../../../../components/loader/Loader";
import { useItemFormUtils } from "./useItemFormUtils";

const ChangeOrderModal = () => {
  const { setOpen, order: { warehouse } } = useOrderActionsContext();
  const {
    showProductModal,
    setShowProductModal,
    showAccessoryModal,
    setShowAccessoryModal,
    loadingSaleInfo,
  } = useChangeOrderContext();

  const { addNewProductToOrder } = useItemFormUtils();

  if (loadingSaleInfo) return <Loader />;
  return (
    <>
      <div className="block">
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none mb-8">
          <div className="w-[90dvw] max-h-[90dvh]">
            <div className="p-2 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
              <div className="w-full flex items-center justify-end rounded text-center">
                <div className="w-[5%]">
                  <button
                    className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <IoMdCloseCircle />
                  </button>
                </div>
              </div>
              <div className="relative px-5 py-3 flex flex-col space-y-5">
                <h3 className="text-center text-2xl font-bold">Change Order</h3>
                <div className="flex flex-col lg:flex-row gap-2">
                  <TableChangeProducts />
                  <PaymentSummary />
                </div>
                <div className="flex flex-col lg:flex-row justify-center lg:justify-end gap-2 items-center text-white">
                  <button className="w-full lg:w-auto py-1 px-3 bg-ag-active rounded-lg" type="button" onClick={()=> setShowProductModal(true)}>
                    Add glass
                  </button>
                  <button className="w-full lg:w-auto py-1 px-3 bg-ag-active rounded-lg" type="button" onClick={()=> setShowAccessoryModal(true)}>
                    Add accessory
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>

      {showAccessoryModal && (
        <SelectAccesory
          onClose={() => setShowAccessoryModal(false)}
          addToCart={addNewProductToOrder}
          warehouseSelected={{_id: warehouse}}
        />
      )}

      {showProductModal && (
        <SelectProduct
          onClose={() => setShowProductModal(false)}
          addToCart={addNewProductToOrder}
          warehouseSelected={{_id: warehouse}}
        />
      )}
    </>
  );
};

export default ChangeOrderModal;