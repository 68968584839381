import ModalInput from '../../../../components/modal/ModalInput';
import ModalSignature from '../../../../components/modal/ModalSignature';
import { useOrderActionsContext } from '../OrderActionsContext';
import ModalInputNumber from "../../../../components/modal/ModalNumber";
import ModalDelivery from './ModalDelivery';
import { IoMdCloseCircle } from 'react-icons/io';

const DeliveryOrderHeaderButtons = () => {
  const {
    handleTicket,
    loader,
    isPaid,
    showConfirm,
    setShowConfirm,
    showConfirmNumber,
    setShowConfirmNumber,
    showConfirmSignature,
    setShowConfirmSignature,
    setShowDelivery,
    handleConfirmDelivery,
    showDelivery,
    handleInput,
    handleShowModal,
    loadingSubmitModal,
    successSubmitModal,
    handleCloseSuccesSubmitModal,
    handleSetPreviousStatus,
    globalItems
  } = useOrderActionsContext();

  const disableSubmitButton = globalItems?.some((item) => item?.real <= 0 || !item.real);

  return (
    <>
      <div className="grid col-span-12 lg:grid-cols-2 lg:col-span-2 gap-4 items-center">
        <p className='text-xl col-span-2 font-light text-ag-secondary text-center lg:text-left'>Line items</p>
      </div>

      <div className="grid col-span-12 lg:grid-cols-8 lg:col-span-10 gap-2" dir='rtl'>
        <button
          className="bg-ag-secondary border text-sm border-ag-secondary rounded-lg md:col-span-2 py-1 disabled:cursor-not-allowed order-3 lg:order-1 text-white"
          type="button"
          disabled={loader || disableSubmitButton}
          onClick={handleShowModal}
        >
          Ready to delivery
        </button>
        {isPaid && (
          <button
            className="bg-ag-active border text-sm border-ag-secondary rounded-lg md:col-span-2 py-1 disabled:cursor-not-allowed order-2 lg:order-2 text-white"
            type="button"
            onClick={handleTicket}
            disabled={ loader }
          >
            View ticket
          </button>
        )}
        <button
          className="bg-ag-primary-dark text-sm border border-ag-secondary rounded-lg md:col-span-2 py-1 disabled:cursor-not-allowed order-1 lg:order-3 text-white"
          type="button"
          onClick={handleSetPreviousStatus}
        >
          Back to previous state
        </button>
      </div>

      { showConfirm?.status &&
        <ModalInput
          type="text"
          forDelivery={true}
          title={showConfirm?.msg}
          onBack={() => {
            setShowConfirm({...showConfirm, status: false, msg: "" });
            setShowDelivery(true);
          }}
          onClose={() => {
            setShowConfirm({...showConfirm, status: false, msg: "" });
            setShowDelivery(false);
          }}
          onResponse={(valIpt) => handleConfirmDelivery("name", valIpt)}
        />
      }

      { showConfirmNumber?.status &&
        <ModalInputNumber
          type="number"
          forDelivery={true}
          title={showConfirmNumber?.msg}
          onClose={() => {
            setShowConfirmNumber({...showConfirmNumber, status: false, msg: "" });
            setShowDelivery(false);
          }}
          onBack={() => {
            setShowConfirmNumber({...showConfirmNumber, status: false, msg: "" });
            setShowDelivery(true);
          }}
          onResponse={(valIpt) => handleConfirmDelivery("phoneNumber", valIpt)}
        />
      }

      { showConfirmSignature?.status &&
        <ModalSignature
          type="number"
          title={showConfirmSignature?.msg}
          onClose={() => {
            setShowConfirmSignature({...showConfirmSignature, status: false, msg: "" });
            setShowDelivery(false);
          }}
          onBack = {() => {
            setShowConfirmSignature({...showConfirmSignature, status: false, msg: "" });
            setShowDelivery(true);
          }}
          onResponse={(valIpt) => handleConfirmDelivery("signature", valIpt)}
        />
      }

      {
        showDelivery ?
          <ModalDelivery
            onInput={handleInput}
            onClose={() => setShowDelivery(false)}
          />
          : null
      }

      { loadingSubmitModal && (
        <ModalNotification title="Please wait for payment confirmation"/>
      )}

      { successSubmitModal && (
        <ModalNotification
          title="Payment has been confirmed, you can close the window"
          isClosable
          onClose={handleCloseSuccesSubmitModal}
        />

      )}
    </>
  );
};

export default DeliveryOrderHeaderButtons;

const ModalNotification = ({ title, isClosable = true, onClose }) => {
  return (
    <>
      <div className="block">
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="w-[320px] md:w-[500px]">
            <div className="p-2 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
              <div className="w-full flex items-center justify-end rounded text-center">
                <div className="w-[5%]">
                  {isClosable && (
                    <button
                      className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                      onClick={() => onClose()}
                    >
                      <IoMdCloseCircle />
                    </button>
                  )}
                </div>
              </div>

              <div className="mt-4 px-4 py-2">
                <h2 className="text-xl font-semibold text-center mb-4">{title}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};