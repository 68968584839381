import { orderArrStatus, paymentQuote, typeOpts } from "../../../utils/constants";
import { formatDateHourOrders } from "../../../utils/Utilities";
import { ChangeOrderContextProvider } from "./change-order/ChangeOrderContext";
import ChangeOrderModal from "./change-order/ChangeOrderModal";
import DeliveryOrderHeaderButtons from "./delivery/DeliveryOrderHeaderButtons";
import { useOrderActionsContext } from "./OrderActionsContext";
import OrganizeOrderHeaderButtons from "./organize/OrganizeOrderHeaderButtons";

const OrderActionsHeader = () => {
  const {
    order,
    open,
    isAttendStatus,
  } = useOrderActionsContext();

  const isMixedPayment = order?.payType?.paymentMethod === "MIXED";
  const mixedPaymentText = `(CREDIT: $${order?.payType?.storeCreditTotal} - ${order?.payType?.secondMethodPayment}: $${order?.payType?.totalSecondMethodPayment})`;

  return (
    <section className="grid grid-cols-12 gap-4">
      <h3 className="col-span-12 text-lg font-light px-2 text-center md:text-left">General details</h3>
      <div className="grid grid-cols-12 col-span-12 border border-ag-secondary-light rounded-xl p-4 text-ag-secondary gap-4 lg:gap-4">
        <div className="grid grid-cols-2 col-span-12 lg:col-span-6 gap-4 break-words">
          <p className="font-bold text-sm md:text-xs lg:text-sm">Date:</p>
          <p className="text-sm md:text-xs lg:text-sm">{ formatDateHourOrders(order?.date) }</p>

          <p className="font-bold text-sm md:text-xs lg:text-sm">Customer:</p>
          <p className="text-sm md:text-xs lg:text-sm">{ order?.client?.names } { order?.client?.lastNames }</p>

          <p className="font-bold text-sm md:text-xs lg:text-sm">Email:</p>
          <p className="text-sm md:text-xs lg:text-sm text-">{ order?.client?.user?.email }</p>

          <p className="font-bold text-sm md:text-xs lg:text-sm">Phone:</p>
          <p className="text-sm md:text-xs lg:text-sm">{ order?.client?.phoneNumber }</p>

          <p className="font-bold text-sm md:text-xs lg:text-sm">Pay type:</p>
          <div>
            <p className="text-sm md:text-xs lg:text-sm">
              {paymentQuote[order?.payType?.paymentMethod] ?
                paymentQuote[order?.payType?.paymentMethod] : order?.payType?.paymentMethod
              }
            </p>
            {isMixedPayment && <p className="text-sm md:text-xs lg:text-sm">{mixedPaymentText}</p> }
          </div>
          <p className="font-bold text-sm md:text-xs lg:text-sm">Total service cost:</p>
          <p className="text-sm md:text-xs lg:text-sm">${order?.totalLaborCost}</p>

        </div>
        <div className="border-t col-span-12 lg:col-start-7 lg:col-end-7 lg:border-t-0 lg:my-0 lg:border-l border-ag-secondary-light"/>
        <div className="grid grid-cols-2 col-span-12 lg:col-span-5 gap-4 break-words">
          <p className="font-bold text-sm md:text-xs lg:text-sm">Order ID:</p>
          <p className="text-sm md:text-xs lg:text-sm">{String(order?._id).substring(String(order?._id).length - 13).toLocaleUpperCase()}</p>

          <p className="font-bold text-sm md:text-xs lg:text-sm">Order number:</p>
          <p className="text-sm md:text-xs lg:text-sm">{String(order?._id).substring(String(order?._id).length - 5).toLocaleUpperCase()}</p>

          <p className="font-bold text-sm md:text-xs lg:text-sm">Order type:</p>
          <p className="text-sm md:text-xs lg:text-sm">{ typeOpts[order.orderType] }</p>

          <p className="font-bold text-sm md:text-xs lg:text-sm">Status:</p>
          <p className="text-sm md:text-xs lg:text-sm">{ orderArrStatus[order.status]?.text }</p>

          <p className="font-bold text-sm md:text-xs lg:text-sm">Employee:</p>
          <p className="text-sm md:text-xs lg:text-sm">
            {
              order?.employee ?
                `${order?.employee?.names} ${order?.employee?.lastNames}`
                : "No employee assigned..."
            }
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 col-span-12 gap-3">
        <h2 className="font-bold text-xl text-ag-secondary-letter">Note</h2>
        <textarea
          className="w-full min-h-[10rem] border border-ag-secondary-light disabled:bg-zinc-200 disabled:cursor-not-allowed resize-none px-2 text-sm"
          value={order?.note}
          disabled
        />
      </div>

      <div className="grid grid-cols-12 col-span-12 gap-4">
        { isAttendStatus ? <OrganizeOrderHeaderButtons /> : <DeliveryOrderHeaderButtons /> }
      </div>

      {open && (
        <ChangeOrderContextProvider>
          <ChangeOrderModal />
        </ChangeOrderContextProvider>
      )}
    </section>
  );
};

export default OrderActionsHeader;
