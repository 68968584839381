import { useOrderActionsContext } from "../OrderActionsContext";
import { useChangeOrderContext } from "./ChangeOrderContext";

export const PaymentSummary = () => {
  const { order, setOpen } = useOrderActionsContext();
  const {
    globalSaleItems,
    nonExistentProducts,
    updateOrderItems,
    subtotal,
    discount,
    total
  } = useChangeOrderContext();

  const isAtLeastOneLabor = globalSaleItems?.some(product => product?.laborCharge);
  const quantityOfLabors = globalSaleItems?.reduce((acc, product) => product?.laborCharge === true ? acc + 1 : acc, 0);

  return (
    <section className="w-full lg:w-[35%] max-h-max rounded-lg border border-ag-secondary-light p-6">
      <div className="space-y-4">
        <div className="border-b border-b-ag-secondary">
          <label htmlFor="workshop-name" className="text-m font-bold text-gray-600">
            { order?.client?.names } { order?.client?.lastNames }
          </label>
        </div>

        <div className="border-b border-b-ag-secondary pb-2">
          <div className="flex justify-between">
            <span className="text-sm text-gray-600 font-bold">Available credit:</span>
            <span className="text-sm">{order?.client?.availableCredit ?? 0}</span>
          </div>
        </div>

        <div>
          <div className="mb-2 grid grid-cols-2 gap-4">
            <div>
              <label className="text-sm text-gray-600 font-bold">Items:</label>
            </div>
            <div>
              <label className="text-sm text-gray-600 font-bold">Quantity:</label>
            </div>
          </div>
          <div className="space-y-4 border-b border-b-ag-secondary pb-2">
            {globalSaleItems.map((product, index) => (
              <div key={index} className="grid grid-cols-2 gap-4">
                <span className="text-sm text-gray-600">{product?.nags}{product?.nags ? " - " : ""}{product?.name}</span>
                <span className="text-sm px-6">{product?.quantity || 1}</span>
              </div>
            ))}
            {isAtLeastOneLabor &&
              <div className="grid grid-cols-2 gap-4">
                <span className="text-sm text-gray-600">Service</span>
                <span className="text-sm px-6">{quantityOfLabors}</span>
              </div>
            }
          </div>
        </div>

        <div className="space-y-2">
          <div className="flex justify-between">
            <span className="text-sm text-gray-600 font-bold">Subtotal:</span>
            <span className="text-sm">${subtotal.toFixed(2)}</span>
          </div>
          <div className="flex justify-between border-b border-b-ag-secondary pb-2">
            <span className="text-sm text-gray-600 font-bold">Discount:</span>
            <span className="text-sm">${discount.toFixed(2)}</span>
          </div>
          <div className="flex justify-between">
            <span className="text-sm text-gray-600 font-bold">Total:</span>
            <span className="text-sm font-medium">${total.toFixed(2)}</span>
          </div>
        </div>

        <div className="flex gap-4">
          <button
            className="flex-1 rounded-md bg-ag-primary-light py-1 text-white focus:outline-none focus:ring-2 focus:ring-ag-primary-light focus:ring-offset-2"
            type="button"
            onClick={() => setOpen(false)}
          >
            Cancel
          </button>
          <button
            className="flex-1 rounded-md bg-emerald-500 py-1 text-white focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 disabled:cursor-not-allowed"
            type="button"
            disabled={nonExistentProducts}
            onClick={updateOrderItems}
          >
            Accept
          </button>
        </div>
      </div>
    </section>

  );
};
