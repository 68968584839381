import { useReturnProcessContext } from "./ReturnProcessContext";

const ReturnSummary = () => {
  const { returnedOrder, isProductOrder, returnBody } = useReturnProcessContext();
  const fullProductName = `${returnedOrder?.productOrder?.productInventory?.product?.nags} - ${returnedOrder?.productOrder?.productInventory?.product?.name}`;

  return (
    <section className="grid grid-cols-12 gap-4 px-2 border-b border-ag-secondary-light pb-6 min-w-max pt-4">
      <h2 className="col-span-12 font-bold text-xl text-ag-secondary-letter">Return summary</h2>
      <table className="col-span-12 min-w-[25rem]">
        <thead className="text-left">
          <tr>
            <th className="w-[5rem] font-medium text-ag-secondary-dark p-2">Name</th>
            <th className="w-[5rem] font-medium text-ag-secondary-dark p-2">Status</th>
            <th className="w-[5rem] font-medium text-ag-secondary-dark p-2">Rack/section to send</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              title={isProductOrder ? fullProductName : ""}
              className="p-2 max-w-[15rem]"
            >
              {isProductOrder ? fullProductName : returnedOrder?.accessoryOrder?.accessoryInventory?.accessory?.name}
            </td>
            <td className="p-2">
              {returnBody?.isSellable == null ? "---" :
                returnBody?.isSellable ? "Accepted" : "Rejected"}
            </td>
            <td className="p-2 max-w-[15rem]">
              {returnBody?.addRackQuantity?.rack?.name === "" ? "---" : returnBody?.addRackQuantity?.rack?.name}
            </td>
          </tr>
        </tbody>

      </table>
    </section>
  );
};

export default ReturnSummary;