import { useEffect } from "react";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { BiSearch } from "react-icons/bi";
import { FaWindowClose } from "react-icons/fa";
import { Link } from "react-router-dom";

const initialFilters = {
  search: "",
  warehouse: "",
  employee: "",
  client: "",
  dateStart: "",
  dateEnd: ""
};

const ReturnFilters = ({ enableFilters, warehouses, employees, clients, wordFilter, onFilters, permits, setSearchWord }) => {

  const [focusClass, setFocusClass] = useState({
    search: { placeholder: "Search", status: false },
    warehouse: { placeholder: "", status: false },
    employee: { placeholder: "", status: false },
    client: { placeholder: "", status: false }
  });

  const [options, setOptions] = useState(initialFilters);

  const handleChange = (evt) => {
    if (evt.currentTarget.name === "search") {
      setOptions ({...options, [evt.currentTarget.name]: evt.currentTarget.value});
      onFilters(evt.currentTarget.value, options);
    } else {
      if (evt.currentTarget.value === "") {
        setOptions({...options, [evt.currentTarget.name]: ""});
      } else {
        setOptions({...options, [evt.currentTarget.name]: evt.currentTarget.value});
      }
    }
  };

  const handleClearFilters = () => {
    setOptions(initialFilters);
  };

  useEffect(() => {
    if (wordFilter && wordFilter !== null) handleChange(wordFilter);
  }, [wordFilter]);

  useEffect(() => {
    if (options && options !== null) onFilters(options.search, options);
  }, [options]);

  return (
    <div className="grid gap-3 grid-cols-12">
      <div className="grid grid-cols-12 col-span-12 lg:col-span-8">
        <div className="col-span-12">
          <div className="grid lg:grid-cols-3 gap-2">
            <select
              className="w-full text-ag-secondary-letter text-sm border border-gray-500 py-3 px-4 rounded-xl disabled:bg-zinc-200 text-center truncate"
              name="warehouse"
              value={options?.warehouse}
              onChange={handleChange}
              disabled={!enableFilters}
            >
              <option className="bg-zinc-200 font-semibold italic" value="">Warehouse</option>
              {
                warehouses.map((element, index) => {
                  return <option key={index} value={element._id}>{element.name}</option>;
                })
              }
            </select>
            <select
              className="w-full text-ag-secondary-letter border border-gray-500 text-sm py-3 px-4 rounded-xl disabled:bg-zinc-200 text-center truncate"
              name="client"
              value={options?.client}
              onChange={handleChange}
              disabled={!enableFilters}
            >
              <option className="bg-zinc-200 font-semibold italic" value="">Client</option>
              {
                clients.map((element, index) => {
                  return (
                    <option key={index} value={element._id}>
                      {element?.user?.identifier &&
                        `${element?.user?.identifier} - `
                      }
                      { element?.names}{' '}{element?.lastNames}
                    </option>
                  );
                })
              }
            </select>
            <select
              className="w-full text-ag-secondary-letter text-sm border border-gray-500 py-3 px-4 rounded-xl disabled:bg-zinc-200 text-center truncate"
              name="employee"
              value={options?.employee}
              onChange={handleChange}
              disabled={!enableFilters}
            >
              <option className="bg-zinc-200 font-semibold italic" value="">Employee</option>
              {
                employees.map((element, index) => {
                  return <option key={index} value={element?.requestedBy?._id}>{`${element.requestedBy?.names} ${element.requestedBy?.lastNames}`}</option>;
                })
              }
            </select>
            <div className="w-full flex justify-center items-center">
              <span
                className="w-[30%] md:w-[45%] lg:w-[37%] bg-ag-secondary text-xs text-white text-center border border-gray-500 py-[10px] rounded-l-xl"
              >
                Start
              </span>
              <div className="customDatePickerWidth">
                <ReactDatePicker
                  className={`w-full text-ag-secondary-letter border border-gray-500 px-2 py-[6px] rounded-r-xl disabled:bg-zinc-200`}
                  placeholderText=""
                  name="dateStart"
                  selected={options.dateStart}
                  onChange={(dateVal) => setOptions({...options, dateStart: dateVal})}
                  disabled={!enableFilters}
                />
              </div>
            </div>
            <div className="w-full flex justify-center items-center">
              <span
                className="w-[30%] md:w-[45%] lg:w-[37%] bg-ag-secondary text-xs text-white text-center border border-gray-500 py-[10px] rounded-l-xl"
              >
                End
              </span>
              <div className="customDatePickerWidth">
                <ReactDatePicker
                  className={`w-full text-ag-secondary-letter border border-gray-500 px-2 py-[6px] rounded-r-xl disabled:bg-zinc-200`}
                  placeholderText=""
                  name="dateEnd"
                  selected={options.dateEnd}
                  onChange={(dateVal) => setOptions({...options, dateEnd: dateVal})}
                  disabled={!enableFilters}
                />
              </div>
            </div>
            <div className="w-full flex items-center justify-center lg:justify-start px-3">
              <button title="Clear filters" onClick={handleClearFilters}>
                <FaWindowClose className="text-3xl text-ag-primary-light"/>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-12 col-span-12 lg:col-span-4 gap-2">
        <div className="flex justify-center items-center border border-gray-500 rounded-xl col-span-12">
          <input
            className={`w-full flex justify-center items-center space-x-3 text-ag-secondary-letter text-xs lg:text-sm px-2 py-2 rounded-xl disabled:bg-zinc-200 truncate ${ focusClass.search.status ? "text-left" : "text-center" }`}
            type="text"
            name="search"
            placeholder={focusClass.search.placeholder}
            disabled={!permits['ORDERS']['RETURNS_FILTERS']}
            onChange={(evt) => setSearchWord({ currentTarget: { name: evt.target.name, value: evt.target.value } })}
            onFocus={() => setFocusClass({...focusClass, search: { placeholder: "", status: true }})}
            onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, search: { placeholder: "Search", status: false }}) : null}
            maxLength={60}
          />
          <div
            className="border-none p-[10px] rounded-md disabled:bg-zinc-200"
          >
            <span className="text-ag-secondary-letter text-xl"><BiSearch /></span>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-6">
          <Link to={"/returns/approved"}>
            <button
              className="w-full bg-ag-primary-light text-white text-xs lg:text-sm px-2 md:px-6 lg:px-2 py-[10px] rounded-xl disabled:bg-zinc-200"
              type="button"
            >
              Return History
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ReturnFilters;