import { createContext, useContext, useEffect, useState } from "react";
import { useOrderActionsContext } from "../OrderActionsContext";
import { changeSalesItems, getSale } from "../../../../services/Sales";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import { DISCOUNT_DICTIONARY } from "../../../../utils/constants";

const initialChangeOrderContext = {
  showProductModal: false,
  setShowProductModal: () => { },
  showAccessoryModal: false,
  setShowAccessoryModal: () => { },
  globalSaleItems: [],
  loadingSaleInfo: true,
  setGlobalSaleItems: () => { },
  nonExistentProducts: false,
  updateOrderItems: () => { },
  subtotal: 0,
  discount:0,
  total: 0,
  countAvailableAccessories: () => { },
  countAvailableProducts: () => { },
  handleChangeSaleNote: () => { },
  saleNote: '',
};

const ChangeOrderContext = createContext(initialChangeOrderContext);

export const ChangeOrderContextProvider = ({ children }) => {
  const {
    sale,
    racksOptions,
    sectionsOptions,
    setSale,
    isProductItem,
    setOpen,
    handleCancelOrganization,
  } = useOrderActionsContext();
  const [showProductModal, setShowProductModal] = useState(false);
  const [showAccessoryModal, setShowAccessoryModal] = useState(false);
  const [globalSaleItems, setGlobalSaleItems] = useState([]);
  const [loadingSaleInfo, setLoadingSaleInfo] = useState(true);
  const [saleNote, setSaleNote] = useState(sale?.note);

  const nonExistentProducts = globalSaleItems?.length < 1;

  const findDiscountKey = (discountValue) => {
    for (const key in DISCOUNT_DICTIONARY) {
      if (DISCOUNT_DICTIONARY[key] === discountValue) return key;
    }
  };

  const countAvailableProducts = (productInventoryId) => {
    let totalQuantityAvailable = 0;
    racksOptions.map(rack => {
      const existProduct = rack.productsRack.find(rackAux =>
        (rackAux?.productInventory?._id === productInventoryId && rackAux?.quantity > 0)
      );
      if (existProduct) totalQuantityAvailable += existProduct?.quantity;
    });
    return totalQuantityAvailable;
  };

  const countAvailableAccessories = (accessoryInventoryId) => {
    let totalQuantityAvailable = 0;
    sectionsOptions.map(section => {
      const existAccessory = section.accessoriesSection.find(sectionAux =>
        (sectionAux?.accessoryInventory?._id === accessoryInventoryId && sectionAux?.quantity > 0)
      );
      if (existAccessory) totalQuantityAvailable += existAccessory?.quantity;
    });
    return totalQuantityAvailable;
  };

  const handleChangeSaleNote = (e) => {
    setSaleNote(e.target.value);
  };

  const getSaleInfo = async () => {
    await getSale(sale._id)
      .then(res => {
        if (res?.status === 200 && res?.data) {
          const productsSale = res?.data?.productsSale?.map(product => {
            const productObject = {
              _id: product?._id,
              year: product?.year,
              discountType: findDiscountKey(product?.discountType),
              discount: product?.discount,
              nags: product?.productInventory?.product?.nags,
              name: product?.productInventory?.product?.name,
              features: product?.productInventory?.product?.features,
              productInventory: { _id: product?.productInventory?._id },
              quantity: product?.quantity,
              cost: product?.productInventory?.cost,
              total: product?.total,
              laborCharge: product?.laborCharge ?? false,
              laborCost: product?.laborCost ?? 0,
              quantityAvailable: countAvailableProducts(product?.productInventory?._id),
              canEdit: false
            };
            return productObject;
          });

          const accessoriesSale = res?.data?.accessoriesSale?.map(accessory => {
            const accessoryObject = {
              _id: accessory?._id,
              year: accessory?.year,
              discountType: findDiscountKey(accessory?.discountType),
              discount: accessory?.discount,
              name: accessory?.accessoryInventory?.accessory?.name,
              accessoryInventory: { _id: accessory?.accessoryInventory?._id },
              quantity: accessory?.quantity,
              cost: accessory?.accessoryInventory?.cost,
              total: accessory?.total,
              laborCharge: accessory?.laborCharge ?? false,
              laborCost: accessory?.laborCost ?? 0,
              quantityAvailable: countAvailableAccessories(accessory?.accessoryInventory?._id),
              canEdit: false
            };
            return accessoryObject;
          });
          setGlobalSaleItems([...productsSale, ...accessoriesSale]);
          setSale(prev => ({...prev, allItems: [...productsSale, ...accessoriesSale]}));
        }
      })
      .catch(err => {
        console.log(err);
        toast.error("There was an error while fetching the sale products", alertOptions);
      })
      .finally(() => {
        setLoadingSaleInfo(false);
      });
  };

  const updateOrderItems = async () => {
    let accessoriesSale = [];
    let productsSale = [];
    const allowSubmit = !globalSaleItems.some(item => item.canEdit);
    if (!allowSubmit) {
      toast.info("Please finish editing the items", alertOptions);
      return;
    }
    const isEmptyLabor = globalSaleItems.some(product => product?.laborCharge && !product?.laborCost);
    if (isEmptyLabor) {
      toast.error('Please add a labor cost for all checked products', alertOptions);
      return;
    }
    globalSaleItems.forEach(product => {
      const totalWithoutDiscount = (product?.cost * product?.quantity);
      if (isProductItem(product)) {
        const productObj = {
          year: product?.year || '',
          discountType: DISCOUNT_DICTIONARY[product?.discountType] || 'NONE',
          discount: product?.discount || 0,
          productInventory: product?.productInventory,
          quantity: product?.quantity || 1,
          unitCost: product?.cost,
          laborCharge: product?.laborCharge,
          laborCost: product?.laborCost,
          total: calculateTotalWithDiscount(product?.discountType, product?.discount, totalWithoutDiscount) + product?.laborCost,
        };
        if(product?._id) productObj["_id"] = product?._id;
        productsSale.push(productObj);
      }
      else {
        const accessoryObj = {
          discountType: DISCOUNT_DICTIONARY[product?.discountType] || 'NONE',
          discount: product?.discount || 0,
          accessoryInventory: product?.accessoryInventory,
          quantity: product?.quantity || 1,
          unitCost: product?.cost,
          laborCharge: product?.laborCharge,
          laborCost: product?.laborCost,
          total: calculateTotalWithDiscount(product?.discountType, product?.discount, totalWithoutDiscount) + product?.laborCost,
        };
        if(product?._id) accessoryObj["_id"] = product?._id;
        accessoriesSale.push(accessoryObj);
      }
    });

    const body = {
      _id: sale?._id,
      productsSale,
      accessoriesSale,
      subtotal,
      discount,
      total,
      note: saleNote ?? '',
    };

    setLoadingSaleInfo(true);
    await changeSalesItems(body._id, body)
      .then(() => {
        toast.success("The items of the current order have been modified", alertOptions);
        setSale(prev => ({ ...prev, allItems: [] }));
        handleCancelOrganization();
        setOpen(false);
      })
      .catch(err => {
        toast.error(err?.response?.data?.message ,alertOptions);
      })
      .finally(() => {
        setLoadingSaleInfo(false);
      });
  };

  const calculateTotalWithDiscount = (discountType, discountAmount, totalWithoutDiscount) => {
    if (discountType === '%')
      return (totalWithoutDiscount - (totalWithoutDiscount * parseFloat(discountAmount)) / 100);
    else if (discountType === '$')
      return parseFloat(totalWithoutDiscount - discountAmount);
    return totalWithoutDiscount;
  };

  const calculateTotal = () => {
    let subtotal = 0;
    let discount = 0;
    let totalLaborCost = 0;

    globalSaleItems.forEach((product) => {
      const cost = product?.cost || 0;
      const quantity = product?.quantity || 1;
      const discountResume = parseFloat(product?.discount) || 0;
      totalLaborCost += product?.laborCost ?? 0;


      const totalCost = cost * quantity;
      subtotal += totalCost;

      if (product?.discountType === '%') {
        discount += (totalCost * discountResume) / 100;
      } else if (product?.discountType === '$') {
        discount += discountResume;
      }
    });

    const total = (subtotal - discount) + totalLaborCost;
    return { subtotal, discount, total };
  };

  const { subtotal, discount, total } = calculateTotal();

  useEffect(() => {
    if (sale._id && sale?.allItems?.length < 1) getSaleInfo();
    else if (sale._id && globalSaleItems?.length < 1) {
      setGlobalSaleItems(sale?.allItems);
      setLoadingSaleInfo(false);
    }
  }, [sale._id, sale.allItems]);


  return (
    <ChangeOrderContext.Provider
      value={{
        showProductModal,
        setShowProductModal,
        showAccessoryModal,
        setShowAccessoryModal,
        globalSaleItems,
        loadingSaleInfo,
        setGlobalSaleItems,
        nonExistentProducts,
        updateOrderItems,
        subtotal,
        discount,
        total,
        countAvailableAccessories,
        countAvailableProducts,
        handleChangeSaleNote,
        saleNote
      }}
    >
      {children}
    </ChangeOrderContext.Provider>

  );
};

export const useChangeOrderContext = () => {
  return useContext(ChangeOrderContext);
};