import { useNavigate } from "react-router-dom";
import { formatCurrency, formatDateHourOrders } from "../../../utils/Utilities";

const RowsReturns = ({ enableActions, returnElement, onDetail }) => {
  const navigate = useNavigate();
  const returnId = String(returnElement?._id).substring(String(returnElement?._id).length - 13).toLocaleUpperCase();

  const isProductOrder = Object.hasOwn(returnElement, "productOrder");
  const fullProductName = `${returnElement?.productOrder?.productInventory?.product?.nags} - ${returnElement?.productOrder?.productInventory?.product?.name}`;

  return (
    <section className="inline-block mb-4 min-w-[60rem] w-full">
      <div className="grid grid-cols-12 col-span-12 border-[0.5px] border-ag-secondary-light rounded-t-xl rounded-b-xl">

        <div className="grid grid-cols-4 col-span-12 text-white bg-ag-secondary px-4 rounded-t-lg font-bold text-sm">
          <h3 className="col-span-1">Date</h3>
          <h3 className="col-span-1">Items</h3>
          <h3 className="col-span-1">Quantity</h3>
          <h3 className="col-span-1">Total cost</h3>
        </div>

        <div className="grid grid-cols-4 col-span-12 p-4 text-ag-secondary-letter text-center text-sm">
          <div className="col-span-1 border-r border-ag-secondary-light">
            <p>{ formatDateHourOrders(returnElement?.createdAt) }</p>
            <p className="truncate">{`ID: ${returnId}`}</p>
          </div>
          <div className="col-span-1 border-r flex flex-col gap-2 items-center border-ag-secondary-light text-sm px-6">
            <p
              title={isProductOrder ? fullProductName : ''}
              className="truncate max-w-[15rem] p-4"
            >
              {isProductOrder ? fullProductName : returnElement?.accessoryOrder?.accessoryInventory?.accessory?.name}
            </p>
          </div>
          <div className="col-span-1 border-r border-ag-secondary-light flex flex-col gap-2 items-center text-sm px-6 overflow-x-hidden overflow-y-auto max-h-[8rem]">
            <p className="p-4">{returnElement?.quantity}</p>
          </div>
          <p className="col-span-1 text-center my-auto">{ formatCurrency(returnElement?.total) }</p>
        </div>

        <div className="grid grid-cols-4 col-span-12 text-white bg-ag-secondary px-4 font-bold text-sm">
          <h3 className="col-span-1">Customer</h3>
          <h3 className="col-span-1">Warehouse</h3>
          <h3 className="col-span-1">Sales employee</h3>
          <h3 className="col-span-1">Actions</h3>
        </div>

        <div className="grid grid-cols-4 col-span-12 p-4 text-ag-secondary-letter text-center rounded-b-xl text-sm">
          <p className="col-span-1 border-r border-ag-secondary-light text-left">{ returnElement?.wholesaler.names }</p>
          <p className="col-span-1 border-r border-ag-secondary-light">{ returnElement?.warehouse.name }</p>
          <p className="col-span-1 border-r border-ag-secondary-light">
            {returnElement?.requestedBy ? `${returnElement.requestedBy?.names} ${returnElement.requestedBy?.lastNames}` : "-----"}
          </p>
          <div className="flex gap-2 text-white justify-center items-center">
            <button
              className="bg-ag-secondary text-white px-3 py-1 rounded-xl disabled:bg-zinc-200 disabled:cursor-not-allowed"
              disabled={!enableActions}
              type="button"
              onClick={() => onDetail(returnElement)}
            >
              View details
            </button>
            <button
              className="bg-ag-primary px-3 py-1 rounded-xl disabled:bg-ag-primary-light disabled:cursor-not-allowed"
              onClick={() => navigate(`/returns/${returnElement._id}`)}
            >
              Start return
            </button>
          </div>
        </div>

      </div>
    </section>
  );
};

export default RowsReturns;