import { Link } from "react-router-dom";
import Loader from "../../../../components/loader/Loader";
import { ReturnProcessContextProvider, useReturnProcessContext } from "./ReturnProcessContext";
import { FiChevronLeft } from "react-icons/fi";
import ReturnProcessDetailTable from "./ReturnProcessDetailTable";
import ReturnProductForm from "./ReturnProductForm";

const ReturnProcess = () => {
  return (
    <ReturnProcessContextProvider>
      <ReturnProcessComponent/>
    </ReturnProcessContextProvider>
  );
};

const ReturnProcessComponent = () => {
  const { loader } = useReturnProcessContext();
  return (
    <>
      {loader && <Loader /> }

      <div className="px-3 my-5 md:px-8 flex items-center">
        <Link to="/returns">
          <button className="p-1 rounded-md" type="button">
            <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
          </button>
        </Link>
        <h1 className="text-2xl text-ag-secondary font-bold">
          Return details
        </h1>
      </div>
      <div className="px-3 md:px-8 grid grid-cols-12 gap-2 pb-6">
        <ReturnProcessDetailTable />
        <ReturnProductForm />
      </div>

    </>
  );
};

export default ReturnProcess;