import { toast } from "react-toastify";
import { alertOptions, formatCurrency, formatDateHourOrders } from "../../../../utils/Utilities";

const arrStatus = {
  canceled: { text: "Canceled", color: "bg-ag-order-canceled" },
  delivered: { text: "Delivered", color: "bg-ag-order-delivered" },
  inprocess: { text: "In process", color: "bg-ag-order-pending" },
  paid: { text: "Delivered", color: "bg-ag-order-paid" },
  pending: { text: "Pending", color: "bg-ag-order-inprocess" },
  attend: { text: "Attended", color: "bg-ag-order-paid" }
};

const paymentQuote = {
  PAY_ON_MY_WON: "Pay on my own",
  CAR_INSURANCE: "Pay with Insurance"
};

const typeOpts = {
  "specifications": "Order",
  "photos": "By Photo",
  "POWER_WINDOW_REPAIR": "Power Window Repair",
  "WINDOW_TINT": "Window Tint",
  "QUOTES": "Quotes",
  "PURCHASE": "Purchase"
};

const CLOVER_URL = process.env.REACT_APP_CLOVER_URL;

const HeaderDetails = ({ type, orderData, onCancel, onDelivery, onToShip }) => {
  const handleTicket = () => {
    if (orderData?.ticketClover && orderData?.ticketClover !== "" && ((orderData?.status === "paid" && orderData.orderType !== "QUOTES") || (orderData?.status === "delivered" && orderData?.orderType === "QUOTES") || (orderData?.paymentState === "PAID" && orderData.orderType !== "QUOTES"))) {
      window.open(`${CLOVER_URL}${orderData?.ticketClover}`, "_blank", "noreferrer");
    } else {
      toast.warning("There is no ticket to show", alertOptions);
    }
  };
  const isMixedPayment = orderData?.payType?.paymentMethod === "MIXED";
  const mixedPaymentText = `(CREDIT: $${orderData?.payType?.storeCreditTotal} - ${orderData?.payType?.secondMethodPayment}: $${orderData?.payType?.totalSecondMethodPayment})`;

  return (
    <div>
      <div className="grid gap-3 md:grid-cols-3">
        <div className="border border-ag-secondary-light rounded-xl">
          <div className="bg-ag-secondary-light text-white text-center p-2 rounded-xl">
            <h1 className="text-sm md:text-xs lg:text-sm">Order detail</h1>
          </div>
          <div className="p-5">
            <ul className="text-ag-secondary text-sm md:text-xs lg:text-sm">
              <li className="flex space-x-2">
                <span className="font-semibold">Customer:</span>
                <span>{ orderData.client?.names }{' '}{ orderData.client?.lastNames }</span>
              </li>
              <li className="flex space-x-2">
                <span className="font-semibold">Order ID:</span>
                <span>{String(orderData?._id).substring(String(orderData?._id).length - 13).toLocaleUpperCase()}</span>
              </li>
              <li className="flex space-x-2">
                <span className="font-semibold">Order Number:</span>
                <span>{String(orderData?._id).substring(String(orderData?._id).length - 5).toLocaleUpperCase()}</span>
              </li>
              <li className="flex space-x-2">
                <span className="font-semibold">Date:</span>
                <span>{ formatDateHourOrders(orderData.date) }</span>
              </li>
              <li className="flex space-x-2">
                <span className="font-semibold">Order Type:</span>
                <span>{ typeOpts[orderData.orderType] }</span>
              </li>
              <li className="flex space-x-2">
                <span className="font-semibold">Status:</span>
                <span>{ arrStatus[orderData.status]?.text }</span>
              </li>
              <li className="flex space-x-2">
                <span className="font-semibold">Pay type:</span>
                <div>
                  <span>
                    {paymentQuote[orderData?.payType?.paymentMethod] ?
                      paymentQuote[orderData?.payType?.paymentMethod]
                      : orderData?.orderType === "PURCHASE" && orderData?.payType?.storeCredit === true ? "CREDIT" : orderData?.payType?.paymentMethod
                    }
                  </span>
                  {isMixedPayment && <span className="text-sm md:text-xs lg:text-sm">{mixedPaymentText}</span> }
                </div>
              </li>
              {
                orderData?.orderType === "PURCHASE" ?
                  <>
                    <li className="flex space-x-2">
                      <span className="font-semibold">Enter Purchase Order {'(PO):'}</span>
                    </li>
                    <li className="flex space-x-2">
                      <p className="truncate w-52 md:w-auto" title={orderData?.po}>{ orderData?.po ? orderData?.po : "---" }</p>
                    </li>
                  </>
                  : null
              }
              <li className="flex space-x-2">
                <span className="font-semibold">Email:</span>
                <span>{ orderData.client?.user?.email }</span>
              </li>
              <li className="flex space-x-2">
                <span className="font-semibold">Phone:</span>
                <span>{ orderData.client?.phoneNumber }</span>
              </li>
              <li className="flex space-x-2">
                <span className="font-semibold">Total service cost:</span>
                <span>${orderData?.totalLaborCost}</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="border border-ag-secondary-light rounded-xl">
          <div className="bg-ag-secondary-light text-white text-center p-2 rounded-xl">
            <h1 className="text-sm md:text-xs lg:text-sm">Employee</h1>
          </div>
          <div className="flex justify-center items-center py-20">
            <div className="text-ag-secondary p-5">
              <span className="font-semibold text-sm md:text-xs lg:text-sm">
                {
                  orderData.employee ? `${orderData.employee?.names} ${orderData.employee?.lastNames}`
                    : "No employee assigned..."
                }
              </span>
            </div>
          </div>
        </div>
        {
          type === "detail" ?
            <div className="border border-ag-secondary-light rounded-xl relative">
              <div className="bg-ag-secondary-light text-white text-center p-2 rounded-xl">
                <h1 className="text-sm md:text-xs lg:text-sm">Total</h1>
              </div>
              <div className="py-20">
                <div className="space-y-3 text-ag-secondary text-sm md:text-xs lg:text-sm">
                  <div className="flex justify-center items-center space-x-3">
                    <span className="font-semibold">Order Total:</span>
                    <span>{ formatCurrency(orderData.total) }</span>
                  </div>
                  <div className="flex justify-center items-center space-x-3">
                    <span className="font-semibold">Refund Total:</span>
                    <span>{ formatCurrency(0) }</span>
                  </div>
                </div>
              </div>
              <div className="absolute bottom-[0] w-full">
                <button className="w-full bg-green-600 text-white text-xs lg:text-base p-2 rounded-xl" type="button" onClick={handleTicket}>
                  View Ticket
                </button>
              </div>
            </div>
            : type === "organize" ?
              <div className="space-y-3">
                <div>
                  <button className="w-full bg-ag-primary-light text-white text-xs lg:text-base p-2 rounded-xl" type="button" onClick={() => onCancel(true)}>
                    Cancel
                  </button>
                </div>
                <div>
                  <button className="w-full bg-green-600 text-white text-xs lg:text-base p-2 rounded-xl" type="button" onClick={handleTicket}>
                    View Ticket
                  </button>
                </div>
                <div>
                  <button className="w-full bg-ag-secondary-light text-white text-xs lg:text-base p-2 rounded-xl" type="button" onClick={() => onToShip(true)}>
                    Ready to dispatch
                  </button>
                </div>
              </div>
              :
              <div className="space-y-3">
                {
                  orderData?.orderType !== "WINDOW_TINT" && orderData?.orderType !== "POWER_WINDOW_REPAIR" ?
                    <div>
                      <button className="w-full bg-ag-primary-light text-white text-xs lg:text-base p-2 rounded-xl" type="button" onClick={() => onCancel(true)}>
                        Back to previous status
                      </button>
                    </div>
                    : null
                }
                <div>
                  <button className="w-full bg-green-600 text-white text-xs lg:text-base p-2 rounded-xl" type="button" onClick={handleTicket}>
                    View Ticket
                  </button>
                </div>
                <div>
                  <button className="w-full bg-ag-secondary-light text-white text-xs lg:text-base p-2 rounded-xl" type="button" onClick={() => onDelivery(true)}>
                    Ready to delivery
                  </button>
                </div>
              </div>
        }
      </div>
    </div>
  );
};

export default HeaderDetails;