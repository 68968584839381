import { useOrderActionsContext } from '../OrderActionsContext';
import { useChangeOrderContext } from './ChangeOrderContext';
import { useState } from 'react';

export const useItemFormUtils = () => {
  const { isProductItem } = useOrderActionsContext();
  const {
    globalSaleItems,
    setShowAccessoryModal,
    setShowProductModal,
    setGlobalSaleItems,
    countAvailableAccessories,
    countAvailableProducts
  } = useChangeOrderContext();
  const [error, setError] = useState("");

  const addNewProductToOrder = (isBulkOrder, selectedItem, quantity) => {
    const qty = isBulkOrder ? parseInt(quantity) : 1;

    const productToAdd = {
      quantity: qty,
      name: selectedItem?.name,
      cost: selectedItem?.cost,
      laborCharge: false,
      laborCost: 0,
    };

    if (isProductItem(selectedItem)) {
      productToAdd["nags"] = selectedItem?.nags;
      productToAdd["productInventory"] = selectedItem?.productInventory;
      productToAdd["quantityAvailable"] = countAvailableProducts(selectedItem?.productInventory?._id);
      productToAdd["features"] = selectedItem?.features;
    }
    else {
      productToAdd["accessoryInventory"] = selectedItem?.accessoryInventory;
      productToAdd["quantityAvailable"] = countAvailableAccessories(selectedItem?.accessoryInventory?._id);
    }

    if (selectedItem) {
      setGlobalSaleItems((prev) => [...prev, productToAdd]);
    }

    setShowProductModal(false);
    setShowAccessoryModal(false);
  };

  const handleDiscountTypeChange = (currentIndex, value) => {
    setGlobalSaleItems((prev) =>
      prev.map((product, index) => {
        if (index === currentIndex) {
          return { ...product, newDiscountType: value };
        }
        return product;
      })
    );
  };

  const handleAmountChange = (currentIndex, value) => {
    setGlobalSaleItems(globalSaleItems.map((product, index) =>
      index === currentIndex ? { ...product, amount: value } : product
    ));
  };

  const handleRemoveProduct = (index = 0) => {
    setGlobalSaleItems((prev) =>
      prev.filter((_product, indexProduct) => index !== indexProduct)
    );
  };

  const resetItemValues = (currentIndex) => {
    setGlobalSaleItems(globalSaleItems.map((item, index) => {
      if (index === currentIndex) {
        const {
          newQuantity,
          amount,
          newDiscountType,
          newLaborChecked,
          newLaborCost,
          ...newItem
        } = item;
        return {...newItem, canEdit: false};
      }
      return item;
    }));
  };

  const updateItem = (currentIndex, canEdit) => {
    if (canEdit) {
      setGlobalSaleItems(globalSaleItems.map((item, index) => index === currentIndex ? { ...item, canEdit: true } : item));
      return;
    }
    setGlobalSaleItems(globalSaleItems.map((item, index) => {
      if (index === currentIndex) {
        const {
          newQuantity,
          amount,
          newDiscountType,
          newLaborChecked,
          newLaborCost,
          ...newItem
        } = item;

        const newObject = {
          ...newItem,
          discountType: newDiscountType ?? newItem?.discountType,
          discount: amount ?? (newDiscountType === '' ? 0 : newItem?.discount),
          quantity: (newQuantity && newQuantity <= item?.quantityAvailable) ? newQuantity : (newItem?.quantity ?? 0),
          canEdit: false,
          laborCharge: newLaborChecked ?? newItem?.laborCharge,
          laborCost: newLaborCost ?? newItem?.laborCost ,
        };
        return newObject;
      }
      return item;
    }));
  };

  const handleChangeQuantity = (event, currentIndex, quantityAvailable) => {
    const newQuantity = parseInt(event.target.value, 10) || 0;

    if (newQuantity > quantityAvailable) setError("Quantity not available");
    else setError("");

    if (parseInt(newQuantity) > 0) {
      setGlobalSaleItems((prev) =>
        prev.map((product, index) =>
          (index === currentIndex)
            ? { ...product, newQuantity }
            : product
        )
      );
    }
  };

  const handleLaborCheck = (checked, currentIndex) => {
    setGlobalSaleItems((prev) =>
      prev.map((product, indexProduct) => {
        if (indexProduct === currentIndex) {
          return { ...product, newLaborChecked: checked, newLaborCost: checked ? (product?.newLaborCost ?? product?.laborCost) : 0 };
        }
        return product;
      })
    );
  };

  const handleLaborCost = (cost, currentIndex) => {
    const newLaborCost = parseInt(cost, 10) || 0;

    if (parseInt(newLaborCost) >= 0) {
      setGlobalSaleItems((prev) =>
        prev.map((product, indexProduct) => {
          if (indexProduct === currentIndex) {
            return { ...product, newLaborCost};
          }
          return product;
        })
      );
    }
  };

  return {
    addNewProductToOrder,
    handleDiscountTypeChange,
    handleAmountChange,
    handleRemoveProduct,
    resetItemValues,
    updateItem,
    handleChangeQuantity,
    error,
    handleLaborCheck,
    handleLaborCost
  };
};
