import { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { getMovementHistoryInventoryAccessory } from "../../../services/InventoryAccessory";
import { formatDate, formatCurrency, formatDateWordEng } from "../../../utils/Utilities";

const MovementHistoryInventoryAccessory = ({ id, onClose }) => {
  const [activeTab, setActiveTab] = useState('generalInformation');
  const [showMessageModification, setShowMessageModification] = useState(false);
  const [accessoryOrders, setAccessoryOrders] = useState([]);
  const [movementHistoryGeneralInformation, setMovementHistoryGeneralInformation] = useState({
    added: "",
    warehouse: "",
    maxStock: "",
    minStock: "",
    quantity: "",
    by: "",
    sections: []
  });

  const [movementHistoryModification, setMovementHistoryModification] = useState({
    modificationDate: "",
    quantityAdded: "",
    newMaxStock: "",
    quantityRemoved: "",
    newMinStock: "",
    newSalesPrice: "",
    modificationBy: "",
    modifiedSections: []
  });

  const getStatusStyles = (status) => {
    switch (status) {
    case 'paid':
      return 'bg-green-100 text-green-800';
    case 'attend':
      return 'bg-blue-100 text-blue-800';
    case 'inprocess':
      return 'bg-amber-100 text-amber-800';
    default:
      return 'bg-gray-100 text-gray-800';
    }
  };


  useEffect(() => {
    getMovementHistoryInventoryAccessory(id).then(res => {
      if (res.status === 200) {
        setMovementHistoryGeneralInformation({
          added: res.data.generalInformation.createdAt,
          warehouse: res.data.generalInformation.Warehouse.name,
          maxStock: res.data.generalInformation.maxStock,
          minStock: res.data.generalInformation.minStock,
          quantity: res.data.generalInformation.quantity,
          by: res.data.generalInformation.by.names + ' ' + res.data.generalInformation.by.lastNames,
          sections: res.data.generalInformation.sections
        });
        setAccessoryOrders(res?.data?.orders);
        if (res.data.accessory !== null) {
          setMovementHistoryModification({
            modificationDate: res.data.accessory.updatedAt,
            quantityAdded: res.data.accessory.totalQuantityAdded,
            newMaxStock: res.data.accessory.maxStock,
            quantityRemoved: res.data.accessory.totalQuantityRemoved,
            newMinStock: res.data.accessory.minStock,
            newSalesPrice: res.data.accessory.cost,
            modificationBy: res.data.accessory.lastUpdateBy.names + ' ' + res.data.accessory.lastUpdateBy.lastNames,
            modifiedSections: res.data.accessory.modifiedSections,
          });
          setShowMessageModification(false);
        } else {
          setShowMessageModification(true);
        }

      }
    });
  }, []);


  return (
    <>

      <div className="block">
        <div className="justify-center items-center flex fixed inset-0 z-40 outline-none focus:outline-none">
          <div className="min-w-[320px] md:min-w-[750px] lg:min-w-[850px]">
            <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none h-[500px] overflow-x-auto overflow-y-auto">
              <div className="w-full flex items-center justify-center rounded text-center">
                <div className="w-[95%]">
                  <span className="text-xl text-ag-secondary font-bold">
                                        Movement History
                  </span>
                </div>
                <div className="w-[5%]">
                  <button
                    className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                    onClick={() => onClose(false)}
                  >
                    <IoMdCloseCircle />
                  </button>
                </div>
              </div>
              <div className="px-2 md:px-5 pb-0 pt-5 min-w-full">
                <div className="flex items-center min-w-[37rem] truncate">
                  <button onClick={() => setActiveTab('generalInformation')} className={`text-sm md:text-lg w-[25%] ${activeTab === 'generalInformation' ? "bg-[#D9D9D9] text-[#787879]" : "bg-[#FF5F52] text-[#FFF]"}`}>General information</button>
                  <button onClick={() => setActiveTab('transfers')} className={`text-sm md:text-lg w-[25%] ${activeTab === 'transfers' ? "bg-[#D9D9D9] text-[#787879]" : "bg-[#FF5F52] text-[#FFF]"}`}>Transfers</button>
                  <button onClick={() => setActiveTab('productModification')} className={`text-sm md:text-lg w-[25%] ${activeTab === 'productModification' ? "bg-[#D9D9D9] text-[#787879]" : "bg-[#FF5F52] text-[#FFF]"}`}>Accessory modification</button>
                  <button onClick={() => setActiveTab('orders')} className={`text-sm md:text-lg w-[25%] ${activeTab === 'orders' ? "bg-[#D9D9D9] text-[#787879]" : "bg-[#FF5F52] text-[#FFF]"}`}>Orders</button>
                </div>
                {
                  activeTab === 'generalInformation' ?
                    <div className="mt-10">
                      <div className="flex justify-between w-[100%] mb-8">
                        <div className="w-[50%]">
                          <p className='text-sm md:text-base text-[#000] font-bold'>Date added: {formatDate(movementHistoryGeneralInformation.added)}</p>
                        </div>
                        <div className="w-[50%]">
                          <p className='text-sm md:text-base text-[#000] font-bold'>Warehouse: {movementHistoryGeneralInformation.warehouse}</p>
                        </div>
                      </div>
                      <div className="flex justify-between w-[100%] mb-8">
                        <div className="w-[50%]">
                          <p className='text-sm md:text-base text-[#000] font-bold'>Maximum stock:  {movementHistoryGeneralInformation.maxStock}</p>
                        </div>
                        <div className="w-[50%]">
                          <p className='text-sm md:text-base text-[#000] font-bold'>Sections:
                            {
                              movementHistoryGeneralInformation.sections.length === 0 ?
                                <p>---</p>
                                :
                                movementHistoryGeneralInformation.sections.map((item, index) => (
                                  <span key={index}>{' ' + item.rack}{index < movementHistoryGeneralInformation.sections.length - 1 && ', '}</span>
                                ))
                            }
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between w-[100%] mb-8">
                        <div className="w-[50%]">
                          <p className='text-sm md:text-base text-[#000] font-bold'>Minimum stock: {movementHistoryGeneralInformation.minStock}</p>
                        </div>
                        <div className="w-[50%]">
                          <p className='text-sm md:text-base text-[#000] font-bold'></p>
                        </div>
                      </div>
                      <div className="flex justify-between w-[100%] mb-8">
                        <div className="w-[50%]">
                          <p className='text-sm md:text-base text-[#000] font-bold'>Quantity added: {movementHistoryGeneralInformation.quantity}</p>
                        </div>
                        <div className="w-[50%]">
                          <p className='text-sm md:text-base text-[#000] font-bold'>Modified by: {movementHistoryGeneralInformation.by}</p>
                        </div>
                      </div>
                    </div>
                    : activeTab === 'transfers' ?
                      <div className="mt-10">
                        <p className='text-sm md:text-base text-[#000] font-bold italic'>Nothing to show.</p>
                      </div>
                      : activeTab === 'productModification' ?
                        (
                          showMessageModification === true ? (
                            <div className="mt-10">
                              <p className='text-sm md:text-base text-[#000] font-bold italic'>Nothing to show.</p>
                            </div>
                          ) : (
                            <div className="mt-10">
                              <div className="mt-6">
                                <div className="flex justify-between w-[100%] mb-8">
                                  <div className="w-[50%]">
                                    <p className='text-sm md:text-base text-[#000] font-bold'>Modification date: {movementHistoryModification.modificationDate ? formatDate(movementHistoryModification.modificationDate) : ''}</p>
                                  </div>
                                  <div className="w-[50%]">
                                    <p className='text-sm md:text-base text-[#000] font-bold'>Quantity added: {movementHistoryModification.quantityAdded}</p>
                                  </div>
                                </div>
                                <div className="flex justify-between w-[100%] mb-8">
                                  <div className="w-[50%]">
                                    <p className='text-sm md:text-base text-[#000] font-bold'>New maximum stock:  {movementHistoryModification.newMaxStock}</p>
                                  </div>
                                  <div className="w-[50%]">
                                    <p className='text-sm md:text-base text-[#000] font-bold'>Quantity removed: {Math.abs(movementHistoryModification.quantityRemoved)}</p>
                                  </div>
                                </div>
                                <div className="flex justify-between w-[100%] mb-8">
                                  <div className="w-[50%]">
                                    <p className='text-sm md:text-base text-[#000] font-bold'>New minimum stock: {movementHistoryModification.newMinStock}</p>
                                  </div>
                                  <div className="w-[50%]">
                                    <p className='text-sm md:text-base text-[#000] font-bold'>Modified sections:
                                      {
                                        movementHistoryModification.modifiedSections.length === 0 ?
                                          <p>---</p>
                                          :
                                          movementHistoryModification.modifiedSections.map((item, index) => (
                                            <span key={index}>{' ' + item.rack}{index < movementHistoryModification.modifiedSections.length - 1 && ', '}</span>
                                          ))
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className="flex justify-between w-[100%] mb-8">
                                  <div className="w-[50%]">
                                    <p className='text-sm md:text-base text-[#000] font-bold'>New sales price: {formatCurrency(movementHistoryModification.newSalesPrice)}</p>
                                  </div>
                                  <div className="w-[50%]">
                                    <p className='text-sm md:text-base text-[#000] font-bold'>Modified by: {movementHistoryModification.modificationBy}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )
                        : activeTab === 'orders' && (
                          <div className="min-w-full space-y-2">
                            { accessoryOrders.length > 0 ? accessoryOrders.map((order) => (
                              <div key={order?._id} className="min-w-[37rem] p-3 rounded-md ">
                                <div key={order?._id} className="group rounded-lg border bg-card p-4 transition-colors hover:bg-muted/50">
                                  <div className="flex items-start justify-between">
                                    <div className="space-y-3">
                                      <div className="space-y-1">
                                        <div className="flex items-center gap-2">
                                          <h3 className="font-bold">Order ID: {order?._id}</h3>
                                          <span className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-bold capitalize
                                                 ${getStatusStyles(order?.status === "delivered" && order?.paymentState === "PAID" ? "paid" : order.status)}`}>
                                            {order?.status === "delivered" && order?.paymentState === "PAID" ? "Paid" : order?.status}
                                          </span>
                                        </div>
                                        <p className="text-sm text-muted-foreground">
                                            Attended by: {`${order?.employee?.names} ${order?.employee?.lastNames}`}
                                        </p>
                                      </div>
                                      <div className="flex flex-wrap gap-x-8 gap-y-2 text-sm">
                                        <div>
                                          <p className="text-muted-foreground">Date</p>
                                          <p className="font-bold">{formatDateWordEng(order.createdAt)}</p>
                                        </div>
                                        <div>
                                          <p className="text-muted-foreground">Total</p>
                                          <p className="font-bold">{formatCurrency(order.total)}</p>
                                        </div>
                                        <div>
                                          <p className="text-muted-foreground">Quantity</p>
                                          <p className="font-bold">{order.accessoriesOrder.quantity}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-3 text-xs text-muted-foreground">
                                    <p>Clover ID: {order?.orderIdClover}</p>
                                  </div>
                                </div>
                              </div>
                            ))
                              : (
                                <div className="mt-10">
                                  <p className='text-sm md:text-base text-[#000] font-bold italic'>Nothing to show.</p>
                                </div>
                              )
                            }
                          </div>
                        )

                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>
    </>
  );
};

export default MovementHistoryInventoryAccessory;