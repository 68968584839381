import { useContext, useEffect, useState } from "react";
import { deleteWholesalerUser, getWholesalerUsers, saveWholesalerUser, updateWholesalerUser } from "../../../../services/Wholesaler";
import { alertOptions, removeAccents } from "../../../../utils/Utilities";
import { PermitsContext } from "../../../../services/Permits";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "../../../../components/modal/Modal";
import Filters from "./Filters";
import FormUser from "../FormUser";
import TableUserWholesaler from "./TableUserWholesaler";
import Loader from "../../../../components/loader/Loader";

const Wholesaler = () => {
  const { dataSession, permits } = useContext(PermitsContext);
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showModal, setShowModal] = useState({ status: false, element: null });

  const [userData, setUserData] = useState(null);
  const [typeOptions] = useState([{ label: "Wholeseller", value: "WHOLESALER_USER" }]);

  const [users, setUsers] = useState([]);
  const [usersUniv, setUsersUniv] = useState([]);

  const handleFilters = (value, option) => {
    if (option === "filters") {
      let palabra = new RegExp(`${removeAccents(value)}.*`, "i");
      const usersFound = usersUniv.filter(element => (
        palabra.test(`${removeAccents(element.names)} ${removeAccents(element.lastNames)}`) ||
                palabra.test(removeAccents(element.user.email)) ||
                palabra.test(element.phoneNumber)
      ));
      setUsers(usersFound);
    } else {
      let arrFound = usersUniv;
      if (value.type === "" && value.phoneNumber === "") {
        setUsers(usersUniv);
      } else {
        for (const key in value) {
          if (Object.hasOwnProperty.call(value, key) && value[key] !== "") {
            arrFound = arrFound.filter(element => (
              element[key] === value[key] || element.user.userType === value[key]
            ));
          }
        }
        setUsers(arrFound);
      }
    }
  };

  const getData = () => {
    getWholesalerUsers().then(resGet => {
      if (resGet.status === 200 && resGet.data.length > 0) {
        const wholesalerUsersFound = resGet.data.filter(wsuElement =>
          (!wsuElement.approve && !wsuElement.deleted && (wsuElement.names !== "Guest" && wsuElement?.lastNames !== "User" && wsuElement?.workshopName !== "Avondale Auto Glass"))
        );

        const sortUser = wholesalerUsersFound.sort(function(a, b) {
          return a?.user?.identifier - b?.user?.identifier;
        });

        setUsers(sortUser);
        setUsersUniv(sortUser);
      }
      setLoader(false);
    });
  };

  const addUser = (userObject) => {
    setLoader(true);
    saveWholesalerUser(userObject).then(res => {
      if (res.status === 200) {
        getData();
        setShowForm(false);
        toast.success("User added successfully", alertOptions);
      } else {
        setLoader(false);
        toast.warning(res.response.data.message, alertOptions);
      }
    }).catch(error => {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    });
  };

  const editUser = (id, userObject) => {
    setLoader(true);
    updateWholesalerUser(id, userObject).then((res) => {
      if (res.status === 200) {
        getData();
        setShowForm(false);
        toast.success("User updated successfully", alertOptions);
      } else {
        setLoader(false);
        toast.warning(res.response.data.message, alertOptions);
      }
    }).catch(error => {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    });
  };

  const removeUser = (sts) => {
    if (sts) {
      setLoader(true);
      deleteWholesalerUser(showModal.element._id).then((res) => {
        if (res.status === 200) {
          getData();
          toast.success("User deleted successfully", alertOptions);
        } else {
          setLoader(false);
          toast.warning(res.response.data.message, alertOptions);
        }
      }).catch(error => {
        setLoader(false);
        toast.warning(error.response.data.message, alertOptions);
      });
    }
    setShowModal({...showModal, status: false, element: null});
  };

  useEffect(() => {
    if (dataSession.userType === "ADMIN" || permits['USERS']['ADD_WOLESALER']) {
      setLoader(true);
      getData();
    } else{
      navigate("/users");
    }
  }, []);

  return (
    <>
      <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
        <div className="text-center">
          <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary">Wholesalers</h1>
        </div>
        <div className="pt-3">
          <Filters
            enableFilters={permits['USERS']['FILTER']}
            enableAddRetail={permits['USERS']['ADD_RETAILER']}
            enableAddWholesaler={permits['USERS']['ADD_WOLESALER']}
            typeOptions={typeOptions}
            onFilters={handleFilters}
            onAdd={() => {
              setShowForm(true);
            }}
          />
        </div>
        <div>
          <TableUserWholesaler
            enableApprove={permits['USERS']['APPROVE_DISPPROVE']}
            arrUsers={users}
            onDelete={(userElement) => {
              setShowModal({...showModal, status: true, element: userElement});
            }}
            onEdit={(userInfo) => {
              setUserData(userInfo);
              setShowForm(true);
            }}
            onStatus={(userInfo, sts) => {
              let newUserModel = {
                _id: userInfo._id,
                names: userInfo.names,
                lastNames: userInfo.lastNames,
                email: userInfo.user.email,
                phoneNumber: userInfo.phoneNumber,
                zipCode: userInfo.zipCode,
                workshopName: userInfo.workshopName,
                invoice: userInfo.invoice,
                invoiceFile: userInfo.invoiceFile,
                approve: sts
              };
              editUser(userInfo._id, newUserModel);
            }}
          />
        </div>
      </div>

      { loader ? <Loader /> : null }

      { showModal.status ?
        <Modal
          title="¿Are you sure you want to delete the user?"
          onClose={() => {setShowModal({...showModal, status: false, element: null}); }}
          onResponse={removeUser}
        />
        :
        null
      }

      { showForm ?
        <FormUser
          enableAddRetail={permits['USERS']['ADD_RETAILER']}
          enableAddWholesaler={permits['USERS']['ADD_WOLESALER']}
          userType="wholesaler"
          userData={userData}
          onSave={addUser}
          onEdit={editUser}
          onClose={() => {
            setShowForm(false);
            setUserData(null);
          }}
        />
        :
        null
      }
    </>
  );
};

export default Wholesaler;