import { AiFillCloseCircle } from "react-icons/ai";
import FileUpload from "../../../../components/FileUpload";
import { useReturnProcessContext } from "./ReturnProcessContext";

const ReturnAdditionalInfo = () => {
  const {
    handleChangeDenyReason,
    returnedOrder,
    handleTicket,
    handleFileChange,
    imageUpload,
    deleteArrImage,
    fields,
    itemDiscount
  } = useReturnProcessContext();

  return (
    <>
      <section className="grid grid-cols-1 gap-4 px-2 border-b border-ag-secondary-light pb-6 min-w-max pt-4">
        <h2 className="font-bold text-xl text-ag-secondary-letter">Note</h2>
        <textarea value={fields?.note} className="w-full min-h-[10rem] border border-ag-secondary-light" maxLength={2500} onChange={handleChangeDenyReason}/>
      </section>

      {returnedOrder?.status && returnedOrder?.status === "APPROVED" &&
        (
          <>
            <section className="grid grid-cols-1 gap-4 px-2 border-b border-ag-secondary-light pb-6 pt-4 w-full">
              <h2 className="font-bold text-xl text-ag-secondary-letter">Upload photos (optional)</h2>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-6 gap-2 sm:gap-3 border border-ag-secondary-light w-full">
                {imageUpload?.map((image, index) => (
                  <div key={index} className="w-[70px] flex justify-center">
                    <div className="w-full p-1 m-auto">
                      {image.file ? (
                        <>
                          <div className="w-full h-full flex justify-center items-center cursor-pointer">
                            <img
                              className="min-h-[50px] sm:min-h-[70px] max-h-[70px] object-cover"
                              src={URL.createObjectURL(image.file)}
                              alt={image.name || "Uploaded image"}
                            />
                          </div>

                          <div className="flex pt-2">
                            <span className="w-full text-left text-[10px] sm:text-xs text-ag-secondary truncate">
                              {image.name}
                            </span>

                            <button
                              className="text-red-500 text-xs sm:text-sm"
                              type="button"
                              onClick={() => deleteArrImage(index)}
                            >
                              <AiFillCloseCircle />
                            </button>
                          </div>
                        </>
                      ) : (
                        <FileUpload
                          key={index}
                          index={index}
                          handleFileChange={handleFileChange}
                          accept=".png, .jpg, .jpeg"
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </section>

            <section className="grid grid-cols-1 gap-4 px-2 border-b border-ag-secondary-light pb-6 min-w-max pt-4">
              <h2 className="font-bold text-xl text-ag-secondary-letter">Credits returned</h2>
              <div className="flex flex-col gap-4 sm:flex-row justify-between items-center">
                <input
                  type="text"
                  className="border border-ag-secondary-light rounded-lg text-ag-secondary-letter font-bold px-2"
                  value={`$${itemDiscount}`}
                  disabled
                />
                <button
                  className="bg-ag-active text-white py-1 px-4 rounded-lg"
                  onClick={handleTicket}
                >
                 View ticket
                </button>
              </div>
            </section>
          </>
        )
      }
    </>
  );
};

export default ReturnAdditionalInfo;