import { useEffect, useState } from "react";
import Pagination from "../../../../../components/pagination/Pagination";
import RowsOrganize from "./RowsOrganize";

const TableOrganize = ({ items, onOrganize, onCompatible, onFeatures, order }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(5);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = items.slice(indexOfFirstRecord, indexOfLastRecord);

  useEffect(() => {
    setCurrentPage(1);
  }, [items]);

  return (
    <div>
      <table className="w-full text-center text-ag-secondary">
        <thead>
          <tr className="bg-ag-secondary-table">
            <th className="w-[40%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-[100px] md:px-20 lg:px-2 py-4">Product</th>
            <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-6 md:px-8 lg:px-2 py-4">Features</th>
            <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-6 md:px-8 lg:px-2 py-4">Quantity</th>
            <th className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-14 md:px-12 lg:px-2 py-4">Outlet rack</th>
            <th className="w-[20%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-14 md:px-16 lg:px-2 py-4">Notes</th>
            <th className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal px-14 md:px-16 py-4">Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            currentRecords.map((element, i) => {
              return (
                <RowsOrganize
                  key={i}
                  item={element}
                  onOrganize={onOrganize}
                  onCompatible={onCompatible}
                  onFeatures={onFeatures}
                  order={order}
                />
              );
            })
          }
        </tbody>
      </table>
      <div className="p-5 m-auto">
        <Pagination
          currentPage={currentPage}
          totalCount={items.length}
          pageSize={recordsPerPage}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>
    </div>
  );
};

export default TableOrganize;