import { MdDelete, MdEdit } from "react-icons/md";
import Features from "../../../components/features/Features";

const RowsWarehouse = ({ indexWarehouse, enableEditR, rack, onEditRack, onEditProduct, onDeleteProduct, onCompatible }) => {
  return (
    <>
      <tr>
        <td
          className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal p-2"
          rowSpan={rack.productsRack.length}
        >
          { indexWarehouse+1 }
        </td>
        <td
          className="w-[20%] border-[0.5px] border-ag-secondary-light text-[#000E90] text-xs lg:text-base font-normal p-2"
          rowSpan={rack.productsRack.length}
        >
          <p className="truncate w-44 m-auto cursor-pointer" onClick={() => onEditRack(rack)}>{ rack.name }</p>
        </td>
        <td className="w-[40%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] lg:text-xs font-normal p-2">
          {
            rack.productsRack.length > 0 ?
              <>
                {
                  rack?.productsRack[0]?.productInventory?.product?.compatible_vehicles.map((prodComp, i) => {
                    if (i < 1) {
                      return (
                        <p className="truncate w-80 m-auto" key={i} title={
                          `${prodComp?.nag} ` +
                          `${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year} ` +
                          `${prodComp?.brand} ` +
                          `${prodComp?.model} ` +
                          `${prodComp?.type_car}`
                        }>
                          { prodComp?.nag }{' - '}
                          { String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year }{' '}
                          { prodComp?.brand }{' '}
                          { prodComp?.model }{' '}
                          { prodComp?.type_car }
                        </p>
                      );
                    }
                  })
                }
                {
                  <div>
                    <Features element={rack?.productsRack[0]?.productInventory?.product} color={'#7a7a7a'} textSize={'12px'} seeTitle={true} />
                  </div>
                }
                {
                  rack?.productsRack[0]?.productInventory?.product?.compatible_vehicles.length > 1 ?
                    <p className="truncate w-80 font-semibold m-auto cursor-pointer" onClick={() => onCompatible(rack?.productsRack[0]?.productInventory?.product)}>
                      See compatible vehicles +
                    </p>
                    : null
                }
              </>
              : <p className="truncate w-80 m-auto">Without products...</p>
          }
        </td>
        <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] lg:text-xs font-normal p-2">
          { rack.productsRack.length > 0 ? rack.productsRack[0].quantity : "N/A" }
        </td>
        <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal p-2">
          {
            rack.productsRack.length > 0 ?
              <button className="text-xl lg:text-3xl disabled:bg-zinc-200" type="button" disabled={!enableEditR} onClick={() => onEditProduct(rack.productsRack[0])}>
                <MdEdit />
              </button>
              : "-----"
          }
        </td>
        <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-xs font-normal p-2">
          {
            rack.productsRack.length > 0 ?
              <button className="text-xl lg:text-3xl disabled:bg-zinc-200" type="button" disabled={!enableEditR} onClick={() => onDeleteProduct(rack._id, rack.productsRack[0]._id) }>
                <MdDelete />
              </button>
              : "-----"
          }
        </td>
      </tr>
      {
        rack?.productsRack?.map((rackElement, i) => {
          if (i > 0) {
            return (
              <tr key={i}>
                <td className="w-[40%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] lg:text-xs font-normal p-2">
                  {
                    rackElement?.productInventory?.product?.compatible_vehicles.map((prodComp, i) => {
                      if (i < 1) {
                        return (
                          <p className="truncate w-80 m-auto" key={i} title={
                            `${prodComp?.nag} ` +
                            `${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year} ` +
                            `${prodComp?.brand} ` +
                            `${prodComp?.model} ` +
                            `${prodComp?.type_car}`
                          }>
                            { prodComp?.nag }{' - '}
                            { String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year }{' '}
                            { prodComp?.brand }{' '}
                            { prodComp?.model }{' '}
                            { prodComp?.type_car }
                          </p>
                        );
                      }
                    })
                  }
                  {
                    <div>
                      <Features element={rackElement?.productInventory?.product} color={'#7a7a7a'} textSize={'12px'} seeTitle={true} />
                    </div>
                  }
                  {
                    rackElement?.productInventory?.product?.compatible_vehicles.length > 1 ?
                      <p className="truncate w-80 font-semibold m-auto cursor-pointer" onClick={() => onCompatible(rackElement?.productInventory?.product)}>
                        See compatible vehicles +
                      </p>
                      : null
                  }
                </td>
                <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] lg:text-xs font-normal p-2">
                  { rackElement.quantity }
                </td>
                <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] lg:text-xs font-normal p-2">
                  <button className="text-xl lg:text-3xl disabled:bg-zinc-200" type="button" disabled={!enableEditR} onClick={() => onEditProduct(rackElement)}>
                    <MdEdit />
                  </button>
                </td>
                <td className="w-[10%] border-[0.5px] border-ag-secondary-light text-ag-secondary-letter text-[10px] lg:text-xs font-normal p-2">
                  <button className="text-xl lg:text-3xl disabled:bg-zinc-200" type="button" disabled={!enableEditR} onClick={() => onDeleteProduct(rack._id, rackElement._id) }>
                    <MdDelete />
                  </button>
                </td>
              </tr>
            );
          }
        })
      }
    </>
  );
};

export default RowsWarehouse;