import ProductAssignmentRow from "./ProductAssignmentRow";

const ProductAssignmentTable = () => {
  return (
    <table className="col-span-12 w-full">
      <tbody>
        <ProductAssignmentRow/>
      </tbody>
    </table>
  );
};

export default ProductAssignmentTable;