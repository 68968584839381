import { FiChevronLeft } from "react-icons/fi";
import { OrderActionsContextProvider, useOrderActionsContext } from "./OrderActionsContext";
import { Link } from "react-router-dom";
import Loader from "../../../components/loader/Loader";
import OrderActionsHeader from "./OrderActionsHeader";
import TableOrderActions from "./TableOrderActions";
import OrganizeOrderFooterButtons from "./organize/OrganizeOrderFooterButtons";
import OrganizeOrders from "./organize/purchases/OrganizeOrders";
import DeliveryOrders from "./delivery/DeliveryOrders";

const OrderActions = () => {
  return (
    <OrderActionsContextProvider >
      <OrderActionsComponent/>
    </OrderActionsContextProvider>
  );
};

const OrderActionsComponent = () => {
  const { loader, isAttendStatus, isPurchase } = useOrderActionsContext();

  //HANDLE WHOLESALER E-COMMERCE ORGANIZE AND DELIVER AS BEFORE
  if (isPurchase && isAttendStatus) return <OrganizeOrders />;
  if (isPurchase && !isAttendStatus) return <DeliveryOrders/>;

  return (
    <>
      { loader && <Loader /> }

      <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
        <div className="grid grid-cols-12 px-0 md:px-5">
          <div>
            <Link to="/orders">
              <button className="p-1 rounded-md" type="button">
                <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
              </button>
            </Link>
          </div>
          <div className="flex justify-center items-center col-span-11 text-center">
            <h1 className="text-[26px] md:text-xl lg:text-[26px] font-light text-ag-secondary md:mr-[8%]">
              Order details
            </h1>
          </div>
        </div>
        <OrderActionsHeader />
        <div className="overflow-x-auto">
          <TableOrderActions/>
        </div>
        { isAttendStatus && <OrganizeOrderFooterButtons /> }
      </div>
    </>
  );
};

export default OrderActions;
