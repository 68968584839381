import { useReturnProcessContext } from "../ReturnProcessContext";

const ProductInformationRow = () => {
  const {
    isProductOrder,
    returnedOrder,
    handleCancel,
    handleAccept,
    fields,
    handleChangeRefundQuantity
  } = useReturnProcessContext();

  return (
    <tr>
      <td className="py-4 max-w-[12rem]">
        {isProductOrder ?
          returnedOrder?.productOrder?.productInventory?.product?.name
          : returnedOrder?.accessoryOrder?.accessoryInventory?.accessory?.name}
      </td>
      <td className="py-4 max-w-[9rem] text-center">
        {isProductOrder && returnedOrder?.productOrder?.productInventory?.product?.nags}
      </td>
      <td className="py-4 max-w-[12rem] text-center">
        {isProductOrder && returnedOrder?.productOrder?.productInventory?.product?.features}
      </td>
      <td className="py-4 max-w-[10rem] text-center">
        <input
          className="text-ag-secondary-letter text-sm border border-gray-500 px-4 rounded-xl max-w-[5rem] disabled:cursor-not-allowed"
          type="text"
          onChange={handleChangeRefundQuantity}
          value={fields?.quantity}
        />
      </td>
      <td className="py-4">
        <div className="flex gap-2 justify-end items-center">
          <button
            className="bg-ag-primary text-white text-sm min-w-[7rem] rounded-xl"
            onClick={handleCancel}
          >
            Deny refund
          </button>
          <button
            className="bg-ag-active text-white text-sm min-w-[7rem] rounded-xl"
            onClick={handleAccept}
          >
            Accept return
          </button>
        </div>

      </td>
    </tr>
  );
};

export default ProductInformationRow;