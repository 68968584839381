import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { cancelDeliveryOrder, cancelOrder, checkDeliveryOrder, deliveryOrder, getOrder, organizeOrder, paiCloverdOrder } from "../../../services/Orders";
import { toast } from "react-toastify";
import { alertOptions, removeAccents } from "../../../utils/Utilities";
import { searchProductEspecification } from "../../../services/Product";
import { getWarehouse } from "../../../services/Warehouse";
import { orderArrStatus, typeOpts } from "../../../utils/constants";

const initialOrder = {
  _id: "",
  warehouse: "",
  client: "",
  date: "",
  employee: "",
  orderType: "",
  payType: "",
  productsOrder: [],
  accessoriesOrder: [],
  newProductsOrder: [],
  newAccessoriesOrder: [],
  status: "",
  paymentState: "",
  subTotal: "",
  total: "",
  ticketClover: "",
  immediatePayment: true,
  note: "",
  totalLaborCost: 0,
};

const initialSale = {
  _id: "",
  immediatePayment: true,
  allItems: [],
  note: "",
};

const initialModalObj = { status: false, msg: "" };

const initialOrderActionsContext = {
  loader: true,
  order: initialOrder,
  sale: initialSale,
  setSale: () => { },
  globalItems: [],
  handleCancel: () => { },
  handleToShip: () => { },
  handleTicket: () => { },
  racksOptions: [],
  sectionsOptions: [],
  open: false,
  setOpen: () => { },
  isProductItem: () => { },
  setOrder: () => { },
  disableSubmitButton: true,
  handleCancelOrganization: () => { },
  isAttendStatus: true,
  isPaid: true,
  handleSetPreviousStatus: () => { },
  showConfirm: initialModalObj,
  showConfirmNumber: initialModalObj,
  showConfirmSignature: initialModalObj,
  setShowConfirm: () => { },
  setShowConfirmNumber: () => { },
  setShowConfirmSignature: () => { },
  showDelivery: false,
  setShowDelivery: () => { },
  handleConfirmDelivery: () => { },
  handleInput: () => { },
  handleShowModal: () => { },
  handleCheck: () => { },
  setGlobalItems: () => { },
  isPurchase: true,
  setLoader: () => { },
  loadingSubmitModal: false,
  successSubmitModal: false,
  handleCloseSuccesSubmitModal: () => { },
};

const OrderActionsContext = createContext(initialOrderActionsContext);

const CLOVER_URL = process.env.REACT_APP_CLOVER_URL;

export const OrderActionsContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [loader, setLoader] = useState(true);
  const [order, setOrder] = useState(initialOrder);
  const [sale, setSale] = useState(initialSale);
  const [globalItems, setGlobalItems] = useState([]);
  const [racksOptions, setRacksOptions] = useState([]);
  const [sectionsOptions, setSectionsOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [showDelivery, setShowDelivery] = useState(false);
  const [showConfirm, setShowConfirm] = useState(initialModalObj);
  const [showConfirmNumber, setShowConfirmNumber] = useState(initialModalObj);
  const [showConfirmSignature, setShowConfirmSignature] = useState(initialModalObj);

  const [loadingSubmitModal, setLoadingSubmitModal] = useState(false);
  const [successSubmitModal, setSuccessSubmitModal] = useState(false);

  const id = params.id;

  const disableSubmitButton = globalItems.length !== (order?.newAccessoriesOrder?.length + order?.newProductsOrder?.length);
  const isAttendStatus = orderArrStatus[order?.status] === orderArrStatus?.attend;
  const isPurchase = typeOpts[order?.orderType] === typeOpts.PURCHASE;
  const isPaid = sale?.immediatePayment === true;

  const handleCancel = () => {
    setLoader(true);
    cancelOrder(id, { _id: id, status: "canceled" }).then(res => {
      if (res.status === 200) {
        toast.success("Order canceled successfully", alertOptions);
        navigate("/orders");
      } else {
        toast.warning(res?.response?.data?.message, alertOptions);
      }
    }).catch(error => {
      toast.warning(error?.response?.data?.message, alertOptions);
    }).finally(() => {
      setLoader(false);
    });
  };

  const handleToShip = () => {
    let totalFulfilledQuantity = 0;
    let totalRequiredQuantity = 0;
    const finalNewProductsInventory = order?.newProductsOrder?.map((product) => {
      const newProduct = {
        _id: product?.itemId,
        productInventory: product?.productInventory,
        organize: product?.organize?.map((item) => {
          totalFulfilledQuantity += item?.takenQuantity;
          const newRack = {
            rack: item?.rack,
            quantity: item?.takenQuantity
          };
          return newRack;
        })

      };
      totalRequiredQuantity += product?.requiredQuantity;
      return newProduct;
    });

    const finalNewAccessoriesInventory= order?.newAccessoriesOrder?.map((accessory) => {
      const newAccessory = {
        _id: accessory?.itemId,
        accessoryInventory: accessory?.accessoryInventory,
        organize: accessory?.organize?.map((item) => {
          totalFulfilledQuantity += item?.takenQuantity;
          const newSection = {
            section: item?.rack,
            quantity: item?.takenQuantity
          };
          return newSection;
        })
      };
      totalRequiredQuantity += accessory?.requiredQuantity;
      return newAccessory;
    });
    if (totalFulfilledQuantity !== totalRequiredQuantity) {
      toast.warning("Please arrange the total quantity of the products", alertOptions);
      return;
    }
    setLoader(true);
    const body = {
      _id: id ,
      productsOrder: finalNewProductsInventory,
      accessoriesOrder: finalNewAccessoriesInventory
    };
    organizeOrder(id , body).then(res => {
      if (res.status === 200) {
        toast.success("Has been dispatched satisfactorily", alertOptions);
        navigate("/orders");
      } else {
        toast.warning(res.response.data.message ? res.response.data.message : "There was a problem organizing the order", alertOptions);
      }
    }).catch(error => {
      toast.warning(error.response.data.message, alertOptions);
    }).finally(() => {
      setLoader(false);
    });
  };

  const handleCancelOrganization = () => {
    setOrder(prev => ({
      ...prev,
      newProductsOrder: [],
      newAccessoriesOrder: [],
    }));
  };

  const handleTicket = () => {
    if (order?.ticketClover && order?.ticketClover !== "" && ((order?.status === "paid" && order.orderType !== "QUOTES") || (order?.status === "delivered" && order?.orderType === "QUOTES") || (order?.paymentState === "PAID" && order.orderType !== "QUOTES"))) {
      window.open(`${CLOVER_URL}${order?.ticketClover}`, "_blank", "noreferrer");
    } else {
      toast.warning("There is no ticket to show", alertOptions);
    }
  };

  const handleSetPreviousStatus = () => {
    setLoader(true);
    cancelDeliveryOrder(id, { _id: id, status: "attend" }).then(res => {
      if (res.status === 200) {
        toast.success("Status changed successfully", alertOptions);
        navigate("/orders");
      } else {
        toast.warning(res.response.data.message ? res.response.data.message : "There was a problem canceling the order", alertOptions);
      }
      setLoader(false);
    }).catch(error => {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    });
  };

  const handleConfirmDelivery = (nameInput, valueInput) => {
    setLoader(true);
    if (nameInput === "name") {
      let palabra = new RegExp(`${removeAccents(order?.client?.names)}.*`, "i");
      if (palabra.test(valueInput)) {
        setShowConfirm({...showConfirm, status: false, msg: "" });
        setShowConfirmNumber({...showConfirmNumber, status: false, msg: "" });
        let body = {
          _id: params.id,
          status:"delivered",
          deliveryValidationType: "FIRST_NAME"
        };
        handleDelivery(body);
      } else {
        setLoader(false);
        toast.warning("Your first name is incorrect, please try again", alertOptions);
      }
    } else if(nameInput === "phoneNumber") {
      if (valueInput === order?.client?.phoneNumber) {
        setShowConfirm({...showConfirm, status: false, msg: "" });
        setShowConfirmNumber({...showConfirmNumber, status: false, msg: "" });
        let body = {
          _id: params.id,
          status:"delivered",
          deliveryValidationType: "PHONE"
        };
        handleDelivery(body);
      } else {
        setLoader(false);
        toast.warning("Your phone number is incorrect, please try again", alertOptions);
      }
    }else{
      setShowConfirmSignature({ status: false, msg: "" });
      let body = {
        _id: params.id,
        status:"delivered",
        deliveryValidationType: "SIGNATURE",
        signature: valueInput
      };
      handleDelivery(body);
    }
  };

  const handleDelivery = (body = {}) => {
    deliveryOrder(params.id, body).then(res => {
      if (res.status === 200) {
        //VALIDAR QUE NO SEA UNA ORDEN MINORISTA ES DECIR MAYORISTAS (PURCHASE O SALES)
        if (order.orderType !== "QUOTES" && order.orderType !== "POWER_WINDOW_REPAIR" && order.orderType !== "WINDOW_TINT") {
          if (typeOpts[order.orderType] === typeOpts.PURCHASE) {
            setLoadingSubmitModal(true);
            paiCloverdOrder(params.id, { status: "paid" }).then(() => {
              setSuccessSubmitModal(true);
            }).catch((errorTC) => {
              toast.warning(errorTC.response.data.message, alertOptions);
            }).finally(() => {
              setLoadingSubmitModal(false);
            });
          }
          else if (typeOpts[order.orderType] === typeOpts.SALES){
            toast.success(`Order delivered successfully ${!order?.immediatePayment ? ", this order needs to be paid" : ""}`, alertOptions);
            navigate("/orders");
          }
        } else {
          toast.success("Order delivered successfully", alertOptions);
          navigate("/orders");
        }
      } else {
        toast.warning(res.response.data.message ? res.response.data.message : "There was a problem delivering the order", alertOptions);
      }
      setLoader(false);
    }).catch(error => {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    });
  };

  const handleCloseSuccesSubmitModal = () => {
    setSuccessSubmitModal(false);
    navigate('/orders');
  };

  const handleInput = (type) => {
    setShowDelivery(false);
    if (type === "name") {
      setShowConfirm({...showConfirm,
        status: true,
        msg: "Please enter customer's first name for delivery"
      });
    } else if(type === "phoneNumber") {
      setShowConfirmNumber({...showConfirmNumber,
        status: true,
        msg: "Please enter the customer's phone number for delivery"
      });
    }else{
      setShowConfirmSignature({...showConfirmSignature,
        status: true,
        msg: "Enter your signature for delivery"
      });
    }
  };

  const handleShowModal = () => {
    if (order?.orderType === "POWER_WINDOW_REPAIR" || order?.orderType === "WINDOW_TINT") {
      setShowDelivery(true);
    } else {
      const foundProductsWithoutCheck = globalItems?.filter(element => !element.check);
      if (foundProductsWithoutCheck.length === 0) {
        setShowDelivery(true);
      } else {
        toast.warning("Please check the all products before delivery", alertOptions);
      }
    }
  };

  const handleCheck = (item, isProduct) => {
    if (item?.check && item?.qtyDefault === item?.real) {
      toast.info("The check has already been carried out previously, for the current entered value", alertOptions);
    } else {
      if (item.hasOwnProperty("real") && (Number(item?.real) !== item?.quantity) && (!item?.hasOwnProperty("note") || item?.note === "")) {  // eslint-disable-line no-prototype-builtins
        toast.info("If there is a discrepancy, a note should be added", alertOptions);
      } else if (!item?.hasOwnProperty("real") && !item?.hasOwnProperty("note")) {  // eslint-disable-line no-prototype-builtins
        toast.info("Please fill in all the fields of the form", alertOptions);
      } else {
        if (!isNaN(item?.real) && item?.real > 0) {
          setLoader(true);
          let checkModel = {
            order: { _id: id },
          };
          if (isProduct) {
            checkModel = {
              ...checkModel,
              productOrder: {
                _id: item?._id,
                check: true,
                real: item?.real,
                discrepancy: Number(item?.quantity) - Number(item?.real),
                note: item?.note
              }
            };
          }
          else {
            checkModel = {
              ...checkModel,
              accessoryOrder: {
                _id: item?._id,
                check: true,
                real: item?.real,
                discrepancy: Number(item?.quantity) - Number(item?.real),
                note: item?.note
              }
            };
          }

          checkDeliveryOrder(id, checkModel).then(res => {
            if (res.status === 200) {
              const newProducts = globalItems.map(elem => {
                if (elem?._id === item?._id) return {...elem, check: true, qtyDefault: item?.real};
                return elem;
              });
              setGlobalItems(newProducts);
              toast.success("Item checked successfully", alertOptions);
              setLoader(false);
            } else {
              setLoader(false);
              toast.warning(res.response.data.message ? res.response.data.message : "There was a problem checking the item", alertOptions);
            }
          }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
          });
        } else {
          toast.warning("Please check that the real field is a numeric value greater than 0", alertOptions);
        }
      }
    }
  };

  const getOrderData = async () => {
    await getOrder(id ).then(async res => {
      if (res.status === 200) {
        let newProductsOrders = [];
        if (res.data?.orderType === "POWER_WINDOW_REPAIR" || res.data?.orderType === "WINDOW_TINT") {
          newProductsOrders.push(res.data?.anotherQuote);
        } else {
          for (let i = 0; i < res.data.productsOrder.length; i++) {
            let productsRes = await searchProductEspecification({ nag: res.data.productsOrder[i].productInventory?.product?.nags, excludePriceZero: false });
            newProductsOrders.push({
              ...res.data.productsOrder[i],
              features: res.data.productsOrder[i].productInventory.product.features,
              compatible_vehicles: productsRes.status === 200 ? productsRes.data : []
            });
          }
        }

        setOrder({...order,
          _id: res.data._id,
          warehouse: res.data.warehouse._id,
          warehouseName: res.data.warehouse.name,
          client: res.data.wholesalerUser ? res.data.wholesalerUser : res.data.retailUser,
          date: res.data.createdAt,
          employee: res.data.employee,
          orderType: res.data.orderType,
          payType: res.data.payType ? res.data?.payType : res.data?.payment,
          productsOrder: newProductsOrders,
          accessoriesOrder: res.data.accessoriesOrder,
          status: res.data.status,
          paymentState: res.data.paymentState,
          subTotal: res.data.subTotal,
          total: res.data.total,
          ticketClover: res.data?.orderIdClover ? res.data?.orderIdClover : "",
          po: res.data?.purchase?.po ? res.data?.purchase?.po : "- - - - -",
          note: res?.data?.note ?? '',
          totalLaborCost: res?.data?.totalLaborCost ?? 0,
        });

        setSale({
          _id: res.data.sale._id,
          immediatePayment: res.data.immediatePayment,
          allItems: [],
          note: res?.data?.note ?? '',
        });

        setGlobalItems([...newProductsOrders, ...res.data.accessoriesOrder]);
      }
      setLoader(false);
    });
  };

  const getWarehouseRacksAndSections = async () => {
    await getWarehouse(order.warehouse).then(res => {
      if (res.status === 200) {
        setRacksOptions(res.data.racks);
        setSectionsOptions(res.data.sections);
      }
      setLoader(false);
    }).catch(() => setLoader(false));
  };

  const isProductItem = (object = {}) => {
    return Object.hasOwn(object, ["productInventory"]);
  };

  useEffect(() => {
    if (params && id && sale?.allItems?.length === 0) {
      setLoader(true);
      getOrderData();
    }
  }, [sale?.allItems?.length]);

  useEffect(() => {
    if (params && id && order.warehouse) {
      setLoader(true);
      getWarehouseRacksAndSections();
    }
  }, [order.warehouse]);

  return (
    <OrderActionsContext.Provider
      value={{
        loader,
        order,
        sale,
        setSale,
        globalItems,
        handleCancel,
        handleToShip,
        handleTicket,
        racksOptions,
        sectionsOptions,
        open,
        setOpen,
        isProductItem,
        setOrder,
        disableSubmitButton,
        handleCancelOrganization,
        isAttendStatus,
        isPaid,
        handleSetPreviousStatus,
        showConfirm,
        setShowConfirm,
        showConfirmNumber,
        setShowConfirmNumber,
        showConfirmSignature,
        setShowConfirmSignature,
        showDelivery,
        setShowDelivery,
        handleConfirmDelivery,
        handleInput,
        handleShowModal,
        handleCheck,
        setGlobalItems,
        isPurchase,
        setLoader,
        loadingSubmitModal,
        successSubmitModal,
        handleCloseSuccesSubmitModal
      }}
    >
      {children}
    </OrderActionsContext.Provider>

  );
};

export const useOrderActionsContext = () => {
  return useContext(OrderActionsContext);
};