import { useReturnProcessContext } from "../ReturnProcessContext";

const ProductAssignmentRow = () => {
  const {
    returnedOrder,
    returnBody,
    isProductOrder,
    handleChangeRestock,
    options,
    handleChangeRack,
    handleChangeRefundQuantity,
    fields
  } = useReturnProcessContext();
  const fullProductName = `${returnedOrder?.productOrder?.productInventory?.product?.nags} - ${returnedOrder?.productOrder?.productInventory?.product?.name}`;

  return (
    <tr>
      <td
        title={isProductOrder ? fullProductName : ""}
        className="py-4 col-span-3 w-[15rem] min-w-[15rem]"
      >
        {isProductOrder ? fullProductName : returnedOrder?.accessoryOrder?.accessoryInventory?.accessory?.name}
      </td>
      <td className="py-4 col-span-3 min-w-[12rem]">
        <div className="flex gap-2 items-center justify-center">
          <button
            className={`bg-ag-primary text-white text-sm py-1 px-4 rounded-xl 
              ${returnBody?.isSellable === false && "ring-2 ring-ag-primary ring-offset-2"}`}
            onClick={() => handleChangeRestock(false)}
          >
            No
          </button>
          <button
            className={`bg-ag-active text-white text-sm py-1 px-4 rounded-xl 
              ${returnBody?.isSellable === true && "ring-2 ring-ag-active ring-offset-2"}`}
            onClick={() => handleChangeRestock(true)}
          >
            Yes
          </button>
        </div>
      </td>
      <td className="py-4 col-span-3 text-center min-w-[10rem]">
        <input
          className="text-ag-secondary-letter text-sm border border-gray-500 px-4 rounded-xl max-w-[5rem] disabled:cursor-not-allowed"
          type="text"
          onChange={handleChangeRefundQuantity}
          value={fields?.quantity}
          disabled={!returnBody?.isSellable}
        />
      </td>
      <td className="py-4 col-span-3 text-center max-w-[10rem]">
        <select
          className="col-span-4 w-[10rem] text-ag-secondary-letter text-sm border border-gray-500 p-1 rounded-xl
        disabled:bg-zinc-200 truncate disabled:cursor-not-allowed"
          onChange={handleChangeRack}
          value={returnBody?.addRackQuantity?.rack?._id}
          disabled={returnBody?.warehouse?._id === "" || !returnBody?.isSellable}
        >
          <option value="" disabled>{`${isProductOrder ? "Rack" : "Section"} name`}</option>
          {options?.map(element => (
            <option key={element?._id} value={element?._id}>{element?.name}</option>
          ))
          }
        </select>
      </td>
    </tr>
  );
};

export default ProductAssignmentRow;