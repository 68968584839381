import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import { COMPLETED_STATUS, INCOMPLETE_STATUS, PENDING_STATUS } from "../../../../utils/constants";
import { useOrderActionsContext } from "../OrderActionsContext";

export const useOrderItems = ({ item }) => {

  const [count, setCount] = useState(1);
  const [rackInputs, setRackInputs] = useState([{ id: 1, rack: {} }]);
  const [customOptions, setCustomOptions] = useState([]);
  const [currentItemStatus, setCurrentItemStatus] = useState({name: PENDING_STATUS.name, color: PENDING_STATUS.color});
  const {
    racksOptions,
    sectionsOptions,
    isProductItem,
    setOrder,
    order,
    handleCancelOrganization
  } = useOrderActionsContext();

  const rackTemplate = {
    id: count,
    rack: {},
  };

  const disableMorePartsButton =
    rackInputs.length === 3 ||
    customOptions.length < 2 ||
    !rackInputs[0]?.rack?._id ||
    rackInputs[0]?.quantityAvailable >= item?.quantity ||
    currentItemStatus?.name !== INCOMPLETE_STATUS.name;
  const isProduct = isProductItem(item);

  useEffect(() => {
    if(count > 1 && rackInputs.length < 3) addSelectInput();
  }, [count]);

  const addSelectInput = () => {
    setRackInputs(prevState => [...prevState, rackTemplate]);
  };

  const handleDeleteSelectInput = (rackId) => {
    const newRackInputs = Array.from(rackInputs);
    const rackIndex = newRackInputs.findIndex(rack => rack.id === rackId);
    newRackInputs.splice(rackIndex, 1);
    const updatedRackInputs = newRackInputs.map((rackOption) => {

      const availableQuantity = rackOption?.quantityAvailable;
      const totalTakenBefore = getTotalTakenQuantity(newRackInputs, rackIndex);
      const maxTakeable = item?.quantity - totalTakenBefore;
      const takenQuantity = Math.min(availableQuantity, maxTakeable);
      const remainingQuantity = availableQuantity - takenQuantity;

      const newRackInput = {
        ...rackOption,
        quantityAvailable: availableQuantity,
        remainingQuantity,
        takenQuantity,
        missingQuantity: Math.max(0, item?.quantity - totalTakenBefore - takenQuantity),
      };

      return newRackInput;
    });
    setRackInputs(updatedRackInputs);
    handleItemStatus(updatedRackInputs);
    isProduct ?
      organizeItemsInContext(updatedRackInputs, order?.newProductsOrder)
      : organizeItemsInContext(updatedRackInputs, order?.newAccessoriesOrder);
  };

  const handleItemStatus = (newRackInputs = []) => {
    const totalQuantityAvailable = newRackInputs.reduce(
      (accumulator, { quantityAvailable }) =>  accumulator + (quantityAvailable ?? 0), 0,
    );
    const takenQuantity = newRackInputs.reduce(
      (accumulator, { takenQuantity }) =>  accumulator + (takenQuantity ?? 0), 0,
    );
    const remainingQuantity = Math.max(0, item?.quantity - takenQuantity);
    const missingQuantity = Math.max(0, item?.quantity - totalQuantityAvailable);

    if (missingQuantity === 0 && remainingQuantity === 0) setCurrentItemStatus({ name: COMPLETED_STATUS.name, color: COMPLETED_STATUS.color });
    else setCurrentItemStatus({ name: INCOMPLETE_STATUS.name, color: INCOMPLETE_STATUS.color });
  };

  const organizeItemsInContext = (newRackInputs = rackInputs, orderArray = []) => {
    const foundProduct = orderArray?.find(product => (product?.itemId === item?._id));
    if (foundProduct) {
      setOrder(prevState => {
        const newItemsOrder = orderArray?.map(element => {
          if (element?.itemId === item?._id) {
            const newAccessory = {
              ...element,
              organize: newRackInputs
            };
            return newAccessory;
          }
          return element;
        });
        if (isProduct) return { ...prevState, newProductsOrder: newItemsOrder };
        return { ...prevState, newAccessoriesOrder: newItemsOrder };
      });
    }
    else {
      setOrder(prevState => {
        const newItem = {
          itemId: item?._id,
          organize: newRackInputs,
          requiredQuantity: item?.quantity
        };
        if (isProduct) {
          newItem["productInventory"] = { _id: item?.productInventory?._id };
          return { ...prevState, newProductsOrder: [...prevState.newProductsOrder, newItem] };
        }
        newItem["accessoryInventory"] = { _id: item?.accessoryInventory?._id };
        return {...prevState, newAccessoriesOrder: [...prevState.newAccessoriesOrder, newItem]};

      });
    }
  };

  const handleChange = (evt, optionIndex) => {
    if (rackInputs.some(({rack}) => rack._id === evt.target.value)) {
      toast.info("The selected option is already added", alertOptions);
      evt.target.value = "";
      return;
    }
    const foundOption = customOptions.find(option => (option._id === evt.target.value));
    if (foundOption) {
      const newRackInputs = rackInputs.map((rackOption, index) => {
        if (index === optionIndex) {

          const availableQuantity = foundOption?.quantityAvailable;
          const totalTakenBefore = getTotalTakenQuantity(rackInputs, optionIndex);
          const maxTakeable = item?.quantity - totalTakenBefore;
          const takenQuantity = Math.min(availableQuantity, maxTakeable);
          const remainingQuantity = availableQuantity - takenQuantity;

          const newRackInput = {
            ...rackOption,
            rack: { _id: foundOption?._id },
            quantityAvailable: availableQuantity,
            remainingQuantity,
            takenQuantity,
            missingQuantity: Math.max(0, item?.quantity - totalTakenBefore - takenQuantity),
          };

          return newRackInput;
        }
        return rackOption;
      });

      if (isProduct) {
        organizeItemsInContext(newRackInputs, order?.newProductsOrder);
      }
      else {
        organizeItemsInContext(newRackInputs, order?.newAccessoriesOrder);
      }

      handleItemStatus(newRackInputs);
      setRackInputs(newRackInputs);

    }
  };

  const getTotalTakenQuantity = (rackInputs, excludeIndex) => {
    return rackInputs.reduce((acc, { takenQuantity }, index) => (index !== excludeIndex ? acc + (takenQuantity ?? 0) : acc), 0);
  };

  useEffect(() => {
    if (isProduct) {
      // Find product in racks to only display rack options where the product exists
      const availableRacks = racksOptions.map(rack => {
        const existProduct = rack.productsRack.find(rackAux =>
          (rackAux?.productInventory?._id === (item?.data ? item?.data?.productInventory._id : item?.productInventory._id) && rackAux?.quantity > 0)
        );

        if (existProduct) return {...rack, quantityAvailable: existProduct?.quantity};
        return rack;
      }).filter(eleFilt => eleFilt.quantityAvailable);
      setCustomOptions(availableRacks);
    }
    else{
      const availableSections = sectionsOptions.map(section => {
        const existAccessory = section.accessoriesSection.find(sectionAux =>
          (sectionAux?.accessoryInventory?._id === (item?.data ? item?.data?.accessoryInventory._id : item?.accessoryInventory._id) && sectionAux?.quantity > 0)
        );

        if (existAccessory) return {...section, quantityAvailable: existAccessory?.quantity};
        return section;
      }).filter(eleFilt => eleFilt.quantityAvailable);
      setCustomOptions(availableSections);
    }
  }, [item, racksOptions, sectionsOptions]);

  useEffect(() => {
    if (order?.newProductsOrder?.length === 0 && order?.newAccessoriesOrder?.length === 0) {
      const newRackInputs = [{ id: 1, rack: {} }];
      setRackInputs(newRackInputs);
      setCount(1);
      setCurrentItemStatus({name: PENDING_STATUS.name, color: PENDING_STATUS.color});
    }
  }, [handleCancelOrganization]);

  return {
    disableMorePartsButton,
    count,
    setCount,
    addSelectInput,
    rackInputs,
    handleDeleteSelectInput,
    customOptions,
    isProduct,
    handleChange,
    currentItemStatus,
  };
};
