import { useReturnProcessContext } from "../ReturnProcessContext";
import ProductAssignmentTable from "./ProductAssignmentTable";

const ProductAssignment = () => {
  const {
    warehouseOptions,
    returnBody,
    handleChangeReceivingWarehouse,
  } = useReturnProcessContext();
  return (
    <div className="grid grid-cols-12 gap-4 px-2 border-b border-ag-secondary-light pb-6 min-w-max pt-4">
      <div className="col-span-3 grid grid-cols-1 gap-4">
        <h2 className="col-span-1 font-bold text-xl text-ag-secondary-letter">
          Select a warehouse
        </h2>
        <select
          className="col-span-1 xl:col-span-4 max-w-[13rem] text-ag-secondary-letter text-sm border border-gray-500 p-1 rounded-xl
        disabled:bg-zinc-200 truncate disabled:cursor-not-allowed"
          value={returnBody?.warehouse?._id}
          onChange={handleChangeReceivingWarehouse}
        >
          <option value="" disabled>Warehouse name</option>
          {warehouseOptions?.map(warehouse => (
            <option key={warehouse?._id} value={warehouse?._id}>{warehouse?.name}</option>
          ))}
        </select>
      </div>
      <h2 className="col-span-3 font-bold text-base text-ag-secondary-letter">
        Restock product back to inventory?
      </h2>
      <h2 className="col-span-3 font-bold text-xl text-ag-secondary-letter text-center">
        Quantity
      </h2>
      <h2 className="col-span-3 font-bold text-xl text-ag-secondary-letter text-center">
        Select rack/section
      </h2>
      <ProductAssignmentTable/>
    </div>
  );
};

export default ProductAssignment;