import { useContext, useEffect, useState } from "react";
import { PermitsContext } from "../../../services/Permits";
import { alertOptions, formatReverseDate, removeAccents } from "../../../utils/Utilities";
import { getReturns } from "../../../services/Returns";
import { getWholesalerUsers } from "../../../services/Wholesaler";
import { getWarehouses } from "../../../services/Warehouse";
import { toast } from "react-toastify";
import { getRetailUsers } from "../../../services/Retail";
import Loader from "../../../components/loader/Loader";
import TableReturns from "./TableReturns";
import DetailReturns from "./DetailReturns";
import ReturnFilters from "./ReturnFilters";

const Returns = () => {
  const { permits, dataSession } = useContext(PermitsContext);
  const [loader, setLoader] = useState(true);
  const [showDetail, setShowDetail] = useState({ status: false, data: null });
  const [returns, setReturns] = useState([]);
  const [returnsUniv, setReturnsUniv] = useState([]);
  const [searchWord, setSearchWord] = useState(null);
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);

  const handleFilters = (wordFilter, options) => {
    if (wordFilter === "" && options.warehouse === "" && options.status === "" && options.client === "" && options.dateStart === "" && options.dateEnd === "") {
      setReturns(returnsUniv);
    } else {
      let returnsFound = returnsUniv;

      if (wordFilter !== "") {
        let palabra = new RegExp(`${removeAccents(wordFilter)}.*`, "i");
        returnsFound = returnsFound.filter(element => {
          let palabraStatus = new RegExp(`${removeAccents(String(wordFilter).replace(/\s+/g, ''))}.*`, "i");
          if (
            palabraStatus.test(removeAccents(element.status)) ||
                        palabra.test(removeAccents(element.reason)) ||
                        palabra.test(removeAccents(element?.productOrder
                          ? element?.productOrder?.productInventory?.product?.nags : element?.accessoryOrder?.accessoryInventory?.accessory?.name
                        )) ||
                        palabra.test(removeAccents(`${element?.wholesaler?.names} ${element?.wholesaler?.lastNames}`)) ||
                        palabra.test(removeAccents(`${element?.employee?.names} ${element?.employee?.lastNames}`)) ||
                        palabra.test(element.total) ||
                        palabra.test(removeAccents(element?.warehouse?.name))
          ) {
            return element;
          }
        });
      }

      if (options.warehouse !== "") {
        returnsFound = returnsFound.filter(element => (element.warehouse?._id === options.warehouse));
      }

      if (options.employee !== "") {
        returnsFound = returnsFound.filter(element => (element?.requestedBy?._id === options.employee));
      }

      if (options.client !== "") {
        returnsFound = returnsFound.filter(element => (element.wholesaler?._id === options.client));
      }

      if ((options.dateStart !== "" && options.dateEnd === "") || (options.dateStart === "" && options.dateEnd !== "")) {
        toast.info("Please enter both dates to perform the filter", alertOptions);
        returnsFound = [];
      } else if (options.dateStart !== "" && options.dateEnd !== "") {
        if (formatReverseDate(options.dateStart) > formatReverseDate(options.dateEnd)) {
          toast.info("The start date is greater than the end date", alertOptions);
          returnsFound = [];
        } else {
          returnsFound = returnsFound.filter(element => (
            (formatReverseDate(element.createdAt) >= formatReverseDate(options.dateStart)) &&
                        (formatReverseDate(element.createdAt) <= formatReverseDate(options.dateEnd))
          ));
        }
      }

      setReturns(returnsFound);
    }
  };

  const getData = () => {
    getReturns().then(res => {
      if (res.data && res.data.record.length > 0) {
        let returnsFound = [];
        if (dataSession.userType === "ADMIN") {
          returnsFound = res.data.record.filter(ele =>
            ele.status !== "CANCELED" && ele?.status !== "FINISHED" && ele.status !== "REMOVED" && ele.status !== "ADDED"
          );
        } else {
          returnsFound = res.data.record.filter(ele => {
            const foundWH = dataSession.allWarehouse.find(elem => ele?.warehouse._id === elem._id);
            if (ele.status !== "CANCELED" && ele?.status !== "FINISHED" && ele.status !== "REMOVED" && ele.status !== "ADDED" && foundWH) return ele;
          });
        }

        const array_sort = returnsFound.sort(function(a,b){
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

        const ids = new Set();
        const employees = array_sort.filter(element => {
          if (element?.requestedBy) {
            if (!ids.has(element?.requestedBy?._id)) {
              ids.add(element?.requestedBy?._id);
              return { _id: element?.requestedBy?._id, names: element.requestedBy?.names, lastNames: element.requestedBy?.lastNames };
            }
            ids.add(element?.requestedBy?._id);
          }
        });

        setEmployeeOptions(employees);

        setReturns(array_sort);
        setReturnsUniv(array_sort);
      }
      setLoader(false);
    });
  };

  useEffect(() => {
    getData();
  }, [dataSession]);

  useEffect(() => {
    let array_clients = [];
    getWholesalerUsers().then(resWU => {
      if (resWU.status === 200 && resWU.data.length > 0) {
        const clientsFound = resWU.data.filter(element => !element.deleted);
        array_clients = array_clients.concat(clientsFound);
      }
      getRetailUsers().then(resRU => {
        if (resRU.status === 200 && resRU.data.length > 0) {
          const clientsFoundAux = resRU.data.filter(element => !element.deleted);
          array_clients = array_clients.concat(clientsFoundAux);
        }

        const sortUser = array_clients.sort(function(a, b) {
          return a?.user?.identifier - b?.user?.identifier;
        });

        setClientOptions(sortUser);
      });
    });

    getWarehouses().then(res => {
      if (res.status === 200 && res.data.length > 0) {
        const warehousesCurrent = res.data.filter(element => !element.deleted);
        setWarehouseOptions(warehousesCurrent);
      }
    });
  }, []);

  return (
    <>
      <div className="space-y-3 px-3 py-5 md:px-8 md:py-8">
        <div className="flex justify-center items-center text-center">
          <h1 className="text-[26px] md:text-xl lg:text-[26px] text-ag-secondary font-bold">
            Returns
          </h1>
        </div>
        <div className="">
          <ReturnFilters
            enableFilters={permits['ORDERS']['RETURNS_FILTERS']}
            warehouses={warehouseOptions}
            employees={employeeOptions}
            clients={clientOptions}
            wordFilter={searchWord}
            onFilters={handleFilters}
            permits={permits}
            setSearchWord={setSearchWord}
          />
        </div>
        <div className="pt-3">
          <TableReturns
            enableActions={permits['ORDERS']['ACTIONS_RETURNS']}
            returns={returns}
            onDetail={(returnElement) => setShowDetail({...showDetail, status: true, data: returnElement})}
          />
        </div>
      </div>

      { loader ? <Loader /> : null }

      {
        showDetail.status ?
          <DetailReturns
            returnElement={showDetail.data}
            onClose={() => setShowDetail({...showDetail, status: false, data: null})}
          />
          : null
      }
    </>
  );
};

export default Returns;