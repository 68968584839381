import { useReturnProcessContext } from "../ReturnProcessContext";
import ProductInformationTable from "./ProductInformationTable";

const ProductInformation = () => {
  const { fields, handleReturnLaborChecked, handleReturnLaborCost} = useReturnProcessContext();
  return (
    <div className="grid grid-cols-12 gap-4 px-2 border-b border-ag-secondary-light pb-6 min-w-max">
      <div className="flex col-span-12 pt-4 w-full">
        <h2 className="font-bold text-xl text-ag-secondary-letter mr-auto min-w-[35rem]">
          Product information
        </h2>
        <h2 className="font-bold text-xl text-ag-secondary-letter text-left min-w-[15rem] max-w-[15rem]">
          Returns management
        </h2>
      </div>
      <div className="flex col-span-12 gap-4 place-items-center">
        <input
          type='checkbox'
          className='accent-ag-active'
          onChange={(e) => handleReturnLaborChecked(e.target.checked)}
          checked={fields?.returnLabor}
        />
        <span>Return service</span>
        <input
          type='text'
          className='w-[10rem] rounded-md border px-2 py-1 text-sm focus:border-emerald-500 focus:outline-none focus:ring-3 focus:ring-emerald-500'
          onChange={(e) => handleReturnLaborCost(e)}
          disabled={!fields?.returnLabor}
          value={fields?.returnLaborCost}
          min={0}
        />
      </div>
      <ProductInformationTable />
    </div>
  );
};

export default ProductInformation;