import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addInventoryReturn, approvedReturn, cancelReturn, getReturn } from "../../../../services/Returns";
import { toast } from "react-toastify";
import { PermitsContext } from "../../../../services/Permits";
import { alertOptions } from "../../../../utils/Utilities";
import { getWarehouse, getWarehouses } from "../../../../services/Warehouse";
import { fileUpload } from "../../../../services/File";

const initialReturnedOrder = {
  _id: '',
  warehouse: {
    _id: '',
    name: '',
  },
  order: {
    _id: '',
    productsOrder: [],
    accessoriesOrder: [],
    total: 0,
    subtotal: 0,
    orderIdClover: '',
    status: '',
    orderType: '',
    paymentState: ''
  },
  productOrder: null,
  accessoryOrder: null,
  employee: {
    _id: '',
    names: '',
    lastNames:''
  },
  wholesaler: {
    _id: '',
    names: '',
    lastNames:''
  },
  createdAt: '',
  total: 0,
  status: "",
  quantity: 0,
  requestedQuantity: 0,
  price: 0,
  returnLabor: false,
  returnLaborCost: 0,
};

const initialReturnBody = {
  _id: "",
  warehouse: {
    _id: "",
    name: ""
  },
  productInventory: {
    _id:""
  },
  note: "",
  isSellable: null,
  removeQuantity: 0,
  addQuantity: 0,
  addRackQuantity:
    {
      rack: {
        _id: "",
        name: ""
      },
    },
};

const initialFields = {
  quantity: 0,
  note: '',
  returnLabor: false,
  returnLaborCost: 0,
};

const initialReturnProcessContext = {
  loader: true,
  returnedOrder: initialReturnedOrder,
  isProductItem: () => { },
  handleChangeSelectedButton: () => { },
  handleSelectedItems: () => { },
  selectedItems: [],
  isProductOrder: false,
  dataSession: null,
  fields: initialFields,
  handleChangeRefundQuantity: () => { },
  handleChangeDenyReason: () => { },
  handleCancel: () => { },
  handleAccept: () => { },
  returnBody: initialReturnBody,
  warehouseOptions: [],
  handleChangeReceivingWarehouse: () => { },
  handleChangeRestock: () => { },
  options: [],
  handleChangeRack: () => { },
  handleTicket: () => { },
  handleFileChange: () => { },
  imageUpload: [],
  deleteArrImage: () => { },
  handleSubmitReturn: () => { },
  itemDiscount: 0,
  handleReturnLaborChecked: () => {},
  handleReturnLaborCost: () => { },
  itemLaborCost: 0,
};

const ReturnProcessContext = createContext(initialReturnProcessContext);

const CLOVER_URL = process.env.REACT_APP_CLOVER_URL;

export const ReturnProcessContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [loader, setLoader] = useState(true);

  const [returnedOrder, setReturnedOrder] = useState(initialReturnedOrder);
  const [returnBody, setReturnBody] = useState(initialReturnBody);

  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [rackOptions, setRackOptions] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [options, setOptions] = useState([]);

  const { dataSession } = useContext(PermitsContext);

  const [fields, setFields] = useState(initialFields);
  const [imageUpload, setImageUpload] = useState([
    { name: "", file: null },
    { name: "", file: null },
    { name: "", file: null },
    { name: "", file: null },
    { name: "", file: null },
    { name: "", file: null },
    { name: "", file: null },
    { name: "", file: null },
    { name: "", file: null },
    { name: "", file: null },
    { name: "", file: null },
    { name: "", file: null }
  ]);

  const id = params.id;

  const isProductOrder = returnedOrder?.productOrder != null;

  const itemTotalWithDiscount = (isProductOrder ?
    (returnedOrder?.productOrder?.price * returnedOrder?.productOrder?.quantity) - returnedOrder?.productOrder?.discountAmount
    :
    (returnedOrder?.accessoryOrder?.price * returnedOrder?.accessoryOrder?.quantity) - returnedOrder?.accessoryOrder?.discountAmount)
    ?? 0;

  const itemDiscount = (
    (isProductOrder ?
      (itemTotalWithDiscount / returnedOrder?.productOrder?.quantity) * returnedOrder?.quantity
      : (itemTotalWithDiscount / returnedOrder?.accessoryOrder?.quantity) * returnedOrder?.quantity
    ) + (returnedOrder?.returnLabor ? returnedOrder?.returnLaborCost : 0)
  ) ?? 0;

  const itemLaborCost = isProductOrder ? returnedOrder?.productOrder?.laborCost ?? 0 : returnedOrder?.accessoryOrder?.laborCost ?? 0;

  const handleReturnLaborCost = (e) => {
    let returnLaborCost;
    if (isNaN(e.target.value)) returnLaborCost = 0;
    else returnLaborCost = parseInt(e.target.value, 10) || 0;

    setFields({...fields, returnLaborCost});
  };

  const handleReturnLaborChecked = (checked) => {
    setFields({
      ...fields,
      returnLabor: checked,
      returnLaborCost: checked ? (fields?.returnLaborCost ?? 0) : 0
    });
  };

  const handleChangeRefundQuantity = (e) => {
    let quantity;
    if (isNaN(e.target.value)) quantity = 1;
    else quantity = parseInt(e.target.value, 10) || 0;

    setFields({...fields, quantity});
  };

  const handleChangeDenyReason = (e) => {
    setFields({...fields, note:e.target.value});
  };

  const handleCancel = () => {
    const body = {
      _id: returnedOrder?._id,
      employee: { _id: dataSession._id },
      status: "CANCELED",
      cancelReason: fields?.note
    };
    if (fields?.note == null || fields?.note === "") {
      toast.warning("Make sure to add a reason in the Note field", alertOptions);
      return;
    }
    setLoader(true);
    setFields(initialFields);
    cancelReturn(returnedOrder?._id, body).then(res => {
      if (res.status === 200) {
        getReturnData();
        navigate("/returns");
        setLoader(false);
        toast.success("Return canceled successfully", alertOptions);
      } else {
        setLoader(false);
        toast.warning(res.response.data.message, alertOptions);
      }
    }).catch(error => {
      setLoader(false);
      toast.warning(error.response.data.message, alertOptions);
    });
  };

  const handleAccept = () => {
    setLoader(true);
    if (fields?.quantity > returnedOrder?.quantity || fields.quantity <= 0) {
      toast.warning("Make sure the quantity is greater than 0 and lower than the total ordered quantity", alertOptions);
      setLoader(false);
      return;
    }
    if (fields?.returnLabor && !fields?.returnLaborCost) {
      toast.warning('Please add the returned labor cost', alertOptions);
      setLoader(false);
      return;
    }
    if (fields?.returnLabor && fields?.returnLaborCost > itemLaborCost) {
      toast.warning(`The labor cost was $${itemLaborCost}, make sure to return that amount or a lower one`, alertOptions);
      setLoader(false);
      return;
    }
    const body = {
      _id: returnedOrder?._id,
      employee: { _id: dataSession._id },
      status: "APPROVED",
      returnMoney: (returnedOrder?.price * fields?.quantity) + fields?.returnLaborCost ?? 0,
      rejectedQuantity: returnedOrder?.quantity - fields?.quantity,
      quantity: fields?.quantity,
      returnLabor: fields?.returnLabor,
      returnLaborCost: fields?.returnLaborCost ?? 0
    };
    setFields(initialFields);
    approvedReturn(returnedOrder?._id, body).then(res => {
      if (res.status === 200) {
        getReturnData();
        toast.success("Return approved successfully", alertOptions);
      } else {
        toast.warning(res.response.data.message, alertOptions);
      }
    }).catch(error => {
      toast.warning(error.response.data.message, alertOptions);
    }).finally(()=> {
      setLoader(false);
    });
  };

  const handleChangeReceivingWarehouse = (e) => {
    const foundOption = warehouseOptions?.find(el => el._id === e.target.value);
    setReturnBody(prev => ({
      ...prev,
      warehouse: { _id: e.target.value, name: foundOption?.name },
      addRackQuantity: {
        rack: {
          _id: "",
          name: "",
        },
      },
    }));
  };

  const handleChangeRestock = (value = true) => {
    setReturnBody(prev => ({
      ...prev,
      isSellable: value,
      addRackQuantity: {
        rack: value === false ?
          { _id: "", name: "" }
          :
          { _id: prev?.addRackQuantity?.rack?._id, name: prev?.addRackQuantity?.rack?.name },
      },
    }));
    setFields(prev => ({...prev, quantity: 0}));
  };

  const handleChangeRack = (e) => {
    let foundOption = {};
    if (isProductOrder) foundOption = rackOptions?.find(el => el._id === e.target.value);
    else foundOption = sectionOptions?.find(el => el._id === e.target.value);

    setReturnBody(prev => ({
      ...prev,
      addRackQuantity: {
        ...prev.addRackQuantity,
        rack: {
          _id: e.target.value,
          name: foundOption?.name
        },
      },
    }));
  };

  const handleFileChange = (evt, index) => {
    if (evt.currentTarget.files[0].type !== "image/png" && evt.currentTarget.files[0].type !== "image/jpg" && evt.currentTarget.files[0].type !== "image/jpeg") {
      toast.warning("It is not possible to upload a file other than png, jpg or jpeg", alertOptions);
    } else {
      const file = evt.currentTarget.files;
      if (file !== null) {
        const newImageUpload = imageUpload?.map((imgEle, i) => {
          if (index === i) {
            return { name: file[0].name, file: file[0] };
          }

          return { name: imgEle.name, file: imgEle.file };
        });
        setImageUpload(newImageUpload);
      }
    }
  };

  const deleteArrImage= (index) => {
    const newImageUpload = imageUpload.map((imgEle, i) => {
      if (index === i) {
        return { name: "", file: null };
      }

      return { name: imgEle.name, file: imgEle.file };
    });

    setImageUpload(newImageUpload);
  };

  async function validateAndUploadImages() {
    let productImagesValid = [];

    for (let i = 0; i < imageUpload.length; i++) {
      const currentImage = imageUpload[i];

      if (currentImage.file !== null && currentImage.name !== "") {
        let bodyFormData = new FormData();
        bodyFormData.append('file', currentImage.file);

        try {
          const valueUpload = await fileUpload(bodyFormData);
          if (valueUpload.status === 200) {
            productImagesValid.push(valueUpload.data);
          } else {
            productImagesValid.push(""); // Caso de error en la carga
          }
        } catch (error) {
          console.error('Error uploading file:', error);
          productImagesValid.push(""); // Manejo de errores en la carga
        }

      } else if (currentImage.name !== "" && currentImage.url && currentImage.url !== null) {
        productImagesValid.push(currentImage.url); // Imagen existente
      }
    }

    return productImagesValid;
  }

  const handleSubmitReturn = async () => {
    setLoader(true);
    if (returnBody?.warehouse?._id === "") {
      toast.warning("Please select a receiving warehouse for this item", alertOptions);
      setLoader(false);
      return;
    }
    if (returnBody?.isSellable == null) {
      toast.warning("You must specify if the item should be restocked back to the inventory", alertOptions);
      setLoader(false);
      return;
    }
    if (returnBody?.isSellable && returnBody?.addRackQuantity?.rack?._id === "") {
      toast.warning(`You must specify the ${isProductOrder ? "rack" : "section"} where the item will be restocked`, alertOptions);
      setLoader(false);
      return;
    }
    if (returnBody?.isSellable && (fields?.quantity > returnedOrder?.quantity || fields.quantity <= 0)) {
      toast.warning("Make sure the quantity is greater than 0 and lower than the total accepted quantity", alertOptions);
      setLoader(false);
      return;
    }
    if (returnedOrder?.quantity !== returnedOrder?.requestedQuantity && fields?.note === '') {
      toast.warning("Make sure to specify a note since you rejected some of the items in the previous step", alertOptions);
      setLoader(false);
      return;
    }
    const productImages = await validateAndUploadImages();

    const itemBody = {
      _id: returnedOrder?._id,
      warehouse: { _id: returnBody?.warehouse?._id },
      note: fields?.note ?? '',
      photos: productImages ?? [],
      removeQuantity: returnBody?.isSellable ? returnedOrder?.quantity - (fields?.quantity ?? 1) ?? 1 : returnedOrder?.quantity,
      addQuantity: returnBody?.isSellable ? fields?.quantity ?? 1 : 0,
      isSellable: returnBody?.isSellable,
    };

    if (isProductOrder) {
      itemBody["productInventory"] = { _id: returnedOrder?.productOrder?.productInventory?._id };
      itemBody["addRackQuantity"] = returnBody?.isSellable ? [{
        rack: { _id: returnBody?.addRackQuantity?.rack?._id },
        quantity: fields?.quantity,
      }] : [];
    }
    else{
      itemBody["accessoryInventory"] = { _id: returnedOrder?.accessoryOrder?.accessoryInventory?._id };
      itemBody["addSectionQuantity"] = returnBody?.isSellable ? [{
        section: { _id: returnBody?.addRackQuantity?.rack?._id },
        quantity: fields?.quantity,
      }] : [];
    }

    addInventoryReturn(id, itemBody).then(res => {
      if (res.status === 200) {
        toast.success("Product returned successfully", alertOptions);
        navigate("/returns");
      } else {
        toast.warning(res.response.data.message, alertOptions);
      }
    }).catch(error => {
      toast.warning(error.response.data.message, alertOptions);
    }).finally(() => {
      setLoader(false);
    });
  };

  const handleTicket = () => {
    if (
      returnedOrder?.order?.orderIdClover && returnedOrder?.order?.orderIdClover !== ""
      &&
      ((returnedOrder?.order?.status === "paid" && returnedOrder?.order?.orderType !== "QUOTES")
        || (returnedOrder?.order?.status === "delivered" && returnedOrder?.order?.orderType === "QUOTES")
        || (returnedOrder?.order?.paymentState === "PAID" && returnedOrder?.order?.orderType !== "QUOTES")
      )) {
      window.open(`${CLOVER_URL}${returnedOrder?.order?.orderIdClover}`, "_blank", "noreferrer");
    } else {
      toast.warning("There is no ticket to show", alertOptions);
    }
  };

  const fetchWarehouses = async () => {
    try {
      setLoader(true);
      if (!dataSession?.userType || !Array.isArray(dataSession.allWarehouse)) {
        setWarehouseOptions([]);
        return;
      }
      const res = await getWarehouses();
      if (res.status === 200) {
        const warehouses = res.data.filter((element) =>
          !element.deleted &&
            (dataSession.userType === "ADMIN" || dataSession.allWarehouse.some((elem) => element._id === elem._id))
        );
        setWarehouseOptions(warehouses);
        res.data.map(({ _id }) => {
          getWarehouse(_id).then(res => {
            if (res.status === 200) {
              if (isProductOrder) {
                const racksPerWarehouse = res.data.racks.map(rack => ({ warehouseId: _id, ...rack}));
                setRackOptions(prevState => [...prevState, ...racksPerWarehouse]);
              }
              else {
                const sectionsPerWarehouse = res.data.sections.map(section => ({warehouseId: _id, ...section}));
                setSectionOptions(prevState => [...prevState, ...sectionsPerWarehouse]);
              }
            }
          });
        });
      } else {
        setWarehouseOptions([]);
      }
    } catch (error) {
      setWarehouseOptions([]);
    }
    finally {
      setLoader(false);
    }
  };

  const getReturnData = () => {
    getReturn(id)
      .then(res => {
        if (res.status === 200 && res.data) {
          setReturnedOrder({
            _id: res?.data?._id,
            warehouse: {
              _id: res?.data?.warehouse?._id,
              name: res?.data?.warehouse?.name,
            },
            order: {
              _id: res?.data?.order?._id,
              productsOrder: res?.data?.order?.productsOrder,
              accessoriesOrder: res?.data?.order?.accessoriesOrder,
              total: res?.data?.order?.total,
              subtotal: res?.data?.order?.subtotal,
              orderIdClover: res?.data?.order?.orderIdClover,
              status: res?.data?.order?.status,
              orderType: res?.data?.order?.orderType,
              paymentState: res?.data?.order?.paymentState
            },
            productOrder: res?.data?.productOrder,
            accessoryOrder: res?.data?.accessoryOrder,
            employee: {
              _id: res?.data?.order?.employee?._id,
              names: res?.data?.order?.employee?.names,
              lastNames: res?.data?.order?.employee?.lastNames
            },
            wholesaler: {
              _id: res?.data?.wholesaler?._id,
              names: res?.data?.wholesaler?.names,
              lastNames:res?.data?.wholesaler?.lastNames
            },
            createdAt: res?.data?.createdAt,
            total: res?.data?.total,
            status: res?.data?.status,
            quantity: res?.data?.quantity,
            requestedQuantity: res?.data?.requestedQuantity,
            price: res?.data?.price,
            returnLabor: res?.data?.returnLabor,
            returnLaborCost: res?.data?.returnLaborCost,
          });
        }
      })
      .catch(err => {
        console.log(err);
        toast.error("There was an error while fetching the returned order");
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getReturnData();
  }, [id]);

  useEffect(() => {
    if(dataSession && warehouseOptions.length === 0 && returnedOrder?.status === "APPROVED") fetchWarehouses();
  }, [returnedOrder?.status, dataSession, id]);

  useEffect(() => {
    let filteredOptions = [];
    if(returnBody?.warehouse?._id === "") return;
    if (isProductOrder) {
      filteredOptions = rackOptions?.filter(rack => rack?.warehouseId === returnBody?.warehouse?._id);
    }
    else {
      filteredOptions = sectionOptions?.filter(section => section?.warehouseId === returnBody?.warehouse?._id);
    }
    setOptions(filteredOptions);
  }, [returnBody?.warehouse?._id]);


  return (
    <ReturnProcessContext.Provider
      value={{
        loader,
        returnedOrder,
        isProductOrder,
        dataSession,
        fields,
        handleChangeRefundQuantity,
        handleChangeDenyReason,
        handleCancel,
        handleAccept,
        returnBody,
        warehouseOptions,
        handleChangeReceivingWarehouse,
        handleChangeRestock,
        options,
        handleChangeRack,
        handleTicket,
        handleFileChange,
        imageUpload,
        deleteArrImage,
        handleSubmitReturn,
        itemDiscount,
        handleReturnLaborChecked,
        handleReturnLaborCost,
        itemLaborCost,
      }}
    >
      {children}
    </ReturnProcessContext.Provider>

  );
};

export const useReturnProcessContext= () => {
  return useContext(ReturnProcessContext);
};