import { useEffect, useState } from "react";
import { useOrderActionsContext } from "../OrderActionsContext";

export const useRowsDeliveryUtils = ({ item }) => {
  const [values, setValues] = useState({ real: 0, note: "" });
  const { isProductItem, globalItems, setGlobalItems, handleCheck } = useOrderActionsContext();
  const isProduct = isProductItem(item);
  const onlyNumbersPattern = new RegExp(/^[0-9]+$/g);

  const handleItem = (evt, item) => {
    const newProductsOrder = globalItems?.map(element => {
      if (item._id === element._id) {
        return {...element, [evt.target.name]: parseInt(evt.target.value, 10) || 0};
      }
      return element;
    });
    setGlobalItems(newProductsOrder);
  };

  const handleChange = (evt) => {
    const { name, value } = evt.target;

    if (name === "real") {
      if (value === '' || onlyNumbersPattern.test(value)) {
        setValues({ ...values, real: onlyNumbersPattern.test(value) ? value : 0});
        handleItem(evt, item);
      }
      else {
        evt.target.value = 0;
        setValues({ ...values, real: 0 });
      }
    } else {
      setValues({ ...values, note: value });
      handleItem(evt, item);
    }
  };

  useEffect(() => {
    if (item?.note) setValues({ ...values, note: item.note });
  }, [item?.note]);

  useEffect(() => {
    if (item?.real) setValues({ ...values, real: item.real });
  }, [item?.real]);

  return {
    handleChange,
    values,
    setValues,
    isProduct,
    handleItem,
    handleCheck
  };
};
