import { useContext, useEffect, useState } from "react";
import { searchInventoryAdd } from "../../../services/Inventory";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { IoMdSearch } from "react-icons/io";
import { PermitsContext } from "../../../services/Permits";
import ModalCompatible from "../products/ModalCompatible";
import Pagination from "../../../components/pagination/Pagination";

function SelectProduct({ onClose, addToCart, warehouseSelected }) {
  const [quantity, setQuantity] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const { dataSession } = useContext(PermitsContext);
  const [pagesData, setPagesData] = useState({ total: 0, records: 0 });
  const [products, setProducts] = useState([]);
  const [filterPagination, setFilterPagination] = useState({
    data: dataSession.userType === "ADMIN" || dataSession.userType === "ADMINISTRATOR" ? (warehouseSelected ? { warehouse: warehouseSelected?._id } : {}) : { warehouse: dataSession?.allWarehouse[0]._id },
    status: false
  });
  const [filterParams, setFilterParams] = useState({
    search: "",
  });
  const [showCompatible, setShowCompatible] = useState({ status: false, element: null });
  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(9);

  const handleFilters = (filtersParams) => {
    setCurrentPage(1);
    let filterObject = dataSession.userType === "ADMIN" || dataSession.userType === "ADMINISTRATOR" ? (warehouseSelected ? { warehouse: warehouseSelected?._id } : {}) : { warehouse: dataSession?.allWarehouse[0]._id };
    if (
      filtersParams.search === ""
    ) {
      setIsLoading(true);
      if (dataSession.allWarehouse.length > 0) {
        setFilterPagination({ ...filterPagination, data: { warehouse: dataSession?.allWarehouse[0]._id }, status: false });
      } else {
        setFilterPagination({ ...filterPagination, data: {}, status: false });
      }
      searchInventoryForPage(1, dataSession.userType === "ADMIN" || dataSession.userType === "ADMINISTRATOR" ? (warehouseSelected ? { warehouse: warehouseSelected?._id } : {}) : { warehouse: dataSession?.allWarehouse[0]._id });
    } else {
      setIsLoading(true);
      for (const key in filtersParams) {
        if (Object.hasOwnProperty.call(filtersParams, key)) {
          filterObject[key] = filtersParams[key];
        }
      }
      setFilterPagination({ ...filterPagination, data: filterObject, status: true });
      searchInventoryForPage(1, filterObject);
    }
  };

  const handleChange = (evt) => {
    const { name, value } = evt.target;
    setFilterParams({ ...filterParams, [name]: value });
  };

  const searchInventoryForPage = (pageInventory, paramsFilters) => {
    searchInventoryAdd(pageInventory, recordsPerPage, paramsFilters).then(res => {
      if (res.status === 200 && res.data.productsInventory.length > 0) {
        let array_productsInvetory = res.data.productsInventory.map((x) => {
          return {
            ...x,
            year: (typeof filterParams.year) === 'string' ? filterParams.year : ''
          };
        });
        if (warehouseSelected !== null) {
          const filteredBystatus = array_productsInvetory.filter((item) => item.productInventory.status !== "dead" && item.warehouse._id === warehouseSelected._id);
          setProducts(filteredBystatus);
        } else {
          const filteredBystatus = array_productsInvetory.filter((item) => item.productInventory.status !== "dead");
          setProducts(filteredBystatus);
        }
        setPagesData({ ...pagesData, total: res.data.totalRecords / recordsPerPage, records: res.data.totalRecords });
      } else {
        toast.warning("Search no records found", alertOptions);
      }

      setIsLoading(false);
    }).catch((error) => {
      toast.warning(error.response.data.message, alertOptions);
      setIsLoading(false);
    });
  };

  const handleQuantityChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    if (value === '' || parseInt(value) > 0) {
      setQuantity(value);
    }
  };

  const handleAddProduct = (isBulkOrder) => {
    addToCart(isBulkOrder, selectedItem, quantity, false);
    setSelectedItem(null);
    setQuantity('1');
  };

  useEffect(() => {
    setIsLoading(true);
    searchInventoryForPage(1, dataSession.userType === "ADMIN" || dataSession.userType === "ADMINISTRATOR" ? (warehouseSelected ? { warehouse: warehouseSelected._id } : {}) : { warehouse: dataSession?.allWarehouse[0]._id });
  }, []);


  const handleSubmit = (evt) => {
    evt.preventDefault();
    const { search } = filterParams;
    const searchQuery = {
      search: search.trim(),
    };
    handleFilters(searchQuery);
  };

  const changePage = (pge) => {
    setIsLoading(true);
    setCurrentPage(pge);
    searchInventoryForPage(pge, filterPagination.data);
  };

  return (

    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-[85%] max-h-[85%] rounded-lg bg-white p-6 overflow-y-auto">
        <div className="mb-4 flex items-center justify-between">
          <div>
          </div>

          <h2 className="text-xl font-semibold">Inventory specification</h2>

          <button
            onClick={() => onClose()}
            className="text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        </div>

        <div className="px-6 pt-2 md:justify-center md:items-center space-x-0 space-y-3 md:space-x-3 md:space-y-0">
          <form onSubmit={handleSubmit}>
            <div className="w-full rounded-lg flex">
              <input
                type="text"
                placeholder="Search"
                name="search"
                onChange={handleChange}
                className="w-full rounded-md border border-gray-300 px-4 py-2"
              />

              <button
                className="w-[50px] bg-ag-secondary text-center text-white p-[6px] rounded-md flex justify-center"
                type="button"
                onClick={() => handleFilters(filterParams)}
              >
                <span className="text-2xl">
                  <IoMdSearch />
                </span>
              </button>

            </div>
          </form>
        </div>
        <div className="max-h-[60vh] overflow-auto mt-[30px]">
          { isLoading ? (
            <div className="flex justify-center items-center h-32">
              <div className="loader border-t-4 border-blue-500 rounded-full w-12 h-12 animate-spin"></div>
              <span className="ml-4">Loading products...</span>
            </div>
          ) :
            <table className="w-full">
              <thead className="bg-gray-100">
                <tr>
                  <th className="border px-4 py-2">Warehouse</th>
                  <th className="border px-4 py-2">NAG</th>
                  <th className="border px-4 py-2">Features</th>
                  <th className="border px-4 py-2">Name</th>
                  <th className="border px-4 py-2">Compatible Vehicles</th>
                  <th className="border px-4 py-2">Barcodes</th>
                  <th className="border px-4 py-2">Sales Price</th>
                  <th className="border px-4 py-2">Stock</th>
                </tr>
              </thead>
              <tbody>
                {products.map((item) => {
                  const product = item.productInventory.product;
                  return  (
                    <tr
                      key={`${product._id}-${item.warehouse.name}`}
                      onClick={() =>
                      {
                        if (item.totalQuantity > 0)
                          setSelectedItem({ ...product, warehouse: item.warehouse.name, cost: item.productInventory.cost, productInventory: { _id: item.productInventory._id } });
                      }
                      }
                      className={`cursor-pointer transition-colors ${
                        selectedItem && selectedItem._id === product._id && item.warehouse.name === selectedItem.warehouse && item.totalQuantity > 0 ? 'bg-green-100' : 'hover:bg-gray-50'
                      }`}
                    >
                      <td className="border px-4 py-2">{item.warehouse.name}</td>
                      <td className="border px-4 py-2">{product.nags}</td>
                      <td className="border px-4 py-2">{product.features}</td>
                      <td className="border px-4 py-2">{product.name}</td>
                      <td className="border px-4 py-2">
                        {
                          product.compatible_vehicles.map((prodComp, i) => {
                            if (i <= 2) {
                              return (
                                <p className="truncate w-24 m-auto" key={i} title={
                                  `${String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year} ` +
                                    `${prodComp?.brand} ` +
                                    `${prodComp?.model} ` +
                                    `${prodComp?.type_car}`
                                }>
                                  { String(prodComp?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodComp?.year).replace(/\s+/g, '').split(',').sort()[Number(String(prodComp?.year).replace(/\s+/g, '').split(',').length-1)]}` : prodComp?.year }{' '}
                                  { prodComp?.brand }{' '}
                                  { prodComp?.model }{' '}
                                  { prodComp?.type_car }
                                </p>
                              );
                            }
                          })
                        }
                        {
                          product.compatible_vehicles.length > 3 ?
                            <p className="truncate w-24 font-semibold m-auto cursor-pointer" onClick={() => setShowCompatible({status: true, element: product})}>
                            See compatible vehicles +
                            </p>
                            : null
                        }
                      </td>
                      <td className="border px-4 py-2">
                        <p>
                          {product.barcode1}
                        </p>

                        <p>
                          {product.barcode2 ? product.barcode2 : ''}
                        </p>
                      </td>
                      <td className="border px-4 py-2">${item.productInventory.cost.toFixed(2)}</td>
                      <td className="border px-4 py-2">{item.totalQuantity}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          }
          <div className="p-5 m-auto">
            <Pagination
              currentPage={currentPage}
              totalCount={pagesData.records}
              pageSize={recordsPerPage}
              onPageChange={page => changePage(page)}
            />
          </div>
        </div>

        <div className="mt-4 flex flex-col sm:flex-row items-center justify-end gap-4">
          <input
            type="text"
            value={quantity}
            onChange={handleQuantityChange}
            placeholder="Quantity"
            className="w-32 rounded-md border border-gray-300 px-4 py-2"
          />
          <button
            onClick={() => handleAddProduct(true)}
            className="rounded-md bg-orange-500 px-4 py-2 text-white hover:bg-orange-600 cursor-pointer disabled:cursor-not-allowed"
            disabled={!selectedItem || !parseInt(quantity) > 0}
          >
            Bulk order
          </button>
          <button
            onClick={() => handleAddProduct(false)}
            className={`rounded-md bg-emerald-500 ${quantity > 1 ? 'opacity-50' : 'opacity-1 hover:bg-emerald-600'} px-4 py-2 text-white 
              cursor-pointer disabled:cursor-not-allowed`}
            disabled={!selectedItem || quantity > 1}
          >
            Add product
          </button>
        </div>
      </div>

      {
        showCompatible.status ?
          <ModalCompatible
            products={showCompatible.element}
            onClose={() => setShowCompatible({status: false, element: null})}
          />
          : null
      }
    </div>
  );
}

export default SelectProduct;
