import { IoMdCloseCircle } from "react-icons/io";
import { formatCurrency, formatDateHourOrders } from "../../../utils/Utilities";
import { useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";

const DetailReturns = ({ returnElement, onClose}) => {
  const returnId = String(returnElement?._id).substring(String(returnElement?._id).length - 13).toLocaleUpperCase();
  const isProductOrder = Object.hasOwn(returnElement, "productOrder");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const renderPhotos = (photosArr = []) => {
    return photosArr?.map((url, index) => {
      const fileName = url?.substring(url?.lastIndexOf('/')+1);
      return (
        <div key={index} className="w-[80px] sm:w-[100px] flex justify-center">
          <div className="w-full p-1 m-auto">
            <div className="w-full h-full flex justify-center items-center cursor-pointer" onClick={() => openModal({url, fileName})}>
              <img
                className="min-h-[50px] sm:min-h-[70px] max-h-[70px] object-cover"
                src={url}
                alt={fileName || "Uploaded image"}
              />
            </div>

            <div className="flex pt-2">
              <span className="w-full text-left text-[10px] sm:text-xs text-ag-secondary truncate">
                {fileName}
              </span>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div className="block">
        <div className="justify-center items-center flex fixed inset-0 z-20 outline-none focus:outline-none">
          <div className="min-w-[60dvw] h-[70dvh] overflow-auto">
            <div className="p-3 md:p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none overflow-auto">
              <div className="min-w-[50rem] flex items-center justify-center rounded text-center">
                <div className="w-[95%]">
                  <span className="pl-[5%] text-xl text-ag-secondary font-bold">
                    Return Detail
                  </span>
                </div>
                <div className="w-[5%]">
                  <button
                    className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                    onClick={() => onClose(true)}
                  >
                    <IoMdCloseCircle />
                  </button>
                </div>
              </div>

              <div className="flex flex-col min-w-[50rem] my-2">
                <h3 className="bg-ag-secondary text-sm px-2 py-1 text-white font-bold rounded-t-xl">General information</h3>
                <div className="border border-ag-secondary-light flex gap-2">
                  <div className="border-r-2 border-r-ag-secondary-light font-bold text-sm text-ag-secondary-letter flex flex-col gap-2 py-2 px-3 min-w-[10rem]">
                    <p>Date</p>
                    <p>Customer</p>
                    <p>Warehouse</p>
                    <p>Sales employee</p>
                    <p>Total cost</p>
                    <p>Total items ordered</p>
                    <p>Total items accepted</p>
                    <p>Total items denied</p>
                    {returnElement?.status === "FINISHED" && (
                      <>
                        <p>Receiving warehouse</p>
                        {isProductOrder ?
                          returnElement?.productReturn?.isSellable && (
                            <>
                              <p>Restocked on rack</p>
                              <p>Total items restocked</p>
                            </>
                          )
                          :
                          returnElement?.accessoryReturn?.isSellable && (
                            <>
                              <p>Restocked on section</p>
                              <p>Total items restocked</p>
                            </>
                          )
                        }
                      </>
                    )}
                    {(returnElement?.status === "FINISHED" || returnElement?.status === "CANCELED") && (
                      <p>Note</p>
                    )}
                  </div>
                  <div className="text-sm text-ag-secondary-letter flex flex-col gap-2 py-2 px-3 min-w-[10rem]">
                    <p>{`${formatDateHourOrders(returnElement?.createdAt)} ID: ${returnId}` }</p>
                    <p>{ returnElement?.wholesaler?.names } { returnElement?.wholesaler?.lastNames }</p>
                    <p>{ returnElement?.warehouse?.name ?? "---" }</p>
                    <p>{ returnElement?.requestedBy ? `${returnElement?.requestedBy?.names} ${returnElement?.requestedBy?.lastNames}` : "-----" }</p>
                    <p>{formatCurrency(returnElement?.total) ?? "---" }</p>
                    <p>{returnElement?.requestedQuantity ?? "---"}</p>
                    <p>{returnElement?.status === "CANCELED" ? 0 : returnElement?.status === "INPROCESS" ? "---" : returnElement?.quantity ?? "---"}</p>
                    <p>{returnElement?.status === "CANCELED" ? returnElement?.quantity : returnElement?.rejectedQuantity ?? "---"}</p>
                    {returnElement?.status === "FINISHED" && (
                      <>
                        <p>{isProductOrder ? returnElement?.productReturn?.warehouse?.name : returnElement?.accessoryReturn?.warehouse?.name}</p>
                        {isProductOrder ?
                          returnElement?.productReturn?.isSellable === true && (
                            <>
                              <p>{returnElement?.productReturn?.addRackQuantity[0]?.rack?.name}</p>
                              <p>{returnElement?.productReturn?.addQuantity}</p>
                            </>
                          )
                          :
                          returnElement?.accessoryReturn?.isSellable === true && (
                            <>
                              <p>{returnElement?.accessoryReturn?.addSectionsQuantity[0]?.section?.name}</p>
                              <p>{returnElement?.accessoryReturn?.addQuantity}</p>
                            </>
                          )
                        }
                      </>
                    )}
                    {(returnElement?.status === "FINISHED" || returnElement?.status === "CANCELED") &&
                      <p className="max-w-[45rem]">
                        {returnElement?.status === "FINISHED" ?
                          (isProductOrder ?
                            returnElement?.productReturn?.note : returnElement?.accessoryReturn?.note
                          )
                          : returnElement?.cancelReason
                        }
                      </p>
                    }
                  </div>
                </div>
              </div>

              <div className="flex flex-col min-w-[50rem] my-2">
                {isProductOrder ?
                  (
                    <>
                      <div className="bg-ag-secondary text-white font-bold text-sm flex px-2 py-1 rounded-t-xl">
                        <h3 className="min-w-[10rem]">Nag</h3>
                        <h3 className="min-w-[25rem]">Name</h3>
                        <h3 className="min-w-[10rem]">Quantity</h3>
                      </div>
                      <div className="border border-ag-secondary-light text-sm text-ag-secondary-letter flex min-w-full">
                        <div className="border-r-2 border-r-ag-secondary-light flex items-center">
                          <p className="min-w-[10rem] text-center">{returnElement?.productOrder?.productInventory?.product?.nags}</p>
                        </div>
                        <div className="flex flex-col w-full">
                          <div className="flex">
                            <p
                              title={returnElement?.productOrder?.productInventory?.product?.name}
                              className="min-w-[25rem] border-r-2 border-r-ag-secondary-light px-2 py-3"
                            >
                              {returnElement?.productOrder?.productInventory?.product?.name}
                            </p>
                            <p className="min-w-[10rem] px-2 py-3">{returnElement?.quantity}</p>
                          </div>
                          <p className="bg-ag-secondary text-white font-bold text-sm px-2 py-1 w-full">Features</p>
                          <div className="flex gap-2 px-2 py-2 flex-wrap flex-grow-0 max-h-[5rem]">
                            {returnElement?.productOrder?.productInventory?.product?.features}
                          </div>
                        </div>
                      </div>
                    </>
                  )
                  :
                  (
                    <>
                      <div className="bg-ag-secondary text-white font-bold text-sm flex px-2 py-1 rounded-t-xl">
                        <p className="min-w-[35rem]">Name</p>
                        <p className="min-w-[10rem]">Quantity</p>
                      </div>
                      <div className="border border-ag-secondary-light text-sm text-ag-secondary-letter flex min-w-full px-2 py-1">
                        <p className="min-w-[35rem] border-r-2 border-r-ag-secondary-light px-2 py-3">
                          {returnElement?.accessoryOrder?.accessoryInventory?.accessory?.name}
                        </p>
                        <p className="min-w-[10rem] px-2 py-3">{returnElement?.quantity}</p>
                      </div>
                    </>
                  )
                }
              </div>

              {returnElement?.status === "FINISHED" &&
                (returnElement?.productReturn?.photos?.length > 0 || returnElement?.accessoryReturn?.photos?.length > 0) &&
                (
                  <div className="flex flex-col min-w-[50rem] my-2">
                    <h3 className="text-xl text-ag-secondary font-bold text-center py-4">Photos</h3>
                    <div className="grid grid-cols-6 gap-2 sm:gap-3 border border-ag-secondary-light">
                      {isProductOrder ?
                        renderPhotos(returnElement?.productReturn?.photos) :
                        renderPhotos(returnElement?.accessoryReturn?.photos)
                      }
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>

      {isModalOpen &&(
        <div className="focus:outline-none fixed inset-0 flex items-center justify-center bg-black/50 z-50">
          <div className="border-white rounded-lg border-8">
            <div className="relative bg-black p-14 pt-8 rounded-lg shadow-lg w-[45rem] h-[30rem] max-w-full max-h-full">
              <button
                className="text-gray-300 absolute top-1 right-0 text-2xl"
                type="button"
                onClick={() => setIsModalOpen(false)}
              >
                <AiFillCloseCircle />
              </button>

              <div className="relative h-full w-full flex flex-col justify-center items-center">
                <img
                  className="max-w-full max-h-full object-contain"
                  src={selectedImage?.url}
                  alt={selectedImage?.fileName || "Uploaded image"}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="opacity-50 fixed inset-0 z-10 bg-black"></div>
    </>
  );
};

export default DetailReturns;