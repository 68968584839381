import { useEffect, useState } from "react";
import Pagination from "../../../components/pagination/Pagination";
import RowsOrganize from "./organize/RowsOrganize";
import { useOrderActionsContext } from "./OrderActionsContext";
import RowsDelivery from "./delivery/RowsDelivery";

const TableOrderActions = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(5);
  const { globalItems, isAttendStatus } = useOrderActionsContext();

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = globalItems.slice(indexOfFirstRecord, indexOfLastRecord);

  useEffect(() => {
    setCurrentPage(1);
  }, [globalItems]);

  return (
    <div>
      {
        currentRecords.map((element, i) =>
          isAttendStatus ?
            <RowsOrganize key={i} item={element} itemNumber={i + 1} />
            :
            <RowsDelivery key={i} item={element} itemNumber={i + 1} />
        )
      }
      <div className="p-5 m-auto">
        <Pagination
          currentPage={currentPage}
          totalCount={globalItems.length}
          pageSize={recordsPerPage}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>
    </div>
  );
};

export default TableOrderActions;