'use client';

import { useEffect, useState } from 'react';
import { getMovementHistory } from '../../../services/Product';
import { alertOptions, formatCurrency, formatDateEng, formatDateWordEng } from '../../../utils/Utilities';
import { toast } from 'react-toastify';

function HistoryModal({ onClose, id }) {
  const [activeTab, setActiveTab] = useState('general');
  const [productHistory, setProductHistory] = useState(null);
  const [rackAdded, setRackAdded] = useState([]);
  const [rackRemoved, setRackRemoved] = useState([]);

  const tabs = [
    { id: 'general', name: 'General information' },
    { id: 'transfers', name: 'Transfers' },
    { id: 'product', name: 'Product modification' },
    { id: 'orders', name: 'Orders' },
  ];

  const getStatusStyles = (status) => {
    switch (status) {
    case 'paid':
      return 'bg-green-100 text-green-800';
    case 'attend':
      return 'bg-blue-100 text-blue-800';
    case 'inprocess':
      return 'bg-amber-100 text-amber-800';
    default:
      return 'bg-gray-100 text-gray-800';
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getMovementHistory(id);
        const data = response.data;

        if (data.product) {
          const rackAdded = data.product.modifiedRacks.filter((x) => x.type === 'Added');
          if (rackAdded !== undefined) {
            setRackAdded(rackAdded);
          } else {
            setRackAdded({});
          }

          const rackRemoved = data.product.modifiedRacks.filter((x) => x.type === 'Removed');
          if (rackRemoved !== undefined) {
            setRackRemoved(rackRemoved);
          } else {
            setRackRemoved({});
          }
        } else {
          setRackAdded({});
          setRackRemoved({});
        }
        setProductHistory({
          orders: (Array.isArray(data.orders) && data.orders.length === 0) ? null : data.orders,
          product: Object.keys(data.product || {}).length === 0 ? null : data.product,
          transfer: Object.keys(data.transfer || {}).length === 0 ? null : data.transfer,
          generalInformation: Object.keys(data.generalInformation || {}).length === 0 ? null : data.generalInformation,
        });
      } catch(error) {
        toast.error(`Error Fetching Data: ${error}`, alertOptions);
      }
    };

    if (id) {
      getData();
    }
  }, [id]);

  const renderContent = () => {
    switch (activeTab) {
    case 'general':
      return (
        <div className="grid grid-cols-2 gap-6 text-sm">
          { productHistory.generalInformation ? (<>
            <div>
              <p className="font-semibold mb-1 text-gray-700">Date added:</p>
              <p className="text-gray-600">{formatDateEng(productHistory.generalInformation.createdAt)}</p>
            </div>
            <div>
              <p className="font-semibold mb-1 text-gray-700">Warehouse:</p>
              <p className="text-gray-600">{productHistory.generalInformation.Warehouse.name}</p>
            </div>
            <div>
              <p className="font-semibold mb-1 text-gray-700">Maximum stock:</p>
              <p className="text-gray-600">{productHistory.generalInformation.maxStock}</p>
            </div>
            <div>
              <p className="font-semibold mb-1 text-gray-700">Racks:</p>

              {
                productHistory.generalInformation.racks.map((rack) => (
                  <p className="text-gray-600" key={rack._id}>{rack.rack}</p>
                ))
              }
            </div>
            <div>
              <p className="font-semibold mb-1 text-gray-700">Minimum stock:</p>
              <p className="text-gray-600">{productHistory.generalInformation.minStock}</p>
            </div>
            <div>
            </div>
            <div>
              <p className="font-semibold mb-1 text-gray-700">Quantity added:</p>
              <p className="text-gray-600">{productHistory.generalInformation.quantity}</p>
            </div>
            <div>
              <p className="font-semibold mb-1 text-gray-700">Modified by:</p>
              <p className="text-gray-600">{productHistory.generalInformation.by.names} {productHistory.generalInformation.by.lastNames}</p>
            </div>
          </>) : (
            <div>
              Nothing to show
            </div>
          )}
        </div>
      );
    case 'transfers':
      return (
        <div className="">
          { productHistory.transfer ? (
            <>
              <div className='mb-6 text-sm'>
                <div>
                  <p className="font-semibold mb-1 text-gray-700">Transfer date:</p>
                  <p className="text-gray-600">{formatDateEng(productHistory.transfer.date)}</p>
                </div>
              </div>
              <div className='grid grid-cols-2 gap-6 text-sm'>
                <div>
                  <p className="font-semibold mb-1 text-gray-700">Home Warehouse:</p>
                  <p className="text-gray-600">{productHistory.transfer.fromWarehouse.name}</p>
                </div>
                <div>
                  <p className="font-semibold mb-1 text-gray-700">Target Warehouse:</p>
                  <p className="text-gray-600">{productHistory.transfer.toWarehouse.name}</p>
                </div>
                <div>
                  <p className="font-semibold mb-1 text-gray-700">Home Rack:</p>
                  <p className="text-gray-600">{productHistory.transfer.productsTransfer[0].rack.name}</p>
                </div>
                <div>
                  <p className="font-semibold mb-1 text-gray-700">Target Rack:</p>
                  <p className="text-gray-600">{productHistory.transfer.productsReceived[0].incomingRack[0].rack}</p>
                </div>
                <div>
                  <p className="font-semibold mb-1 text-gray-700">Quantity transferred:</p>
                  <p className="text-gray-600">{productHistory.transfer.totalQuantity}</p>
                </div>
              </div>
              <div className='mt-6 text-sm'>
                <div>
                  <p className="font-semibold mb-1 text-gray-700">Modified By:</p>
                  <p className="text-gray-600">{productHistory.transfer.cratedBy.names + ' ' + productHistory.transfer.cratedBy.lastNames} </p>
                </div>
              </div>
            </>
          ) : (
            <div>
              Nothing to show
            </div>
          )}
        </div>
      );
    case 'product':
      return (
        <div className="grid grid-cols-2 gap-6 text-sm">
          {productHistory.product ? (
            <>
              <div>
                <p className="font-semibold mb-1 text-gray-700">Modification date:</p>
                <p className="text-gray-600">{formatDateEng(productHistory.product.updatedAt)}</p>
              </div>
              <div>
                <p className="font-semibold mb-1 text-gray-700">Quantity added:</p>
                {
                  rackAdded.length > 0 ?
                    rackAdded.map((item, index) => (
                      <p key={index} className="text-gray-600">{`${item?.qtyChanged !== undefined ? item?.qtyChanged : ''}${' - '}${item?.rack !== undefined ? item?.rack : ''}`}</p>
                    ))
                    :
                    <>
                      <p className="text-gray-600">{`${productHistory.product.totalQuantityAdded}${' '}${rackAdded?.rack !== undefined ? rackAdded?.rack : ''}`}</p>
                    </>
                }
              </div>
              <div>
                <p className="font-semibold mb-1 text-gray-700">New maximum stock:</p>
                <p className="text-gray-600">{productHistory.product.maxStock}</p>
              </div>
              <div>
                <p className="font-semibold mb-1 text-gray-700">Quantity removed:</p>
                {
                  rackRemoved.length > 0 ?
                    rackRemoved.map((item, index) => (
                      <p key={index} className="text-gray-600">{`${item?.qtyChanged !== undefined ? Math.abs(item?.qtyChanged) : ''}${' - '}${item?.rack !== undefined ? item?.rack : ''}`}</p>
                    ))
                    :
                    <>
                      <p className="text-gray-600">{`${Math.abs(productHistory.product.totalQuantityRemoved)}${' '}${rackRemoved?.rack !== undefined ? rackRemoved?.rack : ''}`}</p>
                    </>
                }
              </div>
              <div>
                <p className="font-semibold mb-1 text-gray-700">New minimum stock:</p>
                <p className="text-gray-600">{productHistory.product.minStock}</p>
              </div>
              <div>
                <p className="font-semibold mb-1 text-gray-700">Modified racks:</p>
                {
                  productHistory.product.modifiedRacks.map((rack) => (
                    <p className="text-gray-600" key={rack._id}>{rack.rack}</p>
                  ))
                }
              </div>
              <div>
                <p className="font-semibold mb-1 text-gray-700">New sales price:</p>
                <p className="text-gray-600">{formatCurrency(productHistory.product.cost)}</p>
              </div>
              <div>
                <p className="font-semibold mb-1 text-gray-700">Modified by:</p>
                <p className="text-gray-600">{productHistory.product.lastUpdateBy.names} {productHistory.product.lastUpdateBy.lastNames}</p>
              </div>
            </>
          ) : (
            <div>
              Nothing to show
            </div>
          )}
        </div>
      );
    case 'orders':
      return (
        <div className="max-w-2xl space-y-2">
          { productHistory.orders ? productHistory.orders.map((order) => (
            <div key={order._id} className="p-3 rounded-md ">
              <div key={order._id} className="group rounded-lg border bg-card p-4 transition-colors hover:bg-muted/50">
                <div className="flex items-start justify-between">
                  <div className="space-y-3">
                    <div className="space-y-1">
                      <div className="flex items-center gap-2">
                        <h3 className="font-bold">Order ID: {order._id}</h3>
                        <span className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-bold capitalize 
                          ${getStatusStyles(order?.status === "delivered" && order?.paymentState === "PAID" ? "paid" : order.status)}`}>
                          {order?.status === "delivered" && order?.paymentState === "PAID" ? "Paid" : order?.status}
                        </span>
                      </div>
                      <p className="text-sm text-muted-foreground">
                        Attended by: {`${order.employee.names} ${order.employee.lastNames}`}
                      </p>
                    </div>
                    <div className="flex flex-wrap gap-x-8 gap-y-2 text-sm">
                      <div>
                        <p className="text-muted-foreground">Date</p>
                        <p className="font-bold">{formatDateWordEng(order.createdAt)}</p>
                      </div>
                      <div>
                        <p className="text-muted-foreground">Total</p>
                        <p className="font-bold">{formatCurrency(order.total)}</p>
                      </div>
                      <div>
                        <p className="text-muted-foreground">Quantity</p>
                        <p className="font-bold">{order.productsOrder.quantity}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3 text-xs text-muted-foreground">
                  <p>Clover ID: {order.orderIdClover}</p>
                </div>
              </div>
            </div>
          ))
            : (
              <div>
                Nothing to show.
              </div>
            )
          }
        </div>
      );
    default:
      return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-10">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl overflow-y-auto h-[90%] md:h-[75%]">
        <div className="flex justify-center items-center border-b p-4 relative">
          <h2 className="text-xl font-semibold text-gray-800">Movement History</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700 absolute inset-y-0 right-8">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="p-4 ">
          <div className="flex mb-6 text-sm font-medium">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`flex-1 py-2 px-4 ${
                  activeTab === tab.id
                    ? 'bg-red-500 text-white'
                    : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                } ${tab.id === 'general' ? 'rounded-l-md' : ''} ${
                  tab.id === 'orders' ? 'rounded-r-md' : ''
                } transition-colors duration-200`}
              >
                {tab.name}
              </button>
            ))}
          </div>
          <div className="overflow-y-auto" style={{ height: '400px' }}>
            {productHistory && renderContent()}
          </div>
        </div>
        <div className="border-t p-4 flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors duration-200"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default HistoryModal;